<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  
  <!--チームを選ぶ-->
  <div class="bbrCom_selectMenu bbrTem_selectTeam">
  <label>
  <select id="bbrTem_selectTeamId" onchange="location.href = this.options[selectedIndex].value">
    <option>チームを選ぶ</option>
    <template v-for="(team) in json.teamList">
      <option v-bind:value="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId" :key="team.id">{{ team.name }}</option>
    </template>
  </select>
  </label>
  </div>
  <!--/チームを選ぶ-->
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  
  <h2 class="bbrCom_ttl"><span v-bind:class="'bbrCom_blTeam' + json.team.id">{{ json.team.name }}</span></h2>
  
  <!--チーム情報ページ_メインメニュー-->
  <ul id="bbrTem_mainMenu" class="bbrCom_tabMenu02">
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + json.team.globalId">チームトップ</a></li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId" class="bbrCom_current">所属選手</a></li>
    <li>
      <a v-if="gameJson != null" v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + getMonth(json.gameDate)">日程・結果</a>
      <a v-else href="">日程・結果</a>
    </li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + getVsTeamId(json.team.globalId )">対戦・成績</a></li>
  </ul>
  <!--/チーム情報ページ_メインメニュー-->

  <!--サブナビ-->
  <ul id="bbrTem_pListTabMenu01" class="bbrCom_tabMenu01 bbrCom_tac bbrCom_mb50">
    <li>
      <a v-if="json.positionCode == 'P'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=P'" class="bbrCom_current">投手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=P'">投手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'C'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=C'" class="bbrCom_current">捕手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=C'">捕手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'IF'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=IF'" class="bbrCom_current">内野手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=IF'">内野手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'OF'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=OF'" class="bbrCom_current">外野手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=OF'">外野手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'M'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=M'" class="bbrCom_current">監督・コーチ</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=M'">監督・コーチ</a>
    </li>
  </ul>
  <!--サブナビ-->
  
  <!--所属選手詳細 基本情報テーブル-->
  <template v-if="json.positionCode == 'M'">
  <h3 id="bbrTem_playerBase"><span>{{ json.profile.uniform }}</span><span>{{ json.profile.name }}</span><span>{{ json.profile.firstName }} {{ json.profile.lastName }}</span><span>{{ json.profile.position }}</span></h3>
  <table id="bbrTem_playerBase_table" class="bbrCom_bdrTable" border="0" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <th>生年月日</th>
        <td>{{ getFormattedBirthDay(json.profile.birthday) }}</td>
        <th>満年齢</th>
        <td>{{ getAge(json.profile.birthday) }}</td>
      </tr>
      <tr>
        <th>所属</th>
        <td colspan="3">{{ json.profile.history }}</td>
      </tr>
    </tbody>
  </table>
  </template>
  <template v-else>
  <h3 id="bbrTem_playerBase"><span>{{ json.profile.uniform }}</span><span>{{ json.profile.name }}</span><span>{{ json.profile.firstName }} {{ json.profile.lastName }}</span><span>{{ positionName[json.positionCode] }}</span></h3>
  <table id="bbrTem_playerBase_table" class="bbrCom_bdrTable" border="0" cellspacing="0" cellpadding="0">
    <tbody>
      <tr>
        <th>生年月日</th>
        <td>{{ getFormattedBirthDay(json.profile.birthday) }}</td>
        <th>身長&nbsp;/&nbsp;体重</th>
        <td>{{ json.profile.height }}cm&nbsp;/&nbsp;{{ json.profile.weight }}kg</td>
      </tr>
      <tr>
        <th>満年齢</th>
        <td>{{ getAge(json.profile.birthday) }}</td>
        <th>投打</th>
        <td>{{ side[json.profile.throwing] }}投げ{{ side[json.profile.batSide] }}打ち</td>
      </tr>
    </tbody>
  </table>
  </template>
  <!--/所属選手詳細 基本情報テーブル-->

  <template v-if="json.positionCode != 'M'">
  <!--所属選手詳細  投手成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_mb10">{{ gameTypeName[json.gameType] }}成績</h3>
  <template v-if="json.positionCode == 'P'">
  <ul class="bbrCom_tabMenu01 bbrCom_mb10">
    <li>
      <a v-if="mode == 'batting'" v-on:click="setBatter" href="" class="bbrCom_current">打者成績</a>
      <a v-else v-on:click="setBatter" href="">打者成績</a>
    </li>
    <li>
      <a v-if="mode == 'pitching'" v-on:click="setPitcher" href="" class="bbrCom_current">投手成績</a>
      <a v-else v-on:click="setPitcher" href="">投手成績</a>
    </li>
  </ul>
  </template>
  <ul class="bbrCom_bdrList bbrTem_resultList bbrCom_mb50">
    <li v-for='(rank,index) in json.rankOrder[mode]' v-bind:key="index">
      <p>{{ rank.name }}</p>
      <span>
        <template v-if="rank.code == 'IP'">
          {{ formattedIP(json.leaders[mode][rank.code]) }}
        </template>
        <template v-else>
          {{ json.leaders[mode][rank.code] }}
        </template>
      </span>
    </li>
  </ul>
  <!--/所属選手詳細  投手成績-->
  
  <!--所属選手詳細  最近5試合の成績-->
  <template v-if="json.latestGames[mode]">
  <h3 class="bbrCom_ttlSc-t">最近5試合の成績</h3>
  <table class="bbrCom_tbl  bbrRnk_top-tbl bbrCom_mb50">
    <tbody>
      <template v-if="mode == 'batting'">
      <tr>
        <th>日付</th>
        <th class="bbrCom_tblTxtTeam">対戦</th>
        <th>打数</th>
        <th>得点</th>
        <th>安打</th>
        <th>本塁打</th>
        <th>打点</th>
        <th>三振</th>
        <th>四死球</th>
      </tr>
      <tr v-for='(game,index) in json.latestGames[mode]' v-bind:key="index">
        <td>{{ getFormattedShortDay(game.gameDate) }}</td>
        <td class="bbrCom_tblTxtTeam">
          <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + game.vsTeamGlobalId">{{ game.vsTeamName }}</a>
        </td>
        <td>{{ game.atBats }}</td>
        <td>{{ game.scored }}</td>
        <td>{{ game.hits }}</td>
        <td>{{ game.homeRuns }}</td>
        <td>{{ game.runs }}</td>
        <td>{{ game.strikeOuts }}</td>
        <td>{{ game.walks }}</td>
      </tr>
      </template>
      <template v-if="mode == 'pitching'">
      <tr>
        <th>日付</th>
        <th class="bbrCom_tblTxtTeam">対戦</th>
        <th>結果</th>
        <th>投球回</th>
        <th>被安打</th>
        <th>三振</th>
        <th>四死球</th>
        <th>失点</th>
        <th>自責点</th>
      </tr>
      <tr v-for='(game,index) in json.latestGames[mode]' v-bind:key="index">
        <td>{{ getFormattedShortDay(game.gameDate) }}</td>
        <td class="bbrCom_tblTxtTeam">
          <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + game.vsTeamGlobalId">{{ game.vsTeamName }}</a>
        </td>
        <template v-if="game.result == '○'">
        <td>勝</td>
        </template>
        <template v-else-if="game.result == '●'">
        <td>敗</td>
        </template>
        <template v-else-if="game.result == null">
        <td>-</td>
        </template>
        <template v-else>
        <td>{{ game.result }}</td>
        </template>
        <td>{{ formattedIP(game.inningsPitched) }}</td>
        <td>{{ game.hits }}</td>
        <td>{{ game.strikeOuts }}</td>
        <td>{{ game.walks }}</td>
        <td>{{ game.runs }}</td>
        <td>{{ game.earnedRuns }}</td>
      </tr>
      </template>
    </tbody>
  </table>
  </template>
  <!--/所属選手詳細  最近5試合の成績-->
  
  <!--所属選手詳細  最近5年間の成績-->
  <template v-if="json.leadersBySeason[mode]">
  <h3 class="bbrCom_ttlSc-t">最近 5年間の成績</h3>
  <div class="bbrCom_mb10">
    <div class="bbrCom_wrapScrTbl  bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_mb20" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th class="bbrCom_tblTxt1st">年度</th>
            <th class="bbrCom_tblTxtPName">チーム</th>
            <th v-for='(rank,index) in json.rankOrder[mode]' v-bind:key="index">
              {{ rank.name }}
            </th>
          </tr>
          <template v-for="(year, yearIndex) in json.leadersBySeason[mode]">
            <template v-for="(teamLeaders, teamIndex) in json.leadersBySeason[mode][yearIndex]">
            <tr :key="yearIndex + teamIndex">
              <td>{{ yearIndex }}</td>
              <td>{{ teamLeaders.teamName }}</td>
              <td v-for='(rank,index) in json.rankOrder[mode]' v-bind:key="index">
              <template v-if="rank.code == 'IP'">
                {{ formattedIP(teamLeaders.leaders[rank.code]) }}
              </template>
              <template v-else>
                {{ teamLeaders.leaders[rank.code] }}
              </template>
              </td>
            </tr>
          </template>
          </template>
        </tbody>
      </table>
    </div>
    <div class="bbrCom_icoScr"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
  </div>
  </template>
  
  </template>
  <!--/所属選手詳細  最近5年間の成績-->
  
  
 </div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
      mode: 'batting',
      side: {
        'Left':'左',
        'Right':'右',
        'Switch':'両',
      },
      positionName: {
        'P':'投手',
        'C':'捕手',
        'IF':'内野手',
        'OF':'外野手',
      },
      gameTypeName: {
        0:'オープン戦',
        1:'シーズン',
      },
    }
  },
  mounted: async function() {
    if (this.json.positionCode == 'P') {
      this.mode = 'pitching';
    }
    this.gameJson = await this.loadLatestGame();
    this.$config.callback();
  },
  methods: {
    setBatter: function (e) {
      e.preventDefault();
      this.mode = 'batting';
    },
    setPitcher: function (e) {
      e.preventDefault();
      this.mode = 'pitching';
    },
    getFormattedBirthDay: function (birthday) {
      const format = new Date(birthday);
      const year = format.getFullYear();
      let month = format.getMonth() + 1;
      let day = format.getDate();
      month = ('0' + month).slice(-2);
      day = ('0' + day).slice(-2);

      return `${year}.${month}.${day}`;
    },
    getAge: function (birthday) {
      const date = new Date();
      const birthDate = new Date(birthday);
      const age = date.getFullYear() - birthDate.getFullYear();
      const thisYearsBirthday = new Date(date.getFullYear(), birthDate.getMonth(), birthDate.getDate());
      return age + (thisYearsBirthday.getTime() > date.getTime() ? -1 : 0);
    },    
  }
}
</script>