<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  
  <!--チームを選ぶ-->
  <div class="bbrCom_selectMenu bbrTem_selectTeam">
  <label>
  <select id="bbrTem_selectTeamId" onchange="location.href = this.options[selectedIndex].value">
    <option>チームを選ぶ</option>
    <template v-for="(team) in json.teamList">
      <option v-bind:value="$config.baseUrl + '?kd_page=team_record&team_id=' + team.globalId + '&vsteam_id=' + getVsTeamId(team.globalId)" :key="team.id">{{ team.name }}</option>
    </template>
  </select>
  </label>
  </div>
  <!--/チームを選ぶ-->
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  
  <h2 class="bbrCom_ttl"><span v-bind:class="'bbrCom_blTeam' + json.team.id">{{ json.team.name }}</span></h2>
  
  <!--チーム情報ページ_メインメニュー-->
  <ul id="bbrTem_mainMenu" class="bbrCom_tabMenu02">
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + json.team.globalId">チームトップ</a></li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId">所属選手</a></li>
    <li>
      <a v-if="gameJson != null" v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + getMonth(json.gameDate)">日程・結果</a>
      <a v-else href="">日程・結果</a>
    </li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + getVsTeamId(json.team.globalId )" class="bbrCom_current">対戦・成績</a></li>
  </ul>
  <!--/チーム情報ページ_メインメニュー-->
  
  <!--リーグリスト-->
  <div class="bbrTem_leagueList bbrCom_cf">
    <span>セ・リーグ</span>
    <ul class="bbrCom_tabMenu01">
      <template v-for="(team) in json.cTeamList">
      <li v-if="json.team.globalId != team.globalId" :key="team.id">
        <a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + team.globalId">{{ team.nickname }}</a>
      </li>
      </template>
    </ul>
  </div>
  <div class="bbrTem_leagueList bbrCom_mb50 bbrCom_cf">
    <span>パ・リーグ</span>
    <ul class="bbrCom_tabMenu01">
      <template v-for="(team) in json.pTeamList">
      <li v-if="json.team.globalId != team.globalId" :key="team.id">
        <a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + team.globalId">{{ team.nickname }}</a>
      </li>
      </template>
    </ul>
  </div>
  <!--/リーグリスト-->
  
  <!--対戦成績-->
  
  <h3 class="bbrCom_ttlSc">VS {{ json.vsTeam.name }}対戦・成績
    <span v-if="json.gameType == 0">（オープン戦）</span>
    <span v-if="json.gameType == 1">（公式戦）</span>
  </h3>
  <template v-if="json.teamStats.home.average.value == null">
    <div class="bbrCom_mb50">対戦データがありません</div>
  </template>
  <template v-else>
  <!--対戦成績 bar-->
  <div id="bbrCom_vsBar" class="bbrCom_mb50">
    <ul class="bbrCom_cf">
      <li id="bbrCom_vsBar-txtL">{{ json.team.nickname }} <span class="bbrCom_vsBar-res">{{ json.teamStandingsSplits.win }}勝（{{ json.teamStandingsSplits.tie }}分）</span></li>
      <li id="bbrCom_vsBar-txtR">{{ json.vsTeam.nickname }} <span class="bbrCom_vsBar-res">{{ json.teamStandingsSplits.lose }}勝</span></li>
    </ul>
    <div id="bbrCom_vsBar_Main">
      <div id="bbrCom_vsBar_Main-left" v-bind:style="'width: calc(' + getTeamStandingsSplitsWidth('home') + ')'" v-bind:class="'bbrCom_bgTeam' + json.team.id"></div>
      <div id="bbrCom_vsBar_Main-right" v-bind:style="'width: calc(' + getTeamStandingsSplitsWidth('visitor') + ')'" v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id"></div>
    </div>
  </div>
  <!--/対戦成績 bar-->
  
  <!--対戦成績 graph-->
  <!--<p class="bbrCom_bgTeam**" style="width: calc( 1% * (ここに1～100の数値が入ります) * 0.8)"></p>-->
  <!--実数値も含めるので、バーの最大横幅は80%とする-->
  
  <div id="bbrCom_vsGraph" class="bbrCom_mb50">
  <table id="bbrCom_vsGraphTbl">
  <tbody>
    <tr>
      <th v-bind:class="'bbrCom_bbTeam' + json.team.id">{{ json.team.nickname }}</th>
      <th>&nbsp;</th>
      <th v-bind:class="'bbrCom_bbTeam' + json.vsTeam.id"><span>{{ json.vsTeam.nickname }}</span></th>
    </tr>
    <tr>
      <td>
        <span>{{ getWinRate('home') }}</span>
        <template v-if="getWinRate('home') >= getWinRate('visitor')">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(getWinRate('home'), getWinRate('visitor')) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(getWinRate('home'), getWinRate('visitor')) + ')'"></p>
        </template>
      </td>
      <td>勝率</td>
      <td>
        <template v-if="getWinRate('visitor') >= getWinRate('home')">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(getWinRate('visitor'), getWinRate('home')) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(getWinRate('visitor'), getWinRate('home')) + ')'"></p>
        </template>
        <span>{{ getWinRate('visitor') }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.average.value }}</span>
        <template v-if="json.teamStats.home.average.value >= json.teamStats.visitor.average.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.average.value, json.teamStats.visitor.average.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.average.value, json.teamStats.visitor.average.value) + ')'"></p>
        </template>
      </td>
      <td>打率</td>
      <td>
        <template v-if="json.teamStats.visitor.average.value >= json.teamStats.home.average.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.average.value, json.teamStats.home.average.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.average.value, json.teamStats.home.average.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.average.value }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.homeRuns.value }}</span>
        <template v-if="json.teamStats.home.homeRuns.value >= json.teamStats.visitor.homeRuns.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.homeRuns.value, json.teamStats.visitor.homeRuns.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.homeRuns.value, json.teamStats.visitor.homeRuns.value) + ')'"></p>
        </template>
      </td>
      <td>本塁打</td>
      <td>
        <template v-if="json.teamStats.visitor.homeRuns.value >= json.teamStats.home.homeRuns.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.homeRuns.value, json.teamStats.home.homeRuns.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.homeRuns.value, json.teamStats.home.homeRuns.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.homeRuns.value }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.hits.value }}</span>
        <template v-if="json.teamStats.home.hits.value >= json.teamStats.visitor.hits.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.hits.value, json.teamStats.visitor.hits.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.hits.value, json.teamStats.visitor.hits.value) + ')'"></p>
        </template>
      </td>
      <td>安打</td>
      <td>
        <template v-if="json.teamStats.visitor.hits.value >= json.teamStats.home.hits.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.hits.value, json.teamStats.home.hits.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.hits.value, json.teamStats.home.hits.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.hits.value }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.earnedRunAverage.value }}</span>
        <template v-if="parseFloat(json.teamStats.home.earnedRunAverage.value) <= parseFloat(json.teamStats.visitor.earnedRunAverage.value)">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.home.earnedRunAverage.value, json.teamStats.visitor.earnedRunAverage.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.home.earnedRunAverage.value, json.teamStats.visitor.earnedRunAverage.value) + ')'"></p>
        </template>
      </td>
      <td>防御率</td>
      <td>
        <template v-if="parseFloat(json.teamStats.visitor.earnedRunAverage.value) <= parseFloat(json.teamStats.home.earnedRunAverage.value)">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.visitor.earnedRunAverage.value, json.teamStats.home.earnedRunAverage.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.visitor.earnedRunAverage.value, json.teamStats.home.earnedRunAverage.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.earnedRunAverage.value }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.strikeOuts.value }}</span>
        <template v-if="json.teamStats.home.strikeOuts.value >= json.teamStats.visitor.strikeOuts.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.strikeOuts.value, json.teamStats.visitor.strikeOuts.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.home.strikeOuts.value, json.teamStats.visitor.strikeOuts.value) + ')'"></p>
        </template>
      </td>
      <td>奪三振</td>
      <td>
        <template v-if="json.teamStats.visitor.strikeOuts.value >= json.teamStats.home.strikeOuts.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.strikeOuts.value, json.teamStats.home.strikeOuts.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getDescTeamStatsWidth(json.teamStats.visitor.strikeOuts.value, json.teamStats.home.strikeOuts.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.strikeOuts.value }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.walks.value }}</span>
        <template v-if="json.teamStats.home.walks.value <= json.teamStats.visitor.walks.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.home.walks.value, json.teamStats.visitor.walks.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.home.walks.value, json.teamStats.visitor.walks.value) + ')'"></p>
        </template>
      </td>
      <td>与四球</td>
      <td>
        <template v-if="json.teamStats.visitor.walks.value <= json.teamStats.home.walks.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.visitor.walks.value, json.teamStats.home.walks.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.visitor.walks.value, json.teamStats.home.walks.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.walks.value }}</span></td>
    </tr>
    <tr>
      <td>
        <span>{{ json.teamStats.home.hitByPitch.value }}</span>
        <template v-if="json.teamStats.home.hitByPitch.value <= json.teamStats.visitor.hitByPitch.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.team.id" v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.home.hitByPitch.value, json.teamStats.visitor.hitByPitch.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.home.hitByPitch.value, json.teamStats.visitor.hitByPitch.value) + ')'"></p>
        </template>
      </td>
      <td>与死球</td>
      <td>
        <template v-if="json.teamStats.visitor.hitByPitch.value <= json.teamStats.home.hitByPitch.value">
        <p v-bind:class="'bbrCom_bgTeam' + json.vsTeam.id" v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.visitor.hitByPitch.value, json.teamStats.home.hitByPitch.value) + ')'"></p>
        </template>
        <template v-else>
        <p v-bind:style="'width: calc(' + getAscTeamStatsWidth(json.teamStats.visitor.hitByPitch.value, json.teamStats.home.hitByPitch.value) + ')'"></p>
        </template>
        <span>{{ json.teamStats.visitor.hitByPitch.value }}</span></td>
    </tr>
  </tbody>
</table>
  </div>
  </template>
  <!--/対戦成績 graph-->
  
  <h3 class="bbrCom_ttlSc">VS {{ json.vsTeam.nickname }}対戦日程
    <span v-if="json.gameType == 0">（オープン戦）</span>
    <span v-if="json.gameType == 1">（公式戦）</span>
  </h3>
  
  <!--2017年4月-->
  <template v-if='json.schedule == null'>
    <div class="bbrCom_mb50">日程データがありません</div>
  </template>
  <template v-for='(gameList, month) in getGameScheduleList()'>
    <h3 class="bbrCom_ttlSc-s" :key="'M' + month">-{{ month }}</h3>
    <table class="bbrCom_tbl bbrCom_tblResult bbrCom_tbl-nonth bbrCom_mb30" :key="month">
      <tbody>
        <tr v-for='(game) in gameList' v-bind:key="game.gameGlobalId">
          <td>{{ getFormattedDay(game.gameDate) }}<br>{{ getFormattedStartTime(game.startTime) }}</td>
          <td>{{ game.venueNickName }}</td>
          <td>
          <template v-if="game.gameStatusName == '試合前' || game.gameStatusName == '中止'">
            <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <p>{{ game.gameStatusName }}</p>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
          </template>
          <template v-else-if="game.gameStatusName == 'ノーゲーム'">
            <a v-bind:href="getGameLink($config.baseUrl, game)">
              <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <p>{{ game.gameStatusName }}</p>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
            </a>
          </template>
          <template v-else>
            <a v-bind:href="getGameLink($config.baseUrl, game)">
              <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <span>{{ game.home.score }}</span>
                <span>-</span>
                <span>{{ game.visitor.score }}</span>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
            </a>
          </template>
          </td>
          <td>
            <dl>
              <template v-if="game.gameStatusName == '試合終了'">
                 <template v-if="game.home.winLose == 'Win' || game.home.winLose == 'true' || game.visitor.winLose == 'Win' || game.visitor.winLose == 'true'">
                  <dt>勝</dt>
                  <template v-if="(game.home.winLose == 'Win' || game.home.winLose == 'true') && game.home.pitcher != null">
                    <dd>{{ game.home.pitcher }}</dd>
                  </template>
                  <template v-else-if="(game.visitor.winLose == 'Win' || game.visitor.winLose == 'true') && game.visitor.pitcher != null">
                    <dd>{{ game.visitor.pitcher }}</dd>
                  </template>
                  <template v-else>
                    <dd>&nbsp;</dd>
                  </template>
                  <template v-if="game.savePitcher != null">
                    <dt>S</dt>
                    <dd>{{ game.savePitcher }}</dd>
                  </template>
                </template>
                <template v-else>
                <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else-if="game.gameStatusName == '試合前'">
                <template v-if="game.visitor.startingPitcher == null">
                <dt>&nbsp;</dt>
                </template>
                <template v-else>
                <dt>予</dt>
                </template>
                <dd v-if="game.visitor.startingPitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.visitor.startingPitcher }}</dd>
              </template>
              <template v-else-if="game.gameStatusName == '試合中'">
                <dt>&nbsp;</dt>
                <dd v-if="game.visitor.pitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.visitor.pitcher }}</dd>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dd>&nbsp;</dd>
              </template>

              <template v-if="game.gameStatusName == '試合終了'">
                <template v-if="game.home.winLose == 'Loss' || game.home.winLose == 'false' || game.visitor.winLose == 'Loss' || game.visitor.winLose == 'false'">
                  <dt>負</dt>
                  <template v-if="(game.home.winLose == 'Loss' || game.home.winLose == 'false') && game.home.pitcher != null">
                    <dd>{{ game.home.pitcher }}</dd>
                  </template>
                  <template v-else-if="(game.visitor.winLose == 'Loss' || game.visitor.winLose == 'false') && game.visitor.pitcher != null">
                    <dd>{{ game.visitor.pitcher }}</dd>
                  </template>
                  <template v-else>
                    <dd>&nbsp;</dd>
                  </template>
                </template>
                <template v-else>
                <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else-if="game.gameStatusName == '試合前'">
                <template v-if="game.home.startingPitcher == null">
                <dt>&nbsp;</dt>
                </template>
                <template v-else>
                <dt>予</dt>
                </template>
                <dd v-if="game.home.startingPitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.home.startingPitcher }}</dd>
              </template>
              <template v-else-if="game.gameStatusName == '試合中'">
                <dt>&nbsp;</dt>
                <dd v-if="game.home.pitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.home.pitcher }}</dd>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dd>&nbsp;</dd>
              </template>
            </dl>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
  
 </div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
    }
  },
  mounted: async function() {
    this.gameJson = await this.loadLatestGame();
    this.$config.callback();
  },
  methods: {
    getWinRate: function (type) {
      let homeWin = this.json.teamStandingsSplits.win;
      let visitorWin = this.json.teamStandingsSplits.lose;
      let total = parseInt(homeWin) + parseInt(visitorWin);
      if (type == 'home') {
        let result = String((Math.round(homeWin / total * 1000) / 1000).toFixed(3));
        if (isNaN(result)) {
          return '.000';
        }
        return result.replace('0.', '.');
      } else {
        let result = String((Math.round(visitorWin / total * 1000) / 1000).toFixed(3));
        if (isNaN(result)) {
          return '.000';
        }
        return result.replace('0.', '.');
      }
    },
    getTeamStandingsSplitsWidth: function (type) {
      let homeWin = this.json.teamStandingsSplits.win;
      let visitorWin = this.json.teamStandingsSplits.lose;
      let total = parseInt(homeWin) + parseInt(visitorWin) + parseInt(this.json.teamStandingsSplits.tie);
      if (type == 'home') {
        return `1% * (${homeWin} / ${total}) * 100`;
      } else {
        return `1% * (${visitorWin} / ${total}) * 100`;
      }
    },
    getTeamStatsWidth: function (baseValue, value) {
      if (baseValue >= value) {
        return `1% * 100 * 0.8`;
      } else {
        return `1% * (${baseValue} / ${value}) * 100 * 0.8`;
      }
    },
    getDescTeamStatsWidth: function (baseValue, value) {
      baseValue = parseFloat(baseValue);
      value = parseFloat(value);
      if (baseValue >= value) {
        return `1% * 100 * 0.8`;
      } else {
        return `1% * (${baseValue} / ${value}) * 100 * 0.8`;
      }
    },
    getAscTeamStatsWidth: function (baseValue, value) {
      baseValue = parseFloat(baseValue);
      value = parseFloat(value);
      if (baseValue <= value) {
        return `1% * 100 * 0.8`;
      } else {
        return `1% * (${value} / ${baseValue}) * 100 * 0.8`;
      }
    },
    getGameScheduleList: function () {
      let gameScheduleList = {};
      let schedule = this.json.schedule;
      if (schedule) {
        const gameJson = this.gameJson;
        Object.keys(schedule).forEach(function (month) {
          gameScheduleList[month] = [];
          schedule[month].forEach((game) => {
            if (gameJson) {
              if (game.gameDate == gameJson.gameDate) {
                gameJson.gameList.forEach((todayGame) => {
                   if (game.gameGlobalId == todayGame.gameGlobalId) {
                     gameScheduleList[month].push(todayGame);
                   }
                });
              } else {
                gameScheduleList[month].push(game);
              }
            } else {
              gameScheduleList[month].push(game);
            }
          });
        });
      }
      return gameScheduleList;
    },
  }
}
</script>