<template>
<div id="bbrCom_wrap">

<!--順位表TOP_メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  <h2 class="bbrCom_ttl">順位表</h2>
  <template v-if="json.updateTime">
  <hr>
  <h3 class="bbrCom_ttlSc-ss bbrCom_txtDetail">{{ json.updateTime }}更新</h3>
  </template>
  
  
  <!-------------------------------------リーグ戦時のコーディング開始------------------------------------->
  <!--↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓-->
  <!--セ・リーグ-->
  <template v-if="json.cTeamStandingsList.length">
  <h3 class="bbrCom_ttlSc-t bbrCom_blCentral">セ・リーグ</h3>
  <table class="bbrCom_tbl  bbrRnk_top-tbl bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtTeam">チーム</th>
        <th>試合</th>
        <th>勝</th>
        <th>敗</th>
        <th>分</th>
        <th>勝率</th>
        <th>差</th>
        <th>CS</th>
      </tr>
      <tr v-for='(standing,index) in json.cTeamStandingsList' v-bind:key="index">
        <td>{{ standing.rank }}</td>
        <td class="bbrCom_tblTxtTeam"><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + standing.globalId"><span v-bind:class="'bbrCom_blTeam' + standing.id">{{ standing.nickname }}</span></a></td>
        <td>{{ Number(standing.win) + Number(standing.lose) + Number(standing.tie) }}</td>
        <td>{{ standing.win }}</td>
        <td>{{ standing.lose }}</td>
        <td>{{ standing.tie }}</td>
        <td>{{ standing.winRate }}</td>
        <template v-if="standing.magicNumber != null">
          <td v-if="standing.magicNumber == 0">優勝</td>
          <td v-else>M{{ standing.magicNumber }}</td>
        </template>
        <template v-else>
          <td>{{ standing.gamesBack }}</td>
        </template>
        <template v-if="standing.clinchNumber == '☆'">
          <td>確定</td>
        </template>
        <template v-else-if="standing.clinchNumber == '△'">
          <td></td>
        </template>
        <template v-else-if="standing.clinchNumber == '×'">
          <td>消滅</td>
        </template>
        <template v-else>
          <td></td>
        </template>
      </tr>
    </tbody>
  </table>
  <div class="bbrCom_txtDetail bbrCom_mb40"><a v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=1'">詳細はこちら</a></div>
  </template>
  <!--/セ・リーグ-->
  
  <!--パ・リーグ-->
  <template v-if="json.pTeamStandingsList.length">
  <h3 class="bbrCom_ttlSc-t bbrCom_blPacific">パ・リーグ</h3>
  <table class="bbrCom_tbl bbrRnk_top-tbl bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtTeam">チーム</th>
        <th>試合</th>
        <th>勝</th>
        <th>敗</th>
        <th>分</th>
        <th>勝率</th>
        <th>差</th>
        <th>CS</th>
      </tr>
      <tr v-for='(standing,index) in json.pTeamStandingsList' v-bind:key="index">
        <td>{{ standing.rank }}</td>
        <td class="bbrCom_tblTxtTeam"><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + standing.globalId"><span v-bind:class="'bbrCom_blTeam' + standing.id">{{ standing.nickname }}</span></a></td>
        <td>{{ Number(standing.win) + Number(standing.lose) + Number(standing.tie) }}</td>
        <td>{{ standing.win }}</td>
        <td>{{ standing.lose }}</td>
        <td>{{ standing.tie }}</td>
        <td>{{ standing.winRate }}</td>
        <template v-if="standing.magicNumber != null">
          <td v-if="standing.magicNumber == 0">優勝</td>
          <td v-else>M{{ standing.magicNumber }}</td>
        </template>
        <template v-else>
          <td>{{ standing.gamesBack }}</td>
        </template>
        <template v-if="standing.clinchNumber == '☆'">
          <td>確定</td>
        </template>
        <template v-else-if="standing.clinchNumber == '△'">
          <td></td>
        </template>
        <template v-else-if="standing.clinchNumber == '×'">
          <td>消滅</td>
        </template>
        <template v-else>
          <td></td>
        </template>
      </tr>
    </tbody>
  </table>
  <div class="bbrCom_txtDetail bbrCom_mb40"><a v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=2'">詳細はこちら</a></div>
  </template>
  <!--パ・リーグ-->
  
  <!--交流戦-->
  <template v-if="json.iTeamStandingsList.length">
  <h3 class="bbrCom_ttlSc-t bbrCom_blInterleague">交流戦</h3>
  <table class="bbrCom_tbl bbrRnk_top-tbl bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtTeam">チーム</th>
        <th>試合</th>
        <th>勝</th>
        <th>敗</th>
        <th>分</th>
        <th>勝率</th>
        <th>差</th>
      </tr>
      <tr v-for='(standing,index) in json.iTeamStandingsList' v-bind:key="index">
        <td>{{ standing.rank }}</td>
        <td class="bbrCom_tblTxtTeam"><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + standing.globalId"><span v-bind:class="'bbrCom_blTeam' + standing.id">{{ standing.nickname }}</span></a></td>
        <td>{{ Number(standing.win) + Number(standing.lose) + Number(standing.tie) }}</td>
        <td>{{ standing.win }}</td>
        <td>{{ standing.lose }}</td>
        <td>{{ standing.tie }}</td>
        <td>{{ standing.winRate }}</td>
        <template v-if="standing.magicNumber != null">
          <td v-if="standing.magicNumber == 0">優勝</td>
          <td v-else>M{{ standing.magicNumber }}</td>
        </template>
        <template v-else>
          <td>{{ standing.gamesBack }}</td>
        </template>
      </tr>
    </tbody>
  </table>
  <div class="bbrCom_txtDetail"><a v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=0'">詳細はこちら</a></div>
  </template>
  <!--交流戦-->
  <!--↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑-->
  <!-------------------------------------リーグ戦時のコーディング終了------------------------------------->

  
  <!-------------------------------------オープン戦時のコーディング開始------------------------------------->
  <!--↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓-->
  <!--オープン戦-->
  <template v-if="json.gameType == 0">
  <h3 class="bbrCom_ttlSc-t bbrCom_blInterleague">オープン戦</h3>
  <table class="bbrCom_tbl bbrRnk_top-tbl bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtTeam">チーム</th>
        <th>試合</th>
        <th>勝</th>
        <th>敗</th>
        <th>分</th>
        <th>勝率</th>
        <th>差</th>
      </tr>
      <tr v-for='(standing,index) in json.oTeamStandingsList' v-bind:key="index">
        <td>{{ standing.rank }}</td>
        <td class="bbrCom_tblTxtTeam"><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + standing.globalId"><span v-bind:class="'bbrCom_blTeam' + standing.id">{{ standing.nickname }}</span></a></td>
        <td>{{ Number(standing.win) + Number(standing.lose) + Number(standing.tie) }}</td>
        <td>{{ standing.win }}</td>
        <td>{{ standing.lose }}</td>
        <td>{{ standing.tie }}</td>
        <td>{{ standing.winRate }}</td>
        <td>{{ standing.gamesBack }}</td>
      </tr>
    </tbody>
  </table>
  </template>
  <!--オープン戦-->
  <!--↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑-->
  <!-------------------------------------オープン戦時のコーディング終了------------------------------------->

</div>
<!--/順位表TOP_メインコンテンツ-->

</div>
</template>

<script>

export default {
  data: function () {
    return {
      json: null,
    }
  },
  mounted: async function() {

  },
  methods: {

  }
}
</script>