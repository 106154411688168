<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  
  <!--チームを選ぶ-->
  <div class="bbrCom_selectMenu bbrTem_selectTeam">
  <label>
  <select id="bbrTem_selectTeamId" onchange="location.href = this.options[selectedIndex].value">
    <option>チームを選ぶ</option>
    <template v-for="(team) in json.teamList">
      <option v-bind:value="$config.baseUrl + '?kd_page=team_result&team_id=' + team.globalId + '&month=' + json.month" :key="team.id">{{ team.name }}</option>
    </template>
  </select>
  </label>
  </div>
  <!--/チームを選ぶ-->
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  
  <h2 class="bbrCom_ttl"><span v-bind:class="'bbrCom_blTeam' + json.team.id">{{ json.team.name }}</span></h2>
  
  <!--チーム情報ページ_メインメニュー-->
  <ul id="bbrTem_mainMenu" class="bbrCom_tabMenu02">
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + json.team.globalId">チームトップ</a></li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId">所属選手</a></li>
    <li>
      <a v-if="gameJson != null" v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + getMonth(json.gameDate)" class="bbrCom_current">日程・結果</a>
      <a v-else href="" class="bbrCom_current">日程・結果</a>
    </li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + getVsTeamId(json.team.globalId)">対戦・成績</a></li>
  </ul>
  <!--/チーム情報ページ_メインメニュー-->
  
  <ul id="bbrTem_monthTabMenu01" class="bbrCom_tabMenu01 bbrCom_tac bbrCom_mb40">
    <li v-for='(month, index) in json.monthList' v-bind:key="index">
      <a v-if="month== json.month" v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + month" class="bbrCom_current">{{ getFormattedMonth(month) }}月</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + month">{{ getFormattedMonth(month) }}月</a>
    </li>
  </ul>
  
  <!--2017年3月-->
  <h3 class="bbrCom_ttlSc">{{ getFormattedYearMonth(json.month) }}</h3>
  <template v-if="json.schedule">
  <table class="bbrCom_tbl bbrCom_tblResult bbrCom_tbl-nonth bbrCom_mb30">
    <tbody>
      <tr v-for='(game) in getGameScheduleList()' v-bind:key="game.gameGlobalId">
        <td>{{ getFormattedDay(game.gameDate) }}<br>{{ getFormattedStartTime(game.startTime) }}</td>
        <td>{{ game.venueNickName }}</td>
        <td>
        <template v-if="game.gameStatusName == '試合前' || game.gameStatusName == '中止'">
          <ul>
            <li>
              <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
              <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
            </li>
            <li>
              <p>{{ game.gameStatusName }}</p>
            </li>
            <li>
              <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
              <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
            </li>
          </ul>
        </template>
        <template v-else-if="game.gameStatusName == 'ノーゲーム'">
          <a v-bind:href="getGameLink($config.baseUrl, game)">
            <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <p>{{ game.gameStatusName }}</p>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
          </a>
        </template>
        <template v-else>
          <a v-bind:href="getGameLink($config.baseUrl, game)">
            <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <span>{{ game.home.score }}</span>
                <span>-</span>
                <span>{{ game.visitor.score }}</span>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
          </a>
        </template>
        </td>
        <td>
          <dl>
            <template v-if="game.gameStatusName == '試合終了'">
              <template v-if="game.home.winLose == 'Win' || game.home.winLose == 'true' || game.visitor.winLose == 'Win' || game.visitor.winLose == 'true'">
                <dt>勝</dt>
                <template v-if="(game.home.winLose == 'Win' || game.home.winLose == 'true') && game.home.pitcher != null">
                  <dd>{{ game.home.pitcher }}</dd>
                </template>
                <template v-else-if="(game.visitor.winLose == 'Win' || game.visitor.winLose == 'true') && game.visitor.pitcher != null">
                  <dd>{{ game.visitor.pitcher }}</dd>
                </template>
                <template v-else>
                  <dd>&nbsp;</dd>
                </template>
                <template v-if="game.savePitcher != null">
                  <dt>S</dt>
                  <dd>{{ game.savePitcher }}</dd>
                </template>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dt>&nbsp;</dt>
              </template>
            </template>
            <template v-else-if="game.gameStatusName == '試合前'">
              <template v-if="game.visitor.startingPitcher == null">
              <dt>&nbsp;</dt>
              </template>
              <template v-else>
              <dt>予</dt>
              </template>
              <dd v-if="game.visitor.startingPitcher == null">&nbsp;</dd>
              <dd v-else>{{ game.visitor.startingPitcher }}</dd>
            </template>
            <template v-else-if="game.gameStatusName == '試合中'">
              <dt>&nbsp;</dt>
               <dd v-if="game.visitor.pitcher == null">&nbsp;</dd>
              <dd v-else>{{ game.visitor.pitcher }}</dd>
            </template>
            <template v-else>
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
            </template>

            <template v-if="game.gameStatusName == '試合終了'">
              <template v-if="game.home.winLose == 'Loss' || game.home.winLose == 'false' || game.visitor.winLose == 'Loss' || game.visitor.winLose == 'false'">
                <dt>負</dt>
                <template v-if="(game.home.winLose == 'Loss' || game.home.winLose == 'false') && game.home.pitcher != null">
                  <dd>{{ game.home.pitcher }}</dd>
                </template>
                <template v-else-if="(game.visitor.winLose == 'Loss' || game.visitor.winLose == 'false') && game.visitor.pitcher != null">
                  <dd>{{ game.visitor.pitcher }}</dd>
                </template>
                <template v-else>
                  <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else>
                <dd></dd>
              </template>
            </template>
            <template v-else-if="game.gameStatusName == '試合前'">
              <template v-if="game.home.startingPitcher == null">
              <dt>&nbsp;</dt>
              </template>
              <template v-else>
              <dt>予</dt>
              </template>
              <dd v-if="game.home.startingPitcher == null">&nbsp;</dd>
              <dd v-else>{{ game.home.startingPitcher }}</dd>
            </template>
            <template v-else-if="game.gameStatusName == '試合中'">
              <dt>&nbsp;</dt>
              <dd v-if="game.home.pitcher == null">&nbsp;</dd>
              <dd v-else>{{ game.home.pitcher }}</dd>
            </template>
            <template v-else>
              <dt>&nbsp;</dt>
              <dd>&nbsp;</dd>
            </template>
          </dl>
        </td>
      </tr>
    </tbody>
  </table>
  </template>
  <template v-else>
    <div>試合の予定はありません</div>
  </template>
  <!--/2017年3月-->
  
 </div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
    }
  },
  mounted: async function() {
    this.gameJson = await this.loadLatestGame();
    this.$config.callback();
  },
  methods: {
    getGameScheduleList: function () {
      let gameScheduleList = [];
      let schedule = this.json.schedule;
      if (schedule) {
        const gameJson = this.gameJson;
        schedule.forEach((game) => {
          if (gameJson) {
            if (game.gameDate == gameJson.gameDate) {
              gameJson.gameList.forEach((todayGame) => {
                if (game.gameGlobalId == todayGame.gameGlobalId) {
                  gameScheduleList.push(todayGame);
                }
              });
            } else {
              gameScheduleList.push(game);
            }
          } else {
            gameScheduleList.push(game);
          }
        });
      }
      return gameScheduleList;
    },
  }
}
</script>