<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  
  <!--チームを選ぶ-->
  <div class="bbrCom_selectMenu bbrTem_selectTeam">
  <label>
  <select id="bbrTem_selectTeamId" onchange="location.href = this.options[selectedIndex].value">
    <option>チームを選ぶ</option>
    <template v-for="(team) in json.teamList">
      <option v-bind:value="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId" :key="team.id">{{ team.name }}</option>
    </template>
  </select>
  </label>
  </div>
  <!--/チームを選ぶ-->
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  
  <h2 class="bbrCom_ttl"><span v-bind:class="'bbrCom_blTeam' + json.team.id">{{ json.team.name }}</span></h2>
  
  <!--チーム情報ページ_メインメニュー-->
  <ul id="bbrTem_mainMenu" class="bbrCom_tabMenu02">
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + json.team.globalId" class="bbrCom_current">チームトップ</a></li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId">所属選手</a></li>
    <li>
      <a v-if="gameJson != null" v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + getMonth(json.gameDate)">日程・結果</a>
      <a v-else href="">日程・結果</a>
    </li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + getVsTeamId(json.team.globalId )">対戦・成績</a></li>
  </ul>
  <!--/チーム情報ページ_メインメニュー-->
  
  <ul id="bbrTem_tListTabMenu01" class="bbrCom_tabMenu01 bbrCom_tac bbrCom_mb40">
    <li>
      <a v-if="mode == 'batting'" v-on:click="setBatter" href="" class="bbrCom_current">チーム内打者成績</a>
      <a v-else v-on:click="setBatter" href="">チーム内打者成績</a>
    </li>
    <li>
      <a v-if="mode == 'pitching'" v-on:click="setPitcher" href="" class="bbrCom_current">チーム内投手成績</a>
      <a v-else v-on:click="setPitcher" href="">チーム内投手成績</a>
    </li>
  </ul>
  
  <!--チーム内投手成績-->
  <h3 v-if="mode == 'pitching'" class="bbrCom_ttlSc">チーム内投手成績</h3>
  <h3 v-else-if="mode == 'batting'" class="bbrCom_ttlSc">チーム内打者成績</h3>

  <table class="bbrCom_tbl bbrTem_tblTop">
    <tbody>
      <tr>
        <th>項目</th>
        <th>成績</th>
        <th>選手名</th>
        <th>リーグ内順位</th>
      </tr>
      <tr v-bind:class="'bbrCom_tblCellAr'" v-for='(rank,index) in json.rankOrder[mode]' v-bind:key="index">
        <td>{{ rank.name }}</td>
        <template v-if="json.teamLeadersList[mode][rank.code]">
        <td>{{ json.teamLeadersList[mode][rank.code].value }}</td>
        <td>
          <span>{{ json.teamLeadersList[mode][rank.code].uniform }}</span>
          <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.team.globalId + '&player_id=' + json.teamLeadersList[mode][rank.code].globalId">{{ json.teamLeadersList[mode][rank.code].name }}</a>
          <a v-else>{{ json.teamLeadersList[mode][rank.code].name }}</a>
        </td>
        <td>{{ json.teamLeadersList[mode][rank.code].rank }}</td>
        </template>
        <template v-else>
          <td></td>
          <td></td>
          <td></td>
        </template>
      </tr>
    </tbody>
  </table>
  <!--/チーム内投手成績-->
  
 </div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
      mode: 'pitching',
    }
  },
  mounted: async function() {
    this.gameJson = await this.loadLatestGame();
    this.$config.callback();
  },
  methods: {
    setBatter: function (e) {
      e.preventDefault();
      this.mode = 'batting';
    },
    setPitcher: function (e) {
      e.preventDefault();
      this.mode = 'pitching';
    },
  }
}
</script>