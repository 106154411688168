<template>
<div>

<!--速報ページ_メインメニュー-->
<ul id="bbrSok_mainMenu" class="bbrCom_tabMenu02">
  <li><a v-bind:href="$config.baseUrl + '?kd_page=game&global_id=' + json.gameInfo.gameGlobalId" class="bbrCom_current">試合情報</a></li>
  <template v-if="json.gameInfo.gameStatus != 1 && json.gameInfo.gameStatus != 23">
  <li><a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId">一球速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=inning&global_id=' + json.gameInfo.gameGlobalId">テキスト速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=member&global_id=' + json.gameInfo.gameGlobalId">出場成績</a></li>
  </template>
</ul>
<!--/速報ページ_メインメニュー-->

<!--速報ページ_メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">

  <div id="bbrSok_game_date">{{ getFormattedGameStartTime(json.gameDate, json.gameInfo.startTime) }} {{ json.gameInfo.venueName }}</div>
  <template v-if="json.gameInfo.gameStatusName == '試合終了' && json.gameEndInfo.timeOfGame != null">
  <p id="bbrRst_game_rstTxt">[試合時間]{{ getFormattedTimeOfGame(json.gameEndInfo.timeOfGame) }} [入場数]{{ json.gameEndInfo.attendance }}人/{{ json.gameEndInfo.round }}回戦（[{{ json.gameInfo.home.aliasName }}]{{ json.teamStandingsSplits.win }}勝{{ json.teamStandingsSplits.lose }}敗{{ json.teamStandingsSplits.tie }}分）</p>
  </template>
  <!--スコアボード-->
  <template v-if="json.lineScore">
  <div class="bbrCom_scrTbl">
    <table id="bbrCom_scrTbl-scoreIn12">
        <tbody>
          <tr class="hd">
            <th class="bbrCom_scrTbl_tName">&nbsp;</th>
            <th v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              {{ inning.number }}
            </th>
            <th class="bbrCom_scrTbl_sum">計</th>
            <th class="bbrCom_scrTbl_hit">H</th>
            <th class="bbrCom_scrTbl_error">E</th>
        </tr>
        <tr class="bbrCom_scrTbl-first">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.visitor.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.visitor.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId + '&inning=' + inning.number + '&tob=1&flow=1'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.visitor.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.visitor.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.visitor.errors }}</td>
        </tr>
        <tr class="bbrCom_scrTbl-second">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.home.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.home.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.home.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId + '&inning=' + inning.number + '&tob=2&flow=1'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.home.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.home.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.home.errors }}</td>
        </tr>
    </tbody></table>
  </div>
  </template>
  <!--/スコアボード-->
  <!--/予告先発-->
  <template v-if="json.gameInfo.gameStatus == 1 || json.gameInfo.gameStatus == 23">
  <template v-if="json.gameInfo.gameType == 0">
  <div id="bbrRst_g01_VsCrd">
    <div id="bbrRst_g01_VsCrd-Pos1" class="bbrRst_g01_VsCrd-Main">
      <h3 v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</h3>
    </div>
    <div id="bbrRst_g01_VsCrd-PosVS">VS</div>
    <div id="bbrRst_g01_VsCrd-Pos2" class="bbrRst_g01_VsCrd-Main">
      <h3 v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id">{{ json.gameInfo.visitor.nickname }}</h3>
    </div>
  </div>
  </template>
  <template v-else>
  <div id="bbrRst_g01_VsCrd">
    <div id="bbrRst_g01_VsCrd-Pos1" class="bbrRst_g01_VsCrd-Main">
      <h3 v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</h3>
      <template v-if="json.gameInfo.home.startingPitcher.globalId != null">
      <div class="bbrRst_g01_VsCrd-Main_pch">
        <span>予告先発</span>
        <p>{{ json.gameInfo.home.startingPitcher.name }}</p>
      </div>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <th>勝</th>
            <th>負</th>
            <th>防御率</th>
            <th>三振</th>
            <th>WHIP</th>
          </tr>
          <tr>
            <template v-if="json.gameInfo.home.startingPitcher.wins != null">
            <td>{{ json.gameInfo.home.startingPitcher.wins }}</td>
            <td>{{ json.gameInfo.home.startingPitcher.loses }}</td>
            <td>{{ json.gameInfo.home.startingPitcher.earnedRunAverage }}</td>
            <td>{{ json.gameInfo.home.startingPitcher.strikeOuts }}</td>
            <td>{{ json.gameInfo.home.startingPitcher.whip }}</td>
            </template>
            <template v-else>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            </template>
          </tr>
        </tbody>
      </table>
      </template>
    </div>
    <div id="bbrRst_g01_VsCrd-PosVS">VS</div>
    <div id="bbrRst_g01_VsCrd-Pos2" class="bbrRst_g01_VsCrd-Main">
      <h3 v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id">{{ json.gameInfo.visitor.nickname }}</h3>
      <template v-if="json.gameInfo.visitor.startingPitcher.globalId != null">
      <div class="bbrRst_g01_VsCrd-Main_pch">
        <span>予告先発</span>
        <p>{{ json.gameInfo.visitor.startingPitcher.name }}</p>
      </div>
      <table border="0" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <th>勝</th>
            <th>負</th>
            <th>防御率</th>
            <th>三振</th>
            <th>WHIP</th>
          </tr>
          <tr>
            <template v-if="json.gameInfo.visitor.startingPitcher.wins != null">
            <td>{{ json.gameInfo.visitor.startingPitcher.wins }}</td>
            <td>{{ json.gameInfo.visitor.startingPitcher.loses }}</td>
            <td>{{ json.gameInfo.visitor.startingPitcher.earnedRunAverage }}</td>
            <td>{{ json.gameInfo.visitor.startingPitcher.strikeOuts }}</td>
            <td>{{ json.gameInfo.visitor.startingPitcher.whip }}</td>
            </template>
            <template v-else>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            </template>
          </tr>
        </tbody>
      </table>
      </template>
    </div>
  </div>
  <!--/予告先発-->
  <!--対戦成績-->
  <h3 class="bbrCom_ttlSc">{{ getGameTypeName(json.gameInfo.gameType) }}の対戦成績</h3>
  <template v-if="json.teamStandingsSplits.win == null">
    <div class="bbrCom_mb50">対戦データがありません</div>
  </template>
  <template v-else>
  <div id="bbrCom_vsBar" class="bbrCom_mb50">
    <ul class="bbrCom_cf">
      <li id="bbrCom_vsBar-txtL">{{ json.gameInfo.home.nickname }} <span class="bbrCom_vsBar-res">{{ json.teamStandingsSplits.win }}勝（{{ json.teamStandingsSplits.tie }}分）</span></li>
      <li id="bbrCom_vsBar-txtR">{{ json.gameInfo.visitor.nickname }} <span class="bbrCom_vsBar-res">{{ json.teamStandingsSplits.lose }}勝</span></li>
    </ul>
    <div id="bbrCom_vsBar_Main">
      <div id="bbrCom_vsBar_Main-left" v-bind:style="'width: calc(' + getTeamStandingsSplitsWidth('home') + ')'" v-bind:class="'bbrCom_bgTeam' + json.gameInfo.home.id"></div>
      <div id="bbrCom_vsBar_Main-right" v-bind:style="'width: calc(' + getTeamStandingsSplitsWidth('visitor') + ')'" v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id"></div>
    </div>
  </div>
  </template>
  <!--/対戦成績-->
  <!--過去5試合の対戦成績-->
  <h3 class="bbrCom_ttlSc">過去5試合の対戦成績</h3>
  <template v-if="json.latestResult.length == 0">
    <div class="bbrCom_mb50">対戦データがありません</div>
  </template>
  <template v-else>
  <table class="bbrCom_tbl bbrCom_tblResult bbrCom_tbl-nonth bbrCom_mb50">
    <tbody>
    <tr v-for='(game) in json.latestResult' v-bind:key="game.gameGlobalId">
          <td>{{ getFormattedDay(game.gameDate) }}<br>{{ getFormattedStartTime(game.startTime) }}</td>
          <td>{{ game.venueNickName }}</td>
          <td>
            <template v-if="game.gameStatusName == '試合前' || game.gameStatusName == '中止'">
            <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
              <li>
                <p>{{ game.gameStatusName }}</p>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
            </ul>
            </template>
            <template v-else>
            <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + game.gameGlobalId">
              <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
              <li>
                <span>{{ game.visitor.score }}</span>
                <span>-</span>
                <span>{{ game.home.score }}</span>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
            </ul>
            </a>
            </template>
          </td>
          <td>
            <dl>
              <template v-if="game.gameStatusName == '試合終了'">
                <template v-if="game.home.winLose == 'Win' || game.visitor.winLose == 'Win'">
                  <dt>勝</dt>
                  <template v-if="game.home.winLose == 'Win' && game.home.pitcher != null">
                    <dd>{{ game.home.pitcher }}</dd>
                  </template>
                  <template v-else-if="game.visitor.winLose == 'Win' && game.visitor.pitcher != null">
                    <dd>{{ game.visitor.pitcher }}</dd>
                  </template>
                  <template v-else>
                    <dd>&nbsp;</dd>
                  </template>
                  <template v-if="game.savePitcher != null">
                    <dt>S</dt>
                    <dd>{{ game.savePitcher }}</dd>
                  </template>
                </template>
                <template v-else>
                <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else-if="game.gameStatusName == '試合前'">
                <template v-if="game.visitor.startingPitcher == null">
                <dt>&nbsp;</dt>
                </template>
                <template v-else>
                <dt>予</dt>
                </template>
                <dd v-if="game.visitor.startingPitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.visitor.startingPitcher }}</dd>
              </template>
              <template v-else-if="game.gameStatusName == '試合中'">
                <dt>&nbsp;</dt>
                <dd v-if="game.visitor.pitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.visitor.pitcher }}</dd>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dd>&nbsp;</dd>
              </template>

              <template v-if="game.gameStatusName == '試合終了'">
                <template v-if="game.home.winLose == 'Loss' || game.visitor.winLose == 'Loss'">
                  <dt>負</dt>
                  <template v-if="game.home.winLose == 'Loss' && game.home.pitcher != null">
                    <dd>{{ game.home.pitcher }}</dd>
                  </template>
                  <template v-else-if="game.visitor.winLose == 'Loss' && game.visitor.pitcher != null">
                    <dd>{{ game.visitor.pitcher }}</dd>
                  </template>
                  <template v-else>
                    <dd>&nbsp;</dd>
                  </template>
                </template>
                <template v-else>
                <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else-if="game.gameStatusName == '試合前'">
                <template v-if="game.home.startingPitcher == null">
                <dt>&nbsp;</dt>
                </template>
                <template v-else>
                <dt>予</dt>
                </template>
                <dd v-if="game.home.startingPitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.home.startingPitcher }}</dd>
              </template>
              <template v-else-if="game.gameStatusName == '試合中'">
                <dt>&nbsp;</dt>
                <dd v-if="game.home.pitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.home.pitcher }}</dd>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dd>&nbsp;</dd>
              </template>
            </dl>
          </td>
        </tr>
    </tbody>
  </table>
  </template>
  <!--/過去5試合の対戦成績-->
  <!--チーム比較-->
  <template v-if="json.teamStats.home.average.value != null">
  <h3 class="bbrCom_ttlSc">チーム比較</h3>
  <div id="bbrCom_vsGraph" class="bbrCom_mb50">
  <table id="bbrCom_vsGraphTbl" class="bbrCom_vsGraphTbl_addVs">
    <tbody>
      <tr>
        <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</th>
        <th>&nbsp;</th>
        <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"><span>{{ json.gameInfo.visitor.nickname }}</span></th>
      </tr>
      <tr>
        <td>
          <span>{{ json.teamStats.home.average.value }}({{ json.teamStats.home.average.rank }}位)</span>
          <template v-if="json.teamStats.home.average.rank <= json.teamStats.visitor.average.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.home.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.average.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.average.rank) + ')'"></p>
          </template>
        </td>
        <td>打率</td>
        <td>
          <template v-if="json.teamStats.visitor.average.rank <= json.teamStats.home.average.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.average.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.average.rank) + ')'"></p>
          </template>
          <span>{{ json.teamStats.visitor.average.value }}({{ json.teamStats.visitor.average.rank }}位)</span></td>
      </tr>
      <tr>
        <td>
          <span>{{ json.teamStats.home.homeRuns.value }}({{ json.teamStats.home.homeRuns.rank }}位)</span>
          <template v-if="json.teamStats.home.homeRuns.rank <= json.teamStats.visitor.homeRuns.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.home.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.homeRuns.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.homeRuns.rank) + ')'"></p>
          </template>
        </td>
        <td>本塁打</td>
        <td>
          <template v-if="json.teamStats.visitor.homeRuns.rank <= json.teamStats.home.homeRuns.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.homeRuns.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.homeRuns.rank) + ')'"></p>
          </template>
          <span>{{ json.teamStats.visitor.homeRuns.value }}({{ json.teamStats.visitor.homeRuns.rank }}位)</span></td>
      </tr>
      <tr>
        <td>
          <span>{{ json.teamStats.home.stolenBases.value }}({{ json.teamStats.home.stolenBases.rank }}位)</span>
          <template v-if="json.teamStats.home.stolenBases.rank <= json.teamStats.visitor.stolenBases.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.home.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.stolenBases.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.stolenBases.rank) + ')'"></p>
          </template>
        </td>
        <td>盗塁</td>
        <td>
          <template v-if="json.teamStats.visitor.stolenBases.rank <= json.teamStats.home.stolenBases.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.stolenBases.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.stolenBases.rank) + ')'"></p>
          </template>
          <span>{{ json.teamStats.visitor.stolenBases.value }}({{ json.teamStats.visitor.stolenBases.rank }}位)</span></td>
      </tr>
      <tr>
        <td>
          <span>{{ json.teamStats.home.earnedRunAverage.value }}({{ json.teamStats.home.earnedRunAverage.rank }}位)</span>
          <template v-if="json.teamStats.home.earnedRunAverage.rank <= json.teamStats.visitor.earnedRunAverage.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.home.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.earnedRunAverage.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.home.earnedRunAverage.rank) + ')'"></p>
          </template>
        </td>
        <td>防御率</td>
        <td>
          <template v-if="json.teamStats.visitor.earnedRunAverage.rank <= json.teamStats.home.earnedRunAverage.rank">
          <p v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id" v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.earnedRunAverage.rank) + ')'"></p>
          </template>
          <template v-else>
          <p v-bind:style="'width: calc(' + getTeamStatsWidth(json.teamStats.visitor.earnedRunAverage.rank) + ')'"></p>
          </template>
          <span>{{ json.teamStats.visitor.earnedRunAverage.value }}({{ json.teamStats.visitor.earnedRunAverage.rank }}位)</span></td>
      </tr>
    </tbody>
  </table>
  </div>
  </template>
  <!--/チーム比較-->
  </template>
  <!--試合前スタメン-->
  <template v-if="json.playerList && json.playerList.length != 0">
  <!--スターティングメンバー-->
    <h3 class="bbrCom_ttlSc">スターティングメンバー</h3>
    <div class="bbrCom_stMember">
      <table>
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_stMember_tName bbrCom_bbTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</th>
            <th v-bind:class="'bbrCom_stMember_tName bbrCom_bbTeam' + json.gameInfo.visitor.id">{{ json.gameInfo.visitor.nickname }}</th>
          </tr>
        </tbody>
      </table>
      
      <table class="bbrCom_mb5">
        <tbody>
          <tr class="bbrCom_stMember_playerSet">
            <th class="bbrCom_stMember_playerSet-pos1">打順</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">打</th>
            <th class="bbrCom_stMember_playerSet-pos5">打率</th>
            <th class="bbrCom_stMember_playerSet-pos1">打順</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">打</th>
            <th class="bbrCom_stMember_playerSet-pos5">打率</th>
          </tr>
          <tr v-for='(result,index) in getBattingList()' v-bind:key="index">
            <td>{{ index+1 }}</td>
            <td>({{ result.home.pos }})</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.home.globalId + '&player_id=' + json.playerList[result.home.globalId].globalId">{{ json.playerList[result.home.globalId].name }}</a>
              <a v-else>{{ json.playerList[result.home.globalId].name }}</a>
            </td>
            <td>{{ side[json.playerList[result.home.globalId].batSide] }}</td>
            <td>{{ json.playerList[result.home.globalId].seasonBatting.average }}</td>
            <td>{{ index+1 }}</td>
            <td>({{ result.visitor.pos }})</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.visitor.globalId + '&player_id=' + json.playerList[result.visitor.globalId].globalId">{{ json.playerList[result.visitor.globalId].name }}</a>
              <a v-else>{{ json.playerList[result.visitor.globalId].name }}</a>
            </td>
            <td>{{ side[json.playerList[result.visitor.globalId].batSide] }}</td>
            <td>{{ json.playerList[result.visitor.globalId].seasonBatting.average }}</td>
          </tr>
        </tbody>
      </table>
    
      <table>
        <tbody>
          <tr class="bbrCom_stMember_playerSet">
            <th class="bbrCom_stMember_playerSet-pos1">&nbsp;</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">投</th>
            <th class="bbrCom_stMember_playerSet-pos5">防御率</th>
            <th class="bbrCom_stMember_playerSet-pos1">&nbsp;</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">投</th>
            <th class="bbrCom_stMember_playerSet-pos5">防御率</th>
          </tr>
          <tr>
            <td>先発</td>
            <td>（投）</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.home.globalId + '&player_id=' + getMoundPitcher(json.gameInfo.home.globalId).globalId">{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
              <a v-else>{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
            </td>
            <td>{{ side[getMoundPitcher(json.gameInfo.home.globalId).throwing] }}</td>
            <template v-if="getMoundPitcher(json.gameInfo.home.globalId).seasonPitching != null">
            <td>{{ getMoundPitcher(json.gameInfo.home.globalId).seasonPitching.earnedRunAverage }}</td>
            </template>
            <template v-else>
            <td>-</td>
            </template>
            <td>先発</td>
            <td>（投）</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.visitor.globalId + '&player_id=' + getMoundPitcher(json.gameInfo.visitor.globalId).globalId">{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
              <a v-else>{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
            </td>
            <td>{{ side[getMoundPitcher(json.gameInfo.visitor.globalId).throwing] }}</td>
            <template v-if="getMoundPitcher(json.gameInfo.visitor.globalId).seasonPitching != null">
            <td>{{ getMoundPitcher(json.gameInfo.visitor.globalId).seasonPitching.earnedRunAverage }}</td>
            </template>
            <template v-else>
            <td>-</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/スターティングメンバー02-->
    <!--ベンチ入り選手-->
    <template v-if="json.gameInfo.gameType != 0 && json.benchMemberList && json.benchMemberList.length != 0">
    <h3 class="bbrCom_ttlSc">ベンチ入り選手</h3>
    <!--投手-->
    <h3 class="bbrCom_ttlSc-s">- 投手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>投</th>
                <th>防御率</th>
              </tr>
              <tr v-for='(member,index) in getBenchPitcherList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].throwing] }}</td>
                <template v-if="json.playerList[member.globalId].seasonPitching">
                  <td>{{ json.playerList[member.globalId].seasonPitching.earnedRunAverage }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>投</th>
                <th>防御率</th>
              </tr>
              <tr v-for='(member,index) in getBenchPitcherList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].throwing] }}</td>
                <template v-if="json.playerList[member.globalId].seasonPitching">
                  <td>{{ json.playerList[member.globalId].seasonPitching.earnedRunAverage }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/投手-->
    <!--捕手-->
    <h3 class="bbrCom_ttlSc-s">- 捕手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchCatcherList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchCatcherList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/捕手-->
    <!--内野手-->
    <h3 class="bbrCom_ttlSc-s">- 内野手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchInfielderList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchInfielderList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/内野手-->
    <!--外野手-->
    <h3 class="bbrCom_ttlSc-s">- 外野手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchOutfielderList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchOutfielderList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </template>
    <!--/外野手-->
  </template>
  <!--/試合前スタメン-->
  </template>
  <!--/試合前以外-->
  <template v-else>
    <template v-if="json.gameInfo.gameStatusName == '試合終了'">
    <!--勝敗投手-->
    <table class="bbrCom_bdrTable bbrRst_bdrTableSet bbrCom_mb50">
      <tbody>
        <tr>
          <th>勝利投手</th>
          <td>{{ getWinLosePitcher(json.winPitcher) }}</td>
        </tr>
        <tr>
          <th>敗戦投手</th>
          <td>{{ getWinLosePitcher(json.losePitcher) }}</td>
        </tr>
        <tr>
          <th>セーブ</th>
          <td>{{ getWinLosePitcher(json.savePitcher) }}</td>
        </tr>
      </tbody>
    </table>
    </template>
    <!--/勝敗投手-->
    <!--コメント-->
    <template v-if="json.gameEndInfo.review != null">
    <h3 class="bbrCom_ttlSc">{{ getFormattedDay(json.gameDate) }} {{ json.gameInfo.home.nickname }}vs{{ json.gameInfo.visitor.nickname }}{{ json.gameEndInfo.round }}回戦</h3>
    <p class="bbrCom_mb50">{{ json.gameEndInfo.review }}</p>
    </template>
    <!--/コメント-->
    <!--バッテリー-->
    <h3 class="bbrCom_ttlSc">バッテリー</h3>
    <table class="bbrCom_bdrTable bbrRst_bdrTableSet bbrCom_mb50">
      <tbody>
        <tr>
          <th>{{ json.gameInfo.visitor.nickname }}</th>
          <td>{{ getPitcherList(json.gameInfo.visitor.globalId) }} - {{ getCatcherList(json.gameInfo.visitor.globalId) }}</td>
        </tr>
        <tr>
          <th>{{ json.gameInfo.home.nickname }}</th>
          <td>{{ getPitcherList(json.gameInfo.home.globalId) }} - {{ getCatcherList(json.gameInfo.home.globalId) }}</td>
        </tr>
      </tbody>
    </table>
    <!--バッテリー-->
    <!--本塁打-->
    <h3 class="bbrCom_ttlSc">本塁打</h3>
    <table class="bbrCom_bdrTable bbrRst_bdrTableSet bbrCom_mb50">
      <tbody>
        <tr>
          <th>{{ json.gameInfo.visitor.nickname }}</th>
          <td>{{ getHomeredList(json.gameInfo.visitor.globalId) }}</td>
        </tr>
        <tr>
          <th>{{ json.gameInfo.home.nickname }}</th>
          <td>{{ getHomeredList(json.gameInfo.home.globalId) }}</td>
        </tr>
      </tbody>
    </table>
    <!--本塁打-->
    <!--スターティングメンバー-->
    <template v-if="json.gameInfo.gameStatus == 2">
    <h3 class="bbrCom_ttlSc">スターティングメンバー</h3>
    <div class="bbrCom_stMember">
      <table>
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_stMember_tName bbrCom_bbTeam' + json.gameInfo.visitor.id">{{ json.gameInfo.visitor.nickname }}</th>
            <th v-bind:class="'bbrCom_stMember_tName bbrCom_bbTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</th>
          </tr>
        </tbody>
      </table>
      
      <table class="bbrCom_mb5">
        <tbody>
          <tr class="bbrCom_stMember_playerSet">
            <th class="bbrCom_stMember_playerSet-pos1">打順</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">打</th>
            <th class="bbrCom_stMember_playerSet-pos5">打率</th>
            <th class="bbrCom_stMember_playerSet-pos1">打順</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">打</th>
            <th class="bbrCom_stMember_playerSet-pos5">打率</th>
          </tr>
          <tr v-for='(result,index) in getBattingList()' v-bind:key="index">
            <td>{{ index+1 }}</td>
            <td>({{ result.visitor.pos }})</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.visitor.globalId + '&player_id=' + json.playerList[result.visitor.globalId].globalId">{{ json.playerList[result.visitor.globalId].name }}</a>
              <a v-else>{{ json.playerList[result.visitor.globalId].name }}</a>
            </td>
            <td>{{ side[json.playerList[result.visitor.globalId].batSide] }}</td>
            <td>{{ json.playerList[result.visitor.globalId].seasonBatting.average }}</td>
            <td>{{ index+1 }}</td>
            <td>({{ result.home.pos }})</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.home.globalId + '&player_id=' + json.playerList[result.home.globalId].globalId">{{ json.playerList[result.home.globalId].name }}</a>
              <a v-else>{{ json.playerList[result.home.globalId].name }}</a>
            </td>
            <td>{{ side[json.playerList[result.home.globalId].batSide] }}</td>
            <td>{{ json.playerList[result.home.globalId].seasonBatting.average }}</td>
          </tr>
        </tbody>
      </table>
    
      <table>
        <tbody>
          <tr class="bbrCom_stMember_playerSet">
            <th class="bbrCom_stMember_playerSet-pos1">&nbsp;</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">投</th>
            <th class="bbrCom_stMember_playerSet-pos5">防御率</th>
            <th class="bbrCom_stMember_playerSet-pos1">&nbsp;</th>
            <th class="bbrCom_stMember_playerSet-pos2">位置</th>
            <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
            <th class="bbrCom_stMember_playerSet-pos4">投</th>
            <th class="bbrCom_stMember_playerSet-pos5">防御率</th>
          </tr>
          <tr>
            <td>先発</td>
            <td>（投）</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.visitor.globalId + '&player_id=' + getMoundPitcher(json.gameInfo.visitor.globalId).globalId">{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
              <a v-else>{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
            </td>
            <td>{{ side[getMoundPitcher(json.gameInfo.visitor.globalId).throwing] }}</td>
            <td>{{ getMoundPitcher(json.gameInfo.visitor.globalId).seasonPitching.earnedRunAverage }}</td>
            <td>先発</td>
            <td>（投）</td>
            <td class="bbrCom_stMember_playerName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.gameInfo.home.globalId + '&player_id=' + getMoundPitcher(json.gameInfo.home.globalId).globalId">{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
              <a v-else>{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
            </td>
            <td>{{ side[getMoundPitcher(json.gameInfo.home.globalId).throwing] }}</td>
            <td>{{ getMoundPitcher(json.gameInfo.home.globalId).seasonPitching.earnedRunAverage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/スターティングメンバー02-->
    <!--ベンチ入り選手-->
    <template v-if="json.gameInfo.gameType != 0">
    <h3 class="bbrCom_ttlSc">ベンチ入り選手</h3>
    <!--投手-->
    <h3 class="bbrCom_ttlSc-s">- 投手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>投</th>
                <th>防御率</th>
              </tr>
              <tr v-for='(member,index) in getBenchPitcherList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].throwing] }}</td>
                <template v-if="json.playerList[member.globalId].seasonPitching">
                  <td>{{ json.playerList[member.globalId].seasonPitching.earnedRunAverage }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>投</th>
                <th>防御率</th>
              </tr>
              <tr v-for='(member,index) in getBenchPitcherList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].throwing] }}</td>
                <template v-if="json.playerList[member.globalId].seasonPitching">
                  <td>{{ json.playerList[member.globalId].seasonPitching.earnedRunAverage }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/投手-->
    <!--捕手-->
    <h3 class="bbrCom_ttlSc-s">- 捕手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchCatcherList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchCatcherList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/捕手-->
    <!--内野手-->
    <h3 class="bbrCom_ttlSc-s">- 内野手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchInfielderList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchInfielderList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/内野手-->
    <!--外野手-->
    <h3 class="bbrCom_ttlSc-s">- 外野手</h3>
    <div class="bbrRst_btMemberTbl">
      <table class="bbrRst_btMemberTbl_tColor">
        <tbody>
          <tr>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.visitor.id"></th>
            <th v-bind:class="'bbrCom_bbTeam' + json.gameInfo.home.id"></th>
          </tr>
        </tbody>
      </table>
      
      <div class="bbrRst_btMemberTbl_flex">
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchOutfielderList(json.gameInfo.visitor.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bbrRst_btMemberTbl_item">
          <table class="bbrRst_btMemberTbl_pData bbrCom_mb50">
            <tbody>
              <tr class="bbrRst_btMemberTbl_playerSet">
                <th>&nbsp;</th>
                <th>選手名</th>
                <th>打</th>
                <th>打率</th>
              </tr>
              <tr v-for='(member,index) in getBenchOutfielderList(json.gameInfo.home.globalId)' v-bind:key="index">
                <td>{{ json.playerList[member.globalId].uniform }}</td>
                <td>{{ json.playerList[member.globalId].name }}</td>
                <td>{{ side[json.playerList[member.globalId].batSide] }}</td>
                <template v-if="json.playerList[member.globalId].seasonBatting">
                  <td>{{ json.playerList[member.globalId].seasonBatting.average }}</td>
                </template>
                <template v-else>
                  <td>-</td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </template>
    <!--/外野手-->
    </template>
  <!--審判-->
    <h3 class="bbrCom_ttlSc">審判</h3>
    <table class="bbrCom_bdrTable bbrRst_bdrTableSetJ">
      <tbody>
        <tr>
          <th>球審</th>
          <td>{{ json.umpireList['HP'] }}</td>
          <th>塁審(一)</th>
          <td>{{ json.umpireList['1B'] }}</td>
          <th>塁審(二)</th>
          <td>{{ json.umpireList['2B'] }}</td>
          <th>塁審(三)</th>
          <td>{{ json.umpireList['3B'] }}</td>
        </tr>
      </tbody>
    </table>
    <!--日本シリーズ時のみ追記-->
    <template v-if="json.umpireList['LF']">
    <table class="bbrCom_bdrTable bbrRst_bdrTableSetJ-addL">
      <tbody>
        <tr>
          <th>線審(左)</th>
          <td>{{ json.umpireList['LF'] }}</td>
          <th>線審(右)</th>
          <td>{{ json.umpireList['RF'] }}</td>
        </tr>
      </tbody>
    </table>
    </template>
    <!--/日本シリーズ時のみ追記-->
    <!--/審判-->
  </template>
  <!--/試合前以外-->
</div>
<!--速報ページ_メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      },
      side: {
        'Left':'左',
        'Right':'右',
        'Switch':'両',
      },
      homerunNameList: {
        '1':'ソロ',
        '2':'2ラン',
        '3':'3ラン',
        '4':'満塁',
      },
    }
  },
  mounted: async function() {
    await this.loadOtherGame(this.json.gameDate);
    this.$config.callback();
  },
  methods: {
    getTeamStandingsSplitsWidth: function (type) {
      let homeWin = this.json.teamStandingsSplits.win;
      let visitorWin = this.json.teamStandingsSplits.lose;
      let total = parseInt(homeWin) + parseInt(visitorWin) + parseInt(this.json.teamStandingsSplits.tie);
      if (type == 'home') {
        if (total == 0 && homeWin == 0) return `1% * 0) * 100`;
        return `1% * (${homeWin} / ${total}) * 100`;
      } else {
        if (total == 0 && visitorWin == 0) return `1% * 0) * 100`;
        return `1% * (${visitorWin} / ${total}) * 100`;
      }
    },
    getTeamStatsWidth: function (rank) {
      return `1% * ((12 - ${rank} + 1) / 12) * 100 * 0.7`;
    },
    getFormattedDay: function (date) {
      const weeks = ["日", "月", "火", "水", "木", "金", "土"];
      const format = new Date(date);
      let month = format.getMonth() + 1;
      let day = format.getDate();
      month = ('0' + month).slice(-2);
      day = ('0' + day).slice(-2);
      const week = weeks[format.getDay()];

      return `${month}.${day} (${week})`;
    },
    getBattingList: function () {
      const hBattingList = this.json.battingList[this.json.gameInfo.home.globalId];
      const vBattingList = this.json.battingList[this.json.gameInfo.visitor.globalId];
      let battingList = [];
      for (let i = 0; i < 9; i++) {
        battingList[i] = {home: hBattingList[i+1], visitor: vBattingList[i+1]};
      }
      return battingList;
    },
    getMoundPitcher: function (teamId) {
      let pitcher = null;
      const id = this.json.moundPitcherList[teamId].globalId;
      pitcher = this.json.playerList[id];
      return pitcher;
    },
    getPitcherList: function (teamId) {
      let pitcherNameList = '';
      const pitcherList = this.json.pitcherList[teamId];
      for (let i in pitcherList) {
        let pitcher = this.json.playerList[pitcherList[i].globalId];
        if (pitcher) {
          if (i == 0) pitcherNameList += pitcher.name;
          else pitcherNameList += '、' + pitcher.name;
        }
      }
      return pitcherNameList;
    },
    getCatcherList: function (teamId) {
      let catcherNameList = '';
      const catcherList = this.json.catcherList[teamId];
      for (let i in catcherList) {
        let catcher = this.json.playerList[catcherList[i].globalId];
        if (catcher) {
          if (i == 0) catcherNameList += catcher.name;
          else catcherNameList += '、' + catcher.name;
        }
      }
      return catcherNameList;
    },
    getHomeredList: function (teamId) {
      let homeredNameList = '';
      const homeredList = this.json.homeredList[teamId];
      for (let i in homeredList) {
        let homered = this.json.playerList[homeredList[i].globalId];
        if (homered) {
          if (i == 0) homeredNameList += `${homered.name} ${homeredList[i].count}号（${homeredList[i].inning}回${this.homerunNameList[homeredList[i].rbi]}）`;
          else homeredNameList += '、' + `${homered.name} ${homeredList[i].count}号（${homeredList[i].inning}回${this.homerunNameList[homeredList[i].rbi]}）`;
        }
      }
      return homeredNameList;
    },
    getBenchPitcherList: function (teamId) {
      return this.json.benchMemberList[teamId]['P'];
    },
    getBenchCatcherList: function (teamId) {
      return this.json.benchMemberList[teamId]['C'];
    },
    getBenchInfielderList: function (teamId) {
      return this.json.benchMemberList[teamId]['IF'];
    },
    getBenchOutfielderList: function (teamId) {
      return this.json.benchMemberList[teamId]['OF'];
    },
    getWinLosePitcher: function (pitcherInfo) {
      if (pitcherInfo == null) return null;
      let pitcher = this.json.playerList[pitcherInfo.globalId];
      let teamName;
      if (this.json.gameInfo.home.globalId == pitcherInfo.teamGlobalId) {
        teamName = this.json.gameInfo.home.nickname;
      } else if (this.json.gameInfo.visitor.globalId == pitcherInfo.teamGlobalId) {
        teamName = this.json.gameInfo.visitor.nickname;
      }
      return `[${teamName}] ${pitcher.name} （${pitcher.seasonPitching.wins}勝${pitcher.seasonPitching.loses}敗 ${pitcher.seasonPitching.saves}S）`;
    },
    getGameTypeName: function (gameType) {
      if (gameType == 0) {
        return 'オープン戦';
      } else if (gameType == 1) {
        return  '公式戦';
      } else if (gameType == 5) {
        return  'オールスター';
      } else if (gameType == 10) {
        return  '日本シリーズ';
      } else if (gameType == 11 || gameType == 12) {
        return  'CS';
      } else {
        return  '';
      }
    },
  }
}
</script>