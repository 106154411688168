<template>
<div>

<!--速報ページ_メインメニュー-->
<ul id="bbrSok_mainMenu" class="bbrCom_tabMenu02">
  <li><a v-bind:href="$config.baseUrl + '?kd_page=game&global_id=' + json.gameInfo.gameGlobalId">試合情報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId">一球速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=inning&global_id=' + json.gameInfo.gameGlobalId" class="bbrCom_current">テキスト速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=member&global_id=' + json.gameInfo.gameGlobalId">出場成績</a></li>
</ul>
<!--/速報ページ_メインメニュー-->

<!--速報ページ_メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">

  <div id="bbrSok_game_date">{{ getFormattedGameStartTime(json.gameDate, json.gameInfo.startTime) }} {{ json.gameInfo.venueName }}</div>
  
  <!--スコアボード-->
  <div class="bbrCom_scrTbl">
    <table id="bbrCom_scrTbl-scoreIn12">
        <tbody>
          <tr class="hd">
            <th class="bbrCom_scrTbl_tName">&nbsp;</th>
            <th v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              {{ inning.number }}
            </th>
            <th class="bbrCom_scrTbl_sum">計</th>
            <th class="bbrCom_scrTbl_hit">H</th>
            <th class="bbrCom_scrTbl_error">E</th>
        </tr>
        <tr class="bbrCom_scrTbl-first">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.visitor.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.visitor.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a href="" v-on:click="scrollInning" v-bind:data-scroll="'#bbrSok_i' + inning.number + 'T'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.visitor.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.visitor.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.visitor.errors }}</td>
        </tr>
        <tr class="bbrCom_scrTbl-second">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.home.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.home.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.home.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a href="" v-on:click="scrollInning" v-bind:data-scroll="'#bbrSok_i' + inning.number + 'B'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.home.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.home.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.home.errors }}</td>
        </tr>
    </tbody></table>
  </div>
  <!--/スコアボード-->
  <template v-for="(inning, index) in json.eventTextList">
    <div class="bbrSok_ac" v-if="inning.Bottom" :key="'B' + index">
      <input type="checkbox" v-bind:id="'bbrSok_i' + inning.number + 'B'" class="bbrSok_ac_chk" />
      <label v-bind:for="'bbrSok_i' + inning.number + 'B'" v-bind:class="'bbrCom_blTeam' + json.gameInfo.home.id">{{ inning.number }}回裏 {{ json.gameInfo.home.nickname }}の攻撃</label>
      <ul class="bbrSok_ac_chkCnt">
        <li class="bbrSok_cf" v-for='(event,eventIndex) in getReversedArray(inning.Bottom)' v-bind:key="eventIndex">
          <div class="bbrSok_ac_chkCnt_lft">
            <div class="bbrSok_ac_chkCnt_lft-baseMap">
              <p class="bbrSok_ac_chkCnt_lft-baseMap_base01" v-if="event.base.b1.onBase"></p>
              <p class="bbrSok_ac_chkCnt_lft-baseMap_base02" v-if="event.base.b2.onBase"></p>
              <p class="bbrSok_ac_chkCnt_lft-baseMap_base03" v-if="event.base.b3.onBase"></p>
            </div>
            <div class="bbrSok_ac_chkCnt_lft-oCount"><div v-bind:class="'bbrSok_ac_chkCnt_lft-oCount-c' + event.beforeBso.o"></div><span>OUT</span></div>
            <template v-if="event.scored">
              <p class="bbrSok_ac_chkCnt_lft-score">{{ json.gameInfo.visitor.aliasName }}{{ event.score.visitor }}-{{ event.score.home }}{{ json.gameInfo.home.aliasName }}</p>
            </template>
          </div>
          <div class="bbrSok_ac_chkCnt_rgt">
            <div>
              <template v-if="event.result && event.result == '守備位置の変更'">
                <span class="bbrSok_ac_chkCnt_icoCf"></span>
              </template>
              <template v-else-if="event.result && event.result == '選手交代'">
                <span class="bbrSok_ac_chkCnt_icoCf"></span>
              </template>
              <template v-else-if="event.result && event.result == '投手交代'">
                <span class="bbrSok_ac_chkCnt_icoCp"></span>
              </template>
              <template v-else-if="event.result && event.result == '代打'">
                <span class="bbrSok_ac_chkCnt_icoPh"></span>
              </template>
              <template v-else-if="event.result && event.result == '代走'">
                <span class="bbrSok_ac_chkCnt_icoPr"></span>
              </template>
              
              <template v-else-if="event.beforeBso.o == event.bso.o">
                <span class="bbrSok_ac_chkCnt_icoHit"></span>
              </template>
              <template v-else>
                <span class="bbrSok_ac_chkCnt_icoOut"></span>
              </template>
            </div>
            <p v-if="event.scored" class="bbrCom_setColRst-hit">{{ event.description }}</p>
            <p v-else>{{ event.description }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="bbrSok_ac" v-if="inning.Top" :key="'T' + index">
      <input type="checkbox" v-bind:id="'bbrSok_i' + inning.number + 'T'" class="bbrSok_ac_chk" />
      <label v-bind:for="'bbrSok_i' + inning.number + 'T'" v-bind:class="'bbrCom_blTeam' + json.gameInfo.visitor.id">{{ inning.number }}回表 {{ json.gameInfo.visitor.nickname }}の攻撃</label>
      <ul class="bbrSok_ac_chkCnt">
        <li class="bbrSok_cf" v-for='(event,eventIndex) in getReversedArray(inning.Top)' v-bind:key="eventIndex">
          <div class="bbrSok_ac_chkCnt_lft">
            <div class="bbrSok_ac_chkCnt_lft-baseMap">
              <p class="bbrSok_ac_chkCnt_lft-baseMap_base01" v-if="event.base.b1.onBase"></p>
              <p class="bbrSok_ac_chkCnt_lft-baseMap_base02" v-if="event.base.b2.onBase"></p>
              <p class="bbrSok_ac_chkCnt_lft-baseMap_base03" v-if="event.base.b3.onBase"></p>
            </div>
            <div class="bbrSok_ac_chkCnt_lft-oCount"><div v-bind:class="'bbrSok_ac_chkCnt_lft-oCount-c' + event.beforeBso.o"></div><span>OUT</span></div>
            <template v-if="event.scored">
              <p class="bbrSok_ac_chkCnt_lft-score">{{ json.gameInfo.visitor.aliasName }}{{ event.score.visitor }}-{{ event.score.home }}{{ json.gameInfo.home.aliasName }}</p>
            </template>
          </div>
          <div class="bbrSok_ac_chkCnt_rgt">
            <div>
              <template v-if="event.result && event.result == '守備位置の変更'">
                <span class="bbrSok_ac_chkCnt_icoCf"></span>
              </template>
              <template v-else-if="event.result && event.result == '選手交代'">
                <span class="bbrSok_ac_chkCnt_icoCf"></span>
              </template>
              <template v-else-if="event.result && event.result == '投手交代'">
                <span class="bbrSok_ac_chkCnt_icoCp"></span>
              </template>
              <template v-else-if="event.result && event.result == '代打'">
                <span class="bbrSok_ac_chkCnt_icoPh"></span>
              </template>
              <template v-else-if="event.result && event.result == '代走'">
                <span class="bbrSok_ac_chkCnt_icoPr"></span>
              </template>
              
              <template v-else-if="event.beforeBso.o == event.bso.o">
                <span class="bbrSok_ac_chkCnt_icoHit"></span>
              </template>
              <template v-else>
                <span class="bbrSok_ac_chkCnt_icoOut"></span>
              </template>
            </div>
            <p v-if="event.scored" class="bbrCom_setColRst-hit">{{ event.description }}</p>
            <p v-else>{{ event.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </template>
</div>
<!--速報ページ_メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      }
    }
  },
  mounted: async function() {
    let firstTextLabel = document.querySelector('.bbrSok_ac label');
    if (firstTextLabel) {
      firstTextLabel.click();
      if (this.json.gameInfo.gameStatusName == '試合終了') {
        this.addText('試合終了');
      }
      let visitorScore = this.json.lineScore.visitor.runs;
      if (visitorScore === undefined) {
        visitorScore = 0;
      }
      let homeScore = this.json.lineScore.home.runs;
      if (homeScore === undefined) {
        homeScore = 0;
      }
      this.addText(`【${this.json.gameInfo.visitor.aliasName} ${visitorScore}-${homeScore} ${this.json.gameInfo.home.aliasName}】`);
    }
    await this.loadOtherGame(this.json.gameDate);
    this.$config.callback();
  },
  methods: {
    getReversedArray: function (array) {
      const reversed = array.slice().reverse();
      return reversed;
    },
    scrollInning: function (e) {
      e.preventDefault();
      let id = e.currentTarget.getAttribute('data-scroll');
      let target = document.querySelector(id);
      if (target.checked == false) {
        target.click();
      }
      target.parentNode.scrollIntoView(true);
    },
    addText: function (text) {
      let firstTextList = document.querySelector('.bbrSok_ac ul');
      let endInfo = document.createElement('li');
      endInfo.setAttribute('class', 'bbrSok_cf');
      let endInfoIcon = document.createElement('div');
      endInfoIcon.setAttribute('class', 'bbrSok_ac_chkCnt_lft');
      let endInfoText = document.createElement('div');
      endInfoText.setAttribute('class', 'bbrSok_ac_chkCnt_rgt');
      endInfoText.innerText = text;
      endInfo.appendChild(endInfoIcon);
      endInfo.appendChild(endInfoText);
      let firstContent = firstTextList.querySelector('li');
      firstTextList.insertBefore(endInfo, firstContent);
    },
  }
}
</script>