<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  
  <!--チームを選ぶ-->
  <div class="bbrCom_selectMenu bbrTem_selectTeam">
  <label>
  <select id="bbrTem_selectTeamId" onchange="location.href = this.options[selectedIndex].value">
    <option>チームを選ぶ</option>
    <template v-for="(team) in json.teamList">
      <option v-bind:value="$config.baseUrl + '?kd_page=team_member&team_id=' + team.globalId" :key="team.id">{{ team.name }}</option>
    </template>
  </select>
  </label>
  </div>
  <!--/チームを選ぶ-->
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->

  <h2 class="bbrCom_ttl"><span v-bind:class="'bbrCom_blTeam' + json.team.id">{{ json.team.name }}</span></h2>
  
  <!--チーム情報ページ_メインメニュー-->
  <ul id="bbrTem_mainMenu" class="bbrCom_tabMenu02">
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + json.team.globalId">チームトップ</a></li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId" class="bbrCom_current">所属選手</a></li>
    <li>
      <a v-if="gameJson != null" v-bind:href="$config.baseUrl + '?kd_page=team_result&team_id=' + json.team.globalId + '&month=' + getMonth(json.gameDate)">日程・結果</a>
      <a v-else href="">日程・結果</a>
    </li>
    <li><a v-bind:href="$config.baseUrl + '?kd_page=team_record&team_id=' + json.team.globalId + '&vsteam_id=' + getVsTeamId(json.team.globalId )">対戦・成績</a></li>
  </ul>
  <!--/チーム情報ページ_メインメニュー-->

  <!--サブナビ-->
  <ul id="bbrTem_pListTabMenu01" class="bbrCom_tabMenu01 bbrCom_tac bbrCom_mb50">
    <li>
      <a v-if="json.positionCode == 'P'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=P'" class="bbrCom_current">投手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=P'">投手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'C'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=C'" class="bbrCom_current">捕手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=C'">捕手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'IF'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=IF'" class="bbrCom_current">内野手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=IF'">内野手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'OF'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=OF'" class="bbrCom_current">外野手</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=OF'">外野手</a>
    </li>
    <li>
      <a v-if="json.positionCode == 'M'" v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=M'" class="bbrCom_current">監督・コーチ</a>
      <a v-else v-bind:href="$config.baseUrl + '?kd_page=team_member&team_id=' + json.team.globalId + '&position_code=M'">監督・コーチ</a>
    </li>
  </ul>
  <!--サブナビ-->
  
  <!--選手一覧_投手-->
  <template v-if="json.positionCode == 'M'">
    <div class="bbrCom_mb20">
    <div class="bbrCom_wrapScrTbl bbrCom_mb10">
      <table class="bbrCom_tbl  bbrTem_tblCch bbrCom_mb20">
        <tbody>
          <tr>
            <th>NO.</th>
            <th class="bbrCom_tblTxtPName">登録名</th>
            <th class="bbrTem_tblCch-pos">肩書</th>
            <th class="bbrTem_tblCch-age">年齢</th>
          </tr>
          <template v-for="(member) in json.teamMemberList">
            <tr :key="member.globalId">
            <td>{{ member.uniform }}</td>
            <td class="bbrCom_tblTxtPName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.team.globalId + '&player_id=' + member.globalId">{{ member.name }}</a>
              <a v-else>{{ member.name }}</a>
            </td>
            <td>{{ member.position }}</td>
            <td>{{ getAge(member.birthday) }}</td>
          </tr>
          </template>
  </tbody>
      </table>
    </div>  
  </div>
  </template>
  <template v-else>
  <div class="bbrCom_mb20">
    <div class="bbrCom_wrapScrTbl bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_tblTaRBeh3Clm bbrCom_mb10" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th>No.</th>
            <th class="bbrCom_tblTxtPName">選手名</th>
            <th v-bind:class="''" v-for='(rank,index) in json.rankOrder' v-bind:key="index">
              <a v-html="formattedRankName(rank.name)"></a>
            </th>
          </tr>
          <template v-for="(member) in json.teamMemberList">
            <tr :key="member.globalId">
            <td>{{ member.uniform }}</td>
            <td class="bbrCom_tblTxtPName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.team.globalId + '&player_id=' + member.globalId">{{ member.name }}</a>
              <a v-else>{{ member.name }}</a>
            </td>
            <td v-bind:class="'bbrCom_tblCellAr'" v-for='(rank,index) in json.rankOrder' v-bind:key="index">
              <template v-if="json.teamMemberLeadersList[member.globalId][rank.code] == null">
              -
              </template>
              <template v-else>
              {{ json.teamMemberLeadersList[member.globalId][rank.code] }}
              </template>
            </td>
          </tr>
          </template>
  </tbody>
      </table>
    </div>
    <div class="bbrCom_icoScr"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
  </div>
  </template>

  <!--/選手一覧_投手-->
  
  
 </div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
    }
  },
  mounted: async function() {
    this.gameJson = await this.loadLatestGame();
    this.loadFixedMidashi();
    this.$config.callback();
  },
  methods: {
    formattedRankName: function (name) {
      let rankName = '';
      let char = name.split('');
      for (var i = 0; i < char.length; ++i) {
        if (i == 0) {
          rankName += char[i].replace('ー', '|');
        } else {
          rankName += '<br>' + char[i].replace('ー', '|');
        }
      }
      return rankName;
    },
    getAge: function (birthday) {
      const date = new Date();
      const birthDate = new Date(birthday);
      const age = date.getFullYear() - birthDate.getFullYear();
      const thisYearsBirthday = new Date(date.getFullYear(), birthDate.getMonth(), birthDate.getDate());
      return age + (thisYearsBirthday.getTime() > date.getTime() ? -1 : 0);
    },
  }
}
</script>