import Vue from 'vue'
import Latest from '../components/Latest.vue';
import Other from '../components/Other.vue';
import StandingsLite from '../components/StandingsLite.vue';
import LeadersLite from '../components/LeadersLite.vue';

export default {
  methods: {
    loadOtherGame: async function (gameDate) {
      let filename = 'gamelist_' + gameDate.replace(/-/g, '');
      let url = this.$api.baseURL + filename + '.json';
      const response = await this.$api.getAPI(url);
      let ComponentClass = Vue.extend(Other);
      let instance = new ComponentClass({
        data: function () {
          return {
            json: response
          }
        }
      });
      instance.$mount('#bbrCom_gameResult');
    },
    loadLatestGame: async function () {
      let filename = 'gamelist';
      let url = this.$api.baseURL + filename + '.json';
      const response = await this.$api.getAPI(url);
      let ComponentClass = Vue.extend(Latest);
      let instance = new ComponentClass({
        data: function () {
          return {
            json: response
          }
        }
      });
      instance.$mount('#bbrCom_gameResult');
      return response;
    },
    loadFixedMidashi: function () {
      let script = document.createElement('script');
      script.setAttribute('src', 'https://npb.sports-digican.com/pc/common/js/fixed_midashi_src.js')
      document.head.appendChild(script);
      script.onload = function() {
        window.FixedMidashi.create();
      };
    },
    loadStandingsLite: async function () {
      let filename = 'standings_lite';
      let url = this.$api.baseURL + filename + '.json';
      const response = await this.$api.getAPI(url);
      let ComponentClass = Vue.extend(StandingsLite);
      let instance = new ComponentClass({
        data: function () {
          return {
            json: response
          }
        }
      });
      instance.$mount('#kd-npb-standings');
    },
    loadLeadersLite: async function () {
      let filename = 'leaders_lite';
      let url = this.$api.baseURL + filename + '.json';
      const response = await this.$api.getAPI(url);
      let ComponentClass = Vue.extend(LeadersLite);
      let instance = new ComponentClass({
        data: function () {
          return {
            json: response
          }
        }
      });
      instance.$mount('#kd-npb-leaders');
    },
    getFormattedMonth: function (month) {
      let result = month.substr(4);
      return Number(result);
    },
    getFormattedYearMonth: function (date) {
      return this.$moment(date).format('YYYY年MM月');
    },
    getFormattedStartTime: function (startTime) {
      return startTime.slice(0, 5);
    },
    getFormattedShortDay: function (date) {
      return this.$moment(date).format('MM.DD');
    },
    getFormattedDay: function (date) {
      return this.$moment(date).format('MM.DD (ddd)');
    },
    getFormattedToday: function (date) {
      return this.$moment(date).format('YYYY.MM.DD (ddd)');
    },
    getFormattedGameStartTime: function (date, startTime) {
      return this.$moment(date + ' ' + startTime).format('YYYY.MM.DD HH:mm');
    },
    getFormattedTimeOfGame: function (timeOfGame) {
      let date = this.$moment().format('YYYY-MM-DD');
      return this.$moment(date + ' ' + timeOfGame).format('H時間mm分');
    },
    getMonth: function (date) {
      return this.$moment(date).format('YYYYMM');
    },
    getVsTeamId: function (teamId) {
      let vsTeamid = null;
      if (teamId < 7917) {
        if (teamId == 7911) {
          vsTeamid = 7912;
        } else {
          vsTeamid = 7911;
        }
      } else {
        if (teamId == 7917) {
          vsTeamid = 7918;
        } else {
          vsTeamid = 7917;
        }
      }
      return vsTeamid;
    },
    getGameLink: function (baseUrl, game) {
      let url = baseUrl;
      if (game.gameStatus == 2 || game.gameStatus == 123) {
        url += '?kd_page=pbp&global_id=' + game.gameGlobalId;
      } else {
        url += '?kd_page=game&global_id=' + game.gameGlobalId;
      }
      return url;
    },
    formattedIP: function (value) {
      if (value == null) return value;
      if (value == '0.0') return value;
      return value.replace('.0', '');
    },
  }
}
