<template>
<div>

<!--速報ページ_メインメニュー-->
<ul id="bbrSok_mainMenu" class="bbrCom_tabMenu02">
  <li><a v-bind:href="$config.baseUrl + '?kd_page=game&global_id=' + json.gameInfo.gameGlobalId">試合情報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId">一球速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=inning&global_id=' + json.gameInfo.gameGlobalId">テキスト速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=member&global_id=' + json.gameInfo.gameGlobalId" class="bbrCom_current">出場成績</a></li>
</ul>
<!--/速報ページ_メインメニュー-->

<!--速報ページ_メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">

  <div id="bbrSok_game_date">{{ getFormattedGameStartTime(json.gameDate, json.gameInfo.startTime) }} {{ json.gameInfo.venueName }}</div>
  
  <!--スコアボード-->
  <div class="bbrCom_scrTbl">
    <table id="bbrCom_scrTbl-scoreIn12">
        <tbody>
          <tr class="hd">
            <th class="bbrCom_scrTbl_tName">&nbsp;</th>
            <th v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              {{ inning.number }}
            </th>
            <th class="bbrCom_scrTbl_sum">計</th>
            <th class="bbrCom_scrTbl_hit">H</th>
            <th class="bbrCom_scrTbl_error">E</th>
        </tr>
        <tr class="bbrCom_scrTbl-first">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.visitor.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.visitor.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId + '&inning=' + inning.number + '&tob=1&flow=1'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.visitor.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.visitor.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.visitor.errors }}</td>
        </tr>
        <tr class="bbrCom_scrTbl-second">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.home.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.home.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.home.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId + '&inning=' + inning.number + '&tob=2&flow=1'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.home.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.home.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.home.errors }}</td>
        </tr>
    </tbody></table>
  </div>
  <!--/スコアボード-->
  <h2 class="bbrCom_ttl">打撃・投手成績</h2>
  <!--先行 - 打撃一覧-->
  <div class="bbrCom_mb10">
    <h3 v-bind:class="'bbrCom_ttlSc-t bbrCom_blTeam' + json.gameInfo.visitor.id">{{ json.gameInfo.visitor.nickname }}</h3>
    <div class="bbrCom_wrapScrTbl bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_mb20" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th class="bbrCom_tblTxt1st">位置</th>
            <th class="bbrCom_tblTxtPName">選手名</th>
            <th>打<br>率</th>
            <th>打<br>数</th>
            <th>得<br>点</th>
            <th>安<br>打</th>
            <th>打<br>点</th>
            <th>盗<br>塁</th>
            <th v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              {{ inning.number }}
            </th>
          </tr>
          <tr v-for='(batter,batterIndex) in json.batterList.visitor' v-bind:key="batterIndex">
            <td>{{ batter.positon }}</td>
            <td class="bbrCom_tblTxtPName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.playerList[batter.globalId].teamGlobalId + '&player_id=' + batter.globalId">{{ json.playerList[batter.globalId].name }}</a>
              <a v-else>{{ json.playerList[batter.globalId].name }}</a>
            </td>
            <td>{{ json.playerList[batter.globalId].seasonBatting.average }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.atBats }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.scored }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.hits }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.runs }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.stolenBases }}</td>
            <td v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
            <template v-if="json.batterResultList.visitor && json.batterResultList.visitor[batter.globalId]">
              <template v-if="json.batterResultList.visitor[batter.globalId][inning.number]">
                <template v-for="(inningByResult, resultIndex) in json.batterResultList.visitor[batter.globalId][inning.number]">
                  <div v-if="inningByResult.color == 'hit'" class="bbrCom_setColRst-hit" :key="resultIndex">
                    {{ inningByResult.result }}
                  </div>
                  <div v-else :key="resultIndex">
                    {{ inningByResult.result }}
                  </div>
                </template>
              </template>
              <template v-else>
                <span></span>
              </template>
            </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bbrCom_icoScr"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
  </div>
  <!--/先行 - 打撃一覧-->
  <!--先行 - 投手成績一覧-->
  <div class="bbrCom_mb50">
    <div class="bbrCom_wrapScrTblSmt bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_mb10" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th></th>
            <th class="bbrCom_tblTxtPName">選手名</th>
            <th class="bbrCom_tblTxtW1">防<br>御<br>率</th>
            <th class="bbrCom_tblTxtW1">投<br>球<br>回</th>
            <th>投<br>球<br>数</th>
            <th>打<br>者</th>
            <th>被<br>安<br>打</th>
            <th>被<br>本<br>塁<br>打</th>
            <th>奪<br>三<br>振</th>
            <th>与<br>四<br>球</th>
            <th>失<br>点</th>
            <th>自<br>責<br>点</th>
          </tr>
          <tr v-for='(pitcher,order) in json.pitcherResultList.visitor' v-bind:key="order">
            <td v-if="pitcher.winning == 'true'">勝</td>
            <td v-else-if="pitcher.losing == 'true'">負</td>
            <td v-else-if="pitcher.saving == 'true'">S</td>
            <td v-else-if="pitcher.holding == 'true'">H</td>
            <td v-else></td>
            <td class="bbrCom_tblTxtPName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getPitcher(pitcher.globalId).teamGlobalId + '&player_id=' + pitcher.globalId">{{ getPitcher(pitcher.globalId).name }}</a>
              <a v-else>{{ getPitcher(pitcher.globalId).name }}</a>
            </td>
            <td>{{ getPitcher(pitcher.globalId).seasonPitching.earnedRunAverage }}</td>
            <td>{{ formattedIP(getPitcher(pitcher.globalId).gamePitching.inningsPitched) }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.pitchCount }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.battersFaced }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.hits }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.homeRuns }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.strikeOuts }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.walks }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.runs }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.earnedRuns }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bbrCom_icoScr bbrCom_ndP bbrCom_mb10"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
  </div>
  <!--/先行 - 投手成績一覧-->

  <!--後行 - 打撃一覧-->
  <div class="bbrCom_mb20">
    <h3 v-bind:class="'bbrCom_ttlSc-t bbrCom_blTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</h3>
    <div class="bbrCom_wrapScrTbl bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_mb20" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th class="bbrCom_tblTxt1st">位置</th>
            <th class="bbrCom_tblTxtPName">選手名</th>
            <th>打<br>率</th>
            <th>打<br>数</th>
            <th>得<br>点</th>
            <th>安<br>打</th>
            <th>打<br>点</th>
            <th>盗<br>塁</th>
            <th v-for='(inning,index) in json.lineScore.home.innings' v-bind:key="index">
              {{ inning.number }}
            </th>
          </tr>
          <tr v-for='(batter,batterIndex) in json.batterList.home' v-bind:key="batterIndex">
            <td>{{ batter.positon }}</td>
            <td class="bbrCom_tblTxtPName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.playerList[batter.globalId].teamGlobalId + '&player_id=' + batter.globalId">{{ json.playerList[batter.globalId].name }}</a>
              <a v-else>{{ json.playerList[batter.globalId].name }}</a>
            </td>
            <td>{{ json.playerList[batter.globalId].seasonBatting.average }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.atBats }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.scored }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.hits }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.runs }}</td>
            <td>{{ json.playerList[batter.globalId].gameBatting.stolenBases }}</td>
            <td v-for='(inning,index) in json.lineScore.home.innings' v-bind:key="index">
            <template v-if="json.batterResultList.home && json.batterResultList.home[batter.globalId]">
              <template v-if="json.batterResultList.home[batter.globalId][inning.number]">
                <template v-for="(inningByResult, resultIndex) in json.batterResultList.home[batter.globalId][inning.number]">
                  <div v-if="inningByResult.color == 'hit'" class="bbrCom_setColRst-hit" :key="resultIndex">
                    {{ inningByResult.result }}
                  </div>
                  <div v-else :key="resultIndex">
                    {{ inningByResult.result }}
                  </div>
                </template>
              </template>
              <template v-else>
                <span></span>
              </template>
            </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bbrCom_icoScr"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
  </div>
  <!--/後行 - 打撃一覧-->
  <!--後行 - 投手成績一覧-->
  <div>
    <div class="bbrCom_wrapScrTblSmt bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_mb10" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th></th>
            <th class="bbrCom_tblTxtPName">選手名</th>
            <th class="bbrCom_tblTxtW1">防<br>御<br>率</th>
            <th class="bbrCom_tblTxtW1">投<br>球<br>回</th>
            <th>投<br>球<br>数</th>
            <th>打<br>者</th>
            <th>被<br>安<br>打</th>
            <th>被<br>本<br>塁<br>打</th>
            <th>奪<br>三<br>振</th>
            <th>与<br>四<br>球</th>
            <th>失<br>点</th>
            <th>自<br>責<br>点</th>
          </tr>
          <tr v-for='(pitcher,order) in json.pitcherResultList.home' v-bind:key="order">
            <td v-if="pitcher.winning == 'true'">勝</td>
            <td v-else-if="pitcher.losing == 'true'">負</td>
            <td v-else-if="pitcher.saving == 'true'">S</td>
            <td v-else-if="pitcher.holding == 'true'">H</td>
            <td v-else></td>
            <td class="bbrCom_tblTxtPName">
              <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getPitcher(pitcher.globalId).teamGlobalId + '&player_id=' + pitcher.globalId">{{ getPitcher(pitcher.globalId).name }}</a>
              <a v-else>{{ getPitcher(pitcher.globalId).name }}</a>
            </td>
            <td>{{ getPitcher(pitcher.globalId).seasonPitching.earnedRunAverage }}</td>
            <td>{{ formattedIP(getPitcher(pitcher.globalId).gamePitching.inningsPitched) }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.pitchCount }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.battersFaced }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.hits }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.homeRuns }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.strikeOuts }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.walks }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.runs }}</td>
            <td>{{ getPitcher(pitcher.globalId).gamePitching.earnedRuns }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bbrCom_icoScr bbrCom_ndP bbrCom_mb10"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
  </div>
  <!--/後行 - 投手成績一覧-->
</div>
<!--速報ページ_メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      }
    }
  },
  mounted: async function() {
    await this.loadOtherGame(this.json.gameDate);
    this.loadFixedMidashi();
    this.$config.callback();
  },
  methods: {
    getPitcher: function (id) {
      let pitcher = this.json.playerList[id];
      if (pitcher) {
        if (!pitcher.seasonPitching) {
          pitcher.seasonPitching = [];
          pitcher.seasonPitching.earnedRunAverage = '';
        }
        if (!pitcher.gamePitching) {
          pitcher.gamePitching = [];
          pitcher.gamePitching.earnedRunAverage = '';
        }
        return pitcher;
      } else {
        pitcher = [];
        pitcher.name = '';
        pitcher.teamGlobalId = '';
        pitcher.globalId = '';
        pitcher.throwing = '';

        pitcher.seasonPitching = [];
        pitcher.seasonPitching.earnedRunAverage = '';
        pitcher.gamePitching = [];
        pitcher.gamePitching.earnedRunAverage = '';
        
        return pitcher;
      }
    },
  }
}
</script>