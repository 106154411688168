<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  

  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  
  <h2 class="bbrCom_ttl">チーム情報</h2>
  
  <!--チーム情報-->
  <div id="bbrTem_teamLists" class="bbrCom_cf bbrCom_mb20">
    <div id="bbrTem_teamLists_left">
      <h3>セ・リーグ</h3>
      <ul>
        <li v-for='(team,index) in json.cTeamList' v-bind:key="index">
          <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId" v-bind:class="'bbrCom_blTeam' + team.id">{{ team.name }}</a>
        </li>
      </ul>
    </div>
  <div id="bbrTem_teamLists_right">
    <h3>パ・リーグ</h3>
      <ul>
        <li v-for='(team,index) in json.pTeamList' v-bind:key="index">
          <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId" v-bind:class="'bbrCom_blTeam' + team.id">{{ team.name }}</a>
        </li>
      </ul>
    </div>
  </div>
  <!--/チーム情報-->
  
  
 </div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
    }
  },
  mounted: async function() {
    this.gameJson = await this.loadLatestGame();
    this.$config.callback();
  },
  methods: {
    
  }
}
</script>