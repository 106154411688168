import axios from 'axios'

const api = {
  //baseURL: 'http://localhost:8001/digican-npb/api/kdjson/',
  baseURL: 'https://npb.sports-digican.com/api/kdjson/',
  getAPI: async function(url) {
    const res = await axios.get(url, {timeout: 5000}).catch(error => {
      return error
    });
    if (res.data) {
      return res.data;
    } else {
      return null;
    }
  }
}

export default api
