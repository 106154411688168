<template>
  
  <div id="bbrCom_gameResult">
    <h4>他球場の試合</h4>
    <ul>
      <li v-for='(game,index) in json.gameList' v-bind:key="index">
        <!--試合前・開始前遅延-->
          <a v-if="game.gameStatus == 1 || game.gameStatus == 23" v-bind:href="$config.baseUrl + '?kd_page=game&global_id=' + game.gameGlobalId">
            <dl>
              <dt>{{ game.gameStatusName }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
              <dd>
                <div>{{ game.visitor.nickname }}
                  <span v-if="game.visitor.startingPitcher != null">先発：{{ game.visitor.startingPitcher }}</span>
                  <span v-else>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
              <dd>
                <div>{{ game.home.nickname }}
                  <span v-if="game.home.startingPitcher != null">先発：{{ game.home.startingPitcher }}</span>
                  <span v-else>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
            </dl>
          </a>
          <!--中止-->
          <a v-else-if="game.gameStatus == 5" v-bind:data="'?kd_page=pbp&global_id=' + game.gameGlobalId">
            <dl>
              <dt>{{ game.gameStatusName }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
              <dd>
                <div>{{ game.visitor.nickname }}
                  <span>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
              <dd>
                <div>{{ game.home.nickname }}
                  <span>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
            </dl>
          </a>
        <!--試合-->
        <a v-else v-bind:href="getGameLink($config.baseUrl, game)">
          <dl>
            <dt v-if="game.gameStatus == 2">{{ game.inning }}回{{ tob[game.tob] }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
            <dt v-else>{{ game.gameStatusName }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
            <dd>
              <div>{{ game.visitor.nickname }}
                <template v-if="game.gameStatusName == '試合終了'">
                  <template v-if="game.visitor.winLose == 'true'">
                  <span>勝：{{ game.visitor.pitcher }}</span>
                  <span v-if="game.savePitcher != null">S：{{ game.savePitcher }}</span>
                  </template>
                  <template v-else-if="game.visitor.winLose == 'false'">
                  <span>負：{{ game.visitor.pitcher }}</span>
                  </template>
                  <template v-else>
                  <span>&nbsp;</span>
                  </template>
                </template>
                <template v-else>
                  <span v-if="game.visitor.pitcher == null">&nbsp;</span>
                  <span v-else>{{ game.visitor.pitcher }}</span>
                </template>
              </div>
              <div>{{ game.visitor.score }}</div>
            </dd>
            <dd>
              <div>{{ game.home.nickname }}
                <template v-if="game.gameStatusName == '試合終了'">
                  <template v-if="game.home.winLose == 'true'">
                  <span>勝：{{ game.home.pitcher }}</span>
                  <span v-if="game.savePitcher != null">S：{{ game.savePitcher }}</span>
                  </template>
                  <template v-else-if="game.home.winLose == 'false'">
                  <span>負：{{ game.home.pitcher }}</span>
                  </template>
                  <template v-else>
                  <span>&nbsp;</span>
                  </template>
                </template>
                <template v-else>
                  <span v-if="game.home.pitcher == null">&nbsp;</span>
                  <span v-else>{{ game.home.pitcher }}</span>
                </template>
              </div>
              <div>{{ game.home.score }}</div>
            </dd>
          </dl>
        </a>
        <!--試合-->
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  data: function () {
    return {
      json: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      }
    }
  },
  mounted: function() {
  },
  methods: {
    getFormattedStartTime: function (startTime) {
      return startTime.slice(0, 5);
    },
    getGameLink: function (baseUrl, game) {
      let url = baseUrl;
      if (game.gameStatus == 2 || game.gameStatus == 123) {
        url += '?kd_page=pbp&global_id=' + game.gameGlobalId;
      } else {
        url += '?kd_page=game&global_id=' + game.gameGlobalId;
      }
      return url;
    },
  }
}
</script>