<script>
import { Pie, mixins } from 'vue-chartjs'

export default {
  extends: Pie,
  mixins: [mixins.reactiveData],
  props: {
    propData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        animation: false,
        legend: {display: false},
        tooltips:{enabled:false}
      }
    }
  },
  mounted () {
    this.render()
  },
  watch: {
    propData () {
      this.render()
    }
  },
  methods: {
    render () {
      this.chartData = this.propData;
    }
  }
}
</script>