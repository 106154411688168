<template>
<div>

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  <template v-if="json.leagueCode == 1">
    <template v-if="json.leadersType == 'batter'">
    <h2 class="bbrCom_ttl">セ・リーグ打撃成績</h2>
    </template>
    <template v-else-if="json.leadersType == 'pitcher'">
    <h2 class="bbrCom_ttl">セ・リーグ投手成績</h2>
    </template>
  </template>
  <template v-else-if="json.leagueCode == 2">
    <template v-if="json.leadersType == 'batter'">
    <h2 class="bbrCom_ttl">パ・リーグ打撃</h2>
    </template>
    <template v-else-if="json.leadersType == 'pitcher'">
    <h2 class="bbrCom_ttl">パ・リーグ投手成績</h2>
    </template>
  </template>

  <ul id="bbrRec_pslTabMenu01" class="bbrCom_tabMenu01 bbrCom_tac bbrCom_mb40">
    <li>
      <a v-bind:href="$config.baseUrl + '?kd_page=leaders_batter&league_code=1'">セ・リーグ打撃</a>
    </li>
    <li>
      <a v-bind:href="$config.baseUrl + '?kd_page=leaders_batter&league_code=2'">パ・リーグ打撃</a>
    </li>
    <li>
      <a v-bind:href="$config.baseUrl + '?kd_page=leaders_pitcher&league_code=1'">セ・リーグ投手</a>
    </li>
    <li>
      <a v-bind:href="$config.baseUrl + '?kd_page=leaders_pitcher&league_code=2'">パ・リーグ投手</a>
    </li>
  </ul>
  
   <!--セ・リーグ打撃成績-->
  <h3 class="bbrCom_ttlSc-ss">
    {{ json.updateTime }}更新 
    <template v-if="getRegulation() && json.leadersType == 'batter'">規定打席以上</template>
    <template v-if="getRegulation() && json.leadersType == 'pitcher'">規定投球回数以上</template>
  </h3>
  <div class="bbrCom_wrapScrTbl bbrCom_mb10">
    <table class="bbrCom_tbl bbrCom_tblThMin bbrCom_tblTaRBeh3Clm bbrCom_mb10" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
      <tbody>
        <tr>
          <th>順位</th>
          <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
          <template v-for="(rank, index) in json.rankOrder">
          <th v-if="rank.code != 'SBA'" v-bind:class="'bbrCom_tblCellAr'" :key="index">
              <a v-bind:href="$config.baseUrl + '?kd_page=leaders_' + json.leadersType +'&league_code=' + json.leagueCode +'&rank_code=' + rank.code" v-html="formattedRankName(rank.name)"></a>
          </th>
          <th v-else :key="index">
              <span v-html="formattedRankName(rank.name)"></span>
          </th>
          </template>
        </tr>
        <template v-if="Object.keys(json.leadersList).length">
        <template v-for="(info, index) in json.leadersList">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <template v-for="(rank, index) in json.rankOrder">
          <td v-if="rank.code == json.rankCode" class="bbrCom_tblCellSort" :key="index">
              <template v-if="rank.code == 'IP'">
                {{ formattedIP(info.leaders[rank.code]) }}
              </template>
              <template v-else>
                {{ info.leaders[rank.code] }}
              </template>
          </td>
          <td v-else :key="index">
              <template v-if="rank.code == 'IP'">
                {{ formattedIP(info.leaders[rank.code]) }}
              </template>
              <template v-else>
                {{ info.leaders[rank.code] }}
              </template>
          </td>
          </template>
        </tr>
        </template>
        </template>

        <template v-else>
          <tr>
            <td v-bind:colspan="json.rankOrder.length + 2">データがまだありません</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
  <div class="bbrCom_icoScr"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div> 
  <!--/セ・リーグ打撃成績-->
</div>
<!--/メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      }
    }
  },
  mounted: async function() {
    await this.loadLatestGame();
    this.loadFixedMidashi();
    this.$config.callback();
  },
  methods: {
    formattedRankName: function (name) {
      let rankName = '';
      let char = name.split('');
      for (var i = 0; i < char.length; ++i) {
        if (i == 0) {
          rankName += char[i].replace('ー', '|');
        } else {
          rankName += '<br>' + char[i].replace('ー', '|');
        }
      }
      return rankName;
    },
    getRegulation: function () {
      const rankOrder = this.json.rankOrder;
      for(let i in rankOrder){
        if (rankOrder[i].code == this.json.rankCode) {
          if (rankOrder[i].regulation == 'true') {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    },
  }
}
</script>