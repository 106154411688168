<template>
<div>
  <div class="bbrCom_setStdCnt bbrCom_setColFill">
    <!--最新試合-->
    <div id="bbrCom_gameResult"></div>
    <!--最新試合-->
    
    <!--日程・結果-->
    <h3 class="bbrCom_ttlSc-l">日程・結果</h3>
    <ul class="bbrCom_tabMenu01 bbrCom_mb10">
      <li v-for='(month, index) in json.monthList' v-bind:key="index">
        <a v-if="json.gameType == 1 && month== json.month" v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=1&month=' + month" class="bbrCom_current">{{ getFormattedMonth(month) }}月</a>
        <a v-else v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=1&month=' + month">{{ getFormattedMonth(month) }}月</a>
      </li>
    </ul>
    <ul class="bbrCom_tabMenu01 bbrCom_mb40">
      <li>
        <a v-if="json.gameType == 0" v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=0'" class="bbrCom_current">オープン戦</a>
        <a v-else v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=0'">オープン戦</a>
      </li>
      <li v-if="json.isCSLink">
        <a v-if="json.gameType == 11" v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=11'" class="bbrCom_current">クライマックスシリーズ</a>
        <a v-else v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=11'">クライマックスシリーズ</a>
      </li>
      <li v-if="json.isJSLink">
        <a v-if="json.gameType == 10" v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=10'" class="bbrCom_current">日本シリーズ</a>
        <a v-else v-bind:href="$config.baseUrl + '?kd_page=schedule&game_type=10'">日本シリーズ</a>
      </li>
    </ul>

    <ul id="bbrRst_topRstWeek" class="mb15 bbrCom_cf bbrCom_mb10">
      <li><p class="bbrCom_btn01"><a v-on:click="prevWeek" href="" class="bbrCom_btn-ar1L">前の週</a></p></li>
      <li><p class="bbrCom_btn01"><a v-on:click="nextWeek" href="" class="bbrCom_btn-ar1R">次の週</a></p></li>
      <li>{{ getWeekStartEnd() }}</li>
    </ul>

    <table class="bbrCom_tbl bbrCom_tbl-nonth bbrCom_tblResult bbrCom_mb50 ">
      <tbody>
        <template v-for="(gameList) in getGameDayList()">
        <tr v-for='(game,gameIndex) in gameList' v-bind:key="game.gameGlobalId">
          <template v-if="gameIndex == 0">
          <td>{{ getFormattedDay(game.gameDate) }}<br>{{ getFormattedStartTime(game.startTime) }}</td>
          </template>
          <template v-else>
          <td><br>{{ getFormattedStartTime(game.startTime) }}</td>
          </template>
          <td>{{ game.venueNickName }}</td>
          <td>
            <template v-if="game.gameStatusName == '試合前' || game.gameStatusName == '中止'">
            <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <p>{{ game.gameStatusName }}</p>
              </li>
              <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
            </template>
            <template v-else-if="game.gameStatusName == 'ノーゲーム'">
            <a v-bind:href="getGameLink($config.baseUrl, game)">
              <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <p>{{ game.gameStatusName }}</p>
              </li>
               <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
            </a>
            </template>
            <template v-else>
            <a v-bind:href="getGameLink($config.baseUrl, game)">
              <ul>
              <li>
                <span class="bbrCom_ndS">{{ game.home.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.home.aliasName }}</span>
              </li>
              <li>
                <span>{{ game.home.score }}</span>
                <span>-</span>
                <span>{{ game.visitor.score }}</span>
              </li>
               <li>
                <span class="bbrCom_ndS">{{ game.visitor.nickname }}</span>
                <span class="bbrCom_ndP">{{ game.visitor.aliasName }}</span>
              </li>
            </ul>
            </a>
            </template>
          </td>
          <td>
            <dl>
              <template v-if="game.gameStatusName == '試合終了'">
                <template v-if="game.home.winLose == 'Win' || game.home.winLose == 'true' || game.visitor.winLose == 'Win' || game.visitor.winLose == 'true'">
                  <dt>勝</dt>
                  <template v-if="(game.home.winLose == 'Win' || game.home.winLose == 'true') && game.home.pitcher != null">
                    <dd>{{ game.home.pitcher }}</dd>
                  </template>
                  <template v-else-if="(game.visitor.winLose == 'Win' || game.visitor.winLose == 'true') && game.visitor.pitcher != null">
                    <dd>{{ game.visitor.pitcher }}</dd>
                  </template>
                  <template v-else>
                    <dd>&nbsp;</dd>
                  </template>
                  <template v-if="game.savePitcher != null">
                    <dt>S</dt>
                    <dd>{{ game.savePitcher }}</dd>
                  </template>
                </template>
                <template v-else>
                <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else-if="game.gameStatusName == '試合前'">
                <template v-if="game.visitor.startingPitcher == null">
                <dt>&nbsp;</dt>
                </template>
                <template v-else>
                <dt>予</dt>
                </template>
                <dd v-if="game.visitor.startingPitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.visitor.startingPitcher }}</dd>
              </template>
              <template v-else-if="game.gameStatusName == '試合中'">
                <dt>&nbsp;</dt>
                <dd v-if="game.visitor.pitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.visitor.pitcher }}</dd>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dd>&nbsp;</dd>
              </template>

              <template v-if="game.gameStatusName == '試合終了'">
                <template v-if="game.home.winLose == 'Loss' || game.home.winLose == 'false' || game.visitor.winLose == 'Loss' || game.visitor.winLose == 'false'">
                  <dt>負</dt>
                  <template v-if="(game.home.winLose == 'Loss' || game.home.winLose == 'false') && game.home.pitcher != null">
                    <dd>{{ game.home.pitcher }}</dd>
                  </template>
                  <template v-else-if="(game.visitor.winLose == 'Loss' || game.visitor.winLose == 'false') && game.visitor.pitcher != null">
                    <dd>{{ game.visitor.pitcher }}</dd>
                  </template>
                  <template v-else>
                    <dd>&nbsp;</dd>
                  </template>
                </template>
                <template v-else>
                <dd>&nbsp;</dd>
                </template>
              </template>
              <template v-else-if="game.gameStatusName == '試合前'">
                <template v-if="game.home.startingPitcher == null">
                <dt>&nbsp;</dt>
                </template>
                <template v-else>
                <dt>予</dt>
                </template>
                <dd v-if="game.home.startingPitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.home.startingPitcher }}</dd>
              </template>
              <template v-else-if="game.gameStatusName == '試合中'">
                <dt>&nbsp;</dt>
                <dd v-if="game.home.pitcher == null">&nbsp;</dd>
                <dd v-else>{{ game.home.pitcher }}</dd>
              </template>
              <template v-else>
                <dt>&nbsp;</dt>
                <dd>&nbsp;</dd>
              </template>
            </dl>
          </td>
        </tr>
        </template>
      </tbody>
    </table>
    <!--/日程・結果-->
  </div>
  <Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
      gameJson: null,
      weekIndex: 0,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      }
    }
  },
  mounted: async function() {
    this.gameJson = await this.loadLatestGame();
    let params = new URLSearchParams(document.location.search.substring(1));
    let week = params.get('week');
    if (week) {
      this.setWeekIndex(week);
    }
    let month = params.get('month');
    if (!month) {
      this.adjustWeekIndex();
    }
    this.$config.callback();
  },
  methods: {
    setWeekIndex: function (weekNumber) {
      const len = this.json.weekNumberList.length;
      for (let i = 0; i < len; i++){
        if(weekNumber == this.json.weekNumberList[i]){
          this.weekIndex = i;
          break;
        }
      }
    },
    adjustWeekIndex: function () {
      const gameJson = this.gameJson;
      const len = this.json.weekNumberList.length;
      let weekIndex = this.weekIndex;
      for (let i = 0; i < len; i++){
        let week = this.json.weekNumberList[i];
        let schedule = this.json.schedule[week];
        Object.keys(schedule).forEach(function (key) {
          if (key != 'startOfWeek' && key != 'endOfWeek') {
            if (gameJson) {
              if (key == gameJson.gameDate.replace(/-/g, '')) {
                 weekIndex = i;
              }
            }
          }
        });
      }
      this.weekIndex = weekIndex;
    },
    getWeekNumber: function () {
      if (this.json.weekNumberList[this.weekIndex]) {
        return this.json.weekNumberList[this.weekIndex];
      } else {
        return null;
      }
    },
    getWeek: function () {
      const num = this.getWeekNumber();
      if (num != null) {
        return this.json.schedule[num];
      } else {
        return null;
      }
    },
    getWeekStartEnd: function () {
      const week = this.getWeek();
      if (week != null) {
        return this.getFormattedDay(week.startOfWeek) + ' ～' + this.getFormattedDay(week.endOfWeek);
      } else {
        return null;
      }
    },
    getGameDayList: function () {
      const week = this.getWeek();
      let gameDayList = [];
      if (week != null) {
        const gameJson = this.gameJson;
        Object.keys(week).forEach(function (key) {
          if (key != 'startOfWeek' && key != 'endOfWeek') {
            if (gameJson) {
              if (key == gameJson.gameDate.replace(/-/g, '')) {
                if (week[key].length) {
                  gameDayList.push(gameJson.gameList);
                }
              } else {
                gameDayList.push(week[key]);
              }
            } else {
              gameDayList.push(week[key]);
            }
          }
        });
      }
      return gameDayList;
    },
    prevWeek: function (e) {
      e.preventDefault();
      this.weekIndex--;
      if (this.weekIndex < 0) {
        this.weekIndex = 0;
        const prevMonth = this.json.prevMonth;
        if (prevMonth) {
          const nowWeekNumber = this.json.weekNumberList[this.weekIndex];
          const len = prevMonth.weekNumberList.length;
          let targetWeekNumber = nowWeekNumber;
          for (let i = len-1; i >= 0 ; i--) {
            if(nowWeekNumber > prevMonth.weekNumberList[i]){
              targetWeekNumber = prevMonth.weekNumberList[i];
              break;
            }
          }
          location.href = this.$config.baseUrl + '?kd_page=schedule&game_type=1&month=' + prevMonth.month + '&week=' + targetWeekNumber;
        }
      }
    },
    nextWeek: function (e) {
      e.preventDefault();
      this.weekIndex++;
      if (this.weekIndex > this.json.weekNumberList.length-1) {
        this.weekIndex = this.json.weekNumberList.length-1;
        const nextMonth = this.json.nextMonth;
        if (nextMonth) {
          const nowWeekNumber = this.json.weekNumberList[this.weekIndex];
          const len = nextMonth.weekNumberList.length;
          let targetWeekNumber = nowWeekNumber;
          for (let i = 0; i < len-1; i++) {
            if(nowWeekNumber < nextMonth.weekNumberList[i]){
              targetWeekNumber = nextMonth.weekNumberList[i];
              break;
            }
          }
          location.href = this.$config.baseUrl + '?kd_page=schedule&game_type=1&month=' + nextMonth.month + '&week=' + targetWeekNumber;
        }
      }
    },
  }
}
</script>