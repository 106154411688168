import { render, staticRenderFns } from "./LeadersLite.vue?vue&type=template&id=3fe1e15e"
import script from "./LeadersLite.vue?vue&type=script&lang=js"
export * from "./LeadersLite.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports