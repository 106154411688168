import Vue from 'vue'
import App from './App.vue'
import api from './api'
import moment from 'moment';

const KDNPB = {
  baseUrl: '',
  defaultPage: '',
  hideMenu: false,
  disabledPlayerLink: false,
  defaultAutoReload: true,
  autoReloadTime: 30,
  callback: function () {
  },
  errorCallback: function () {
    let element = document.querySelector('#kd-npb-contents');
    element.innerHTML = 'データが見つかりません';
  }
}

if ('KDNPBOptions' in window) {
  if ('baseUrl' in window.KDNPBOptions) {
    KDNPB.baseUrl = window.KDNPBOptions.baseUrl;
  }
  if ('defaultPage' in window.KDNPBOptions) {
    KDNPB.defaultPage = window.KDNPBOptions.defaultPage;
  }
  if ('hideMenu' in window.KDNPBOptions) {
    KDNPB.hideMenu = window.KDNPBOptions.hideMenu;
  }
  if ('disabledPlayerLink' in window.KDNPBOptions) {
    KDNPB.disabledPlayerLink = window.KDNPBOptions.disabledPlayerLink;
  }
  if ('defaultAutoReload' in window.KDNPBOptions) {
    KDNPB.defaultAutoReload = window.KDNPBOptions.defaultAutoReload;
  }
  if ('autoReloadTime' in window.KDNPBOptions) {
    if (window.KDNPBOptions.autoReloadTime < 15) {
      KDNPB.autoReloadTime = 15;
    } else {
      KDNPB.autoReloadTime = window.KDNPBOptions.autoReloadTime;
    }
  }
  if ('callback' in window.KDNPBOptions) {
    KDNPB.callback = window.KDNPBOptions.callback;
  }
  if ('errorCallback' in window.KDNPBOptions) {
    KDNPB.errorCallback = window.KDNPBOptions.errorCallback;
  }
}

Vue.prototype.$config = KDNPB;
Vue.prototype.$api = api;
moment.locale('ja');
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#kd-npb');
