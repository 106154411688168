<template>
<div>

<!--順位表ページ_メインメニュー-->
<ul id="bbrRnk_mainMenu" class="bbrCom_tabMenu02">
  <li>
    <a v-bind:href="$config.baseUrl + '?kd_page=standings'">順位表トップ</a></li>
  <li>
    <a v-if="json.leagueCode == '1'" v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=1'" class="bbrCom_current">セ・リーグ</a>
    <a v-else v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=1'">セ・リーグ</a>
  </li>
  <li>
    <a v-if="json.leagueCode == '2'" v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=2'" class="bbrCom_current">パ・リーグ</a>
    <a v-else v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=2'">パ・リーグ</a>
  </li>
  <template v-if="json.isInterLink">
  <li>
    <a v-if="json.leagueCode == '0'" v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=0'" class="bbrCom_current">交流戦</a>
    <a v-else v-bind:href="$config.baseUrl + '?kd_page=standings&league_code=0'">交流戦</a>
  </li>
  </template>
</ul>
<!--/順位表ページ_メインメニュー-->

<!--順位表TOP_メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  <template v-if="json.leagueCode == '1'">
    <h2 class="bbrCom_ttl">セ・リーグ</h2>
  </template>
  <template v-else-if="json.leagueCode == '2'">
    <h2 class="bbrCom_ttl">パ・リーグ</h2>
  </template>
  <template v-else-if="json.leagueCode == '0'">
    <h2 class="bbrCom_ttl">交流戦</h2>
  </template>
  <template v-if="json.updateTime">
  <hr>
  <h3 class="bbrCom_ttlSc-ss bbrCom_txtDetail">{{ json.updateTime }}更新</h3>
  </template>
  
  <!--順位表詳細-->
  <h3 class="bbrCom_ttlSc">順位表詳細</h3>
  <div class="bbrCom_mb50">
    <div class="bbrCom_wrapScrTbl bbrCom_mb10">
      <table class="bbrCom_tbl bbrCom_tblThMin bbrRnk_det-dtlTbl bbrCom_mb10" data-fixedhead="cols:2; rows:0; div-auto-size: none;">
        <tbody>
          <tr>
            <th>順位</th>
            <th class="bbrCom_tblTxtTeam">チーム</th>
            <th>試<br>合<br>数</th>
            <th>勝<br>利</th>
            <th>敗<br>北</th>
            <th>引<br>分</th>
            <th>勝<br>率</th>
            <th>勝<br>差</th>
            <template v-if="json.leagueCode != '0'">
            <th>C<br>S</th>
            </template>
            <th>防<br>御<br>率</th>
            <th>得<br>点</th>
            <th>失<br>点</th>
            <th>本<br>塁<br>打</th>
            <th>打<br>率</th>
            <th>残<br>試<br>合</th>
          </tr>
          <template v-if="Object.keys(json.teamStandingsList).length">
          <tr v-for='(standing,index) in json.teamStandingsList' v-bind:key="index">
            <td>{{ standing.rank }}</td>
            <td class="bbrCom_tblTxtTeam"> <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + standing.globalId"><span v-bind:class="'bbrCom_blTeam' + standing.id">{{ standing.nickname }}</span></a></td>
            <td>{{ Number(standing.win) + Number(standing.lose) + Number(standing.tie) }}</td>
            <td>{{ standing.win }}</td>
            <td>{{ standing.lose }}</td>
            <td>{{ standing.tie }}</td>
            <td>{{ standing.winRate }}</td>
            <template v-if="standing.magicNumber != null">
              <td v-if="standing.magicNumber == 0">優勝</td>
              <td v-else>M{{ standing.magicNumber }}</td>
            </template>
            <template v-else>
              <td>{{ standing.gamesBack }}</td>
            </template>
            <template v-if="json.leagueCode != '0'">
              <template v-if="standing.clinchNumber == '☆'">
              <td>確定</td>
              </template>
              <template v-else-if="standing.clinchNumber == '△'">
              <td></td>
              </template>
              <template v-else-if="standing.clinchNumber == '×'">
              <td>消滅</td>
              </template>
              <template v-else>
              <td></td>
              </template>
            </template>
            <td>{{ standing.earnedRunAverage }}</td>
            <td>{{ standing.runsScored }}</td>
            <td>{{ standing.runsAllowed }}</td>
            <td>{{ standing.homeRuns }}</td>
            <td>{{ standing.battingAverage }}</td>
            <td>{{ standing.remainingGames }}</td>
          </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="15">データ更新までお待ちください。</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <template v-if="Object.keys(json.teamStandingsList).length">
    <div class="bbrCom_icoScr"><span class="icon-bbrIco_finger"></span>表は左右にスクロールできます</div>  
    </template>
  </div>
  <!--/順位表詳細-->

  <!--対戦成績-->
  <template v-if="Object.keys(json.teamStandingsList).length">
  <h3 class="bbrCom_ttlSc">対戦成績</h3>
  <template v-if="json.leagueCode == '0'">
    <h3 class="bbrCom_ttlSc-s">-セ・リーグ</h3>
    <div class="bbrCom_wrapScrTblSmt bbrCom_mb10">
    <table class="bbrCom_tbl bbrRnk_det-vsTbl  bbrCom_mb10" data-fixedhead="cols:1; rows:0; div-auto-size: none;">
    <tbody>
      <tr>
        <th class="bbrCom_tblTxtTeam">&nbsp;</th>
        <th v-for='(team) in getFilteredTeamInfo(2)' v-bind:key="team.globalId">
          <span v-bind:class="'bbrCom_blTeam' + team.id">{{ team.aliasName }}</span>
        </th>
      </tr>
      <template v-for="(team) in getFilteredTeamInfo(1)">
        <tr :key="'T' + team.globalId">
          <td class="bbrCom_tblTxtTeam">
            <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId">
              <span v-bind:class="'bbrCom_blTeam' + team.id">
                <span class="bbrCom_ndS">{{ team.nickname }}</span>
                <span class="bbrCom_ndP">{{ team.aliasName }}</span>
              </span>
            </a>
          </td>
          <template v-for="(split) in getFilteredTeamInfo(2)">
            <template v-if="team.globalId == split.globalId">
              <td :key="'T' + split.globalId">-</td>
            </template>
            <template v-else>
              <td :key="'T' + split.globalId">
                {{ json.teamStandingsSplitsList[1][team.globalId][split.globalId].win }}-{{ json.teamStandingsSplitsList[1][team.globalId][split.globalId].lose }}({{ json.teamStandingsSplitsList[1][team.globalId][split.globalId].tie }})
              </td >
            </template>
          </template>
        </tr>
      </template>
    </tbody>
    </table>
    </div>
    <h3 class="bbrCom_ttlSc-s bbrCom_mt50">-パ・リーグ</h3>
    <div class="bbrCom_wrapScrTblSmt bbrCom_mb10">
    <table class="bbrCom_tbl bbrRnk_det-vsTbl  bbrCom_mb10" data-fixedhead="cols:1; rows:0; div-auto-size: none;">
    <tbody>
      <tr>
        <th class="bbrCom_tblTxtTeam">&nbsp;</th>
        <th v-for='(team) in getFilteredTeamInfo(1)' v-bind:key="team.globalId">
          <span v-bind:class="'bbrCom_blTeam' + team.id">{{ team.aliasName }}</span>
        </th>
      </tr>
      <template v-for="(team) in getFilteredTeamInfo(2)">
        <tr :key="'T' + team.globalId">
          <td class="bbrCom_tblTxtTeam">
            <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId">
              <span v-bind:class="'bbrCom_blTeam' + team.id">
                <span class="bbrCom_ndS">{{ team.nickname }}</span>
                <span class="bbrCom_ndP">{{ team.aliasName }}</span>
              </span>
            </a>
          </td>
          <template v-for="(split) in getFilteredTeamInfo(1)">
            <template v-if="team.globalId == split.globalId">
              <td :key="'T' + split.globalId">-</td>
            </template>
            <template v-else>
              <td :key="'T' + split.globalId">
                {{ json.teamStandingsSplitsList[2][team.globalId][split.globalId].win }}-{{ json.teamStandingsSplitsList[2][team.globalId][split.globalId].lose }}({{ json.teamStandingsSplitsList[2][team.globalId][split.globalId].tie }})
              </td >
            </template>
          </template>
        </tr>
      </template>
    </tbody>
    </table>
    </div>
  </template>
  <template v-else>
  <div class="bbrCom_wrapScrTblSmt bbrCom_mb10">
    <table class="bbrCom_tbl bbrRnk_det-vsTbl  bbrCom_mb10" data-fixedhead="cols:1; rows:0; div-auto-size: none;">
    <tbody>
      <tr>
        <th class="bbrCom_tblTxtTeam">&nbsp;</th>
        <th v-for='(team) in json.teamStandingsList' v-bind:key="team.globalId">
          <span v-bind:class="'bbrCom_blTeam' + team.id">{{ team.aliasName }}</span>
        </th>
      </tr>
      <template v-for="(team) in json.teamStandingsList">
        <tr :key="'T' + team.globalId">
          <td class="bbrCom_tblTxtTeam">
            <a v-bind:href="$config.baseUrl + '?kd_page=team&team_id=' + team.globalId">
              <span v-bind:class="'bbrCom_blTeam' + team.id">
                <span class="bbrCom_ndS">{{ team.nickname }}</span>
                <span class="bbrCom_ndP">{{ team.aliasName }}</span>
              </span>
            </a>
          </td>
          <template v-for="(split) in json.teamStandingsList">
            <template v-if="team.globalId == split.globalId">
              <td :key="'T' + split.globalId">-</td>
            </template>
            <template v-else>
              <td :key="'T' + split.globalId">
                {{ json.teamStandingsSplitsList[team.globalId][split.globalId].win }}-{{ json.teamStandingsSplitsList[team.globalId][split.globalId].lose }}({{ json.teamStandingsSplitsList[team.globalId][split.globalId].tie }})
              </td >
            </template>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
  </div>
  </template>
  </template>
  <!--/対戦成績-->

</div>
<!--/順位表TOP_メインコンテンツ-->


<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';

export default {
  mixins: [utilsMixin],
  components: {
    Copyright
  },
  data: function () {
    return {
      json: null,
    }
  },
  mounted: async function() {
    await this.loadLatestGame();
    this.loadFixedMidashi();
    this.$config.callback();
  },
  methods: {
    getTeamInfo: function (teamId) {
      let teamStandingsList = this.json.teamStandingsList;
      for(let i in teamStandingsList){
        if (teamStandingsList[i].globalId == teamId){
          return teamStandingsList[i];
        }
      }
      return;
    },
    getFilteredTeamInfo: function (leagueCode) {
      let teamInfoList = [];
      let teamIdList = [];
      if (leagueCode == 1) {
        teamIdList = [7911, 7912, 7913, 7914, 7915, 7916,];
      } else if (leagueCode == 2) {
        teamIdList = [7917, 7918, 7919, 7920, 7921, 7922,];
      }
      let teamStandingsList = this.json.teamStandingsList;
      for(let i in teamStandingsList){
        if (teamIdList.includes(teamStandingsList[i].globalId)){
          teamInfoList.push(teamStandingsList[i]);
        }
      }
      return teamInfoList;
    },
  }
}
</script>