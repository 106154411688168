<template>
  <div id="bbr">
    <div id="bbrCom_wrap">
      <ul v-if="$config.hideMenu != true" id="bbrCom_menu-global">
        <li><a v-bind:href="$config.baseUrl + '?kd_page=top'">スコアトップ</a></li>
        <li><a v-bind:href="$config.baseUrl + '?kd_page=schedule'">日程</a></li>
        <li><a v-bind:href="$config.baseUrl + '?kd_page=standings'">順位表</a></li>
        <li><a v-bind:href="$config.baseUrl + '?kd_page=leaders'">個人成績</a></li>
        <li><a v-bind:href="$config.baseUrl + '?kd_page=team_list'">チーム情報</a></li>
      </ul>
      <div id="kd-npb-contents"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Top from './components/Top.vue';
import Game from './components/Game.vue';
import Pbp from './components/Pbp.vue';
import Inning from './components/Inning.vue';
import Member from './components/Member.vue';
import Standings from './components/Standings.vue';
import StandingsLite from './components/StandingsLite.vue';
import StandingsDetail from './components/StandingsDetail.vue';
import Schedule from './components/Schedule.vue';
import Leaders from './components/Leaders.vue';
import LeadersLite from './components/LeadersLite.vue';
import LeadersDetail from './components/LeadersDetail.vue';
import Team from './components/Team.vue';
import TeamList from './components/TeamList.vue';
import TeamResult from './components/TeamResult.vue';
import TeamRecord from './components/TeamRecord.vue';
import TeamMember from './components/TeamMember.vue';
import Player from './components/Player.vue';

export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      id: null,
      json: null,
    }
  },
  mounted: function() {
    try {
      let filename;
      let params = new URLSearchParams(document.location.search.substring(1));
      let page = params.get('kd_page');
      let globalId = params.get('global_id');
      let inning = params.get('inning');
      let tob = params.get('tob');
      let teamId = params.get('team_id');
      let vsTeamId = params.get('vsteam_id');
      let leagueCode = params.get('league_code');
      let gametype = params.get('game_type');
      let month = params.get('month');
      let rankCode = params.get('rank_code');
      let positionCode = params.get('position_code');
      let playerId = params.get('player_id');

      if (page) {
        if (page.match(/top/)) {
          page = 'gamelist';
        }
        filename = page;
        if (globalId) {
          filename += '_' + globalId;
        }
        if (inning) {
          filename += '_' + inning;
        }
        if (tob) {
          filename += '_' + tob;
        }
        if (teamId) {
          filename += '_' + teamId;
        }
        if (vsTeamId) {
          filename += '_' + vsTeamId;
        }
        if (leagueCode) {
          filename += '_' + leagueCode;
        }
        if (gametype) {
          filename += '_' + gametype;
        }
        if (month) {
          filename += '_' + month;
        }
        if (rankCode) {
          filename += '_' + rankCode;
        }
        if (positionCode) {
          filename += '_' + positionCode;
        }
        if (playerId) {
          filename += '_' + playerId;
        }
      } else {
        if (this.$config.defaultPage != '') {
          page = this.$config.defaultPage;
        } else {
          page = 'gamelist';
        }
        filename = page;
      }
      let url = this.$api.baseURL + filename + '.json';
      this.$api.getAPI(url).then((data) => {
        if (data) {
          let template = Top;

          if (filename.match(/game_/)) {
          template = Game;
        } else if (filename.match(/pbp/)) {
          template = Pbp;
        } else if (filename.match(/inning/)) {
          template = Inning;
        } else if (filename.match(/^member/)) {
          template = Member;
        } else if (filename.match(/standings/)) {
          if (filename.match(/standings_lite/)) {
            template = StandingsLite;
          } else if (filename.match(/standings_[0-2]/)) {
            template = StandingsDetail;
          } else {
            template = Standings;
          }
        } else if (filename.match(/schedule/)) {
          template = Schedule;
        } else if (filename.match(/leaders/)) {
          if (filename.match(/leaders_lite/)) {
            template = LeadersLite;
          } else if (filename.match(/leaders_/)) {
            template = LeadersDetail;
          } else {
            template = Leaders;
          }
        } else if (filename.match(/team/)) {
          template = Team;
          if (filename.match(/team_list/)) {
            template = TeamList;
          } else if (filename.match(/team_result/)) {
            template = TeamResult;
          } else if (filename.match(/team_record/)) {
            template = TeamRecord;
          } else if (filename.match(/team_member_[0-9]{4}/)) {
            template = TeamMember;
          }
        } else if (filename.match(/player/)) {
          template = Player;
        }
        let ComponentClass = Vue.extend(template);
        let instance = new ComponentClass({
          data: function () {
            return {
              json: data
              }
            }
          });
        instance.$mount('#kd-npb-contents');
        } else {
          this.$config.errorCallback();
        }
      });
    } catch (e) {
      console.log(e)
      this.$config.errorCallback();
    }
  },
}
</script>
