<template>
<div>

<!--速報ページ_メインメニュー-->
<ul id="bbrSok_mainMenu" class="bbrCom_tabMenu02">
  <li><a v-bind:href="$config.baseUrl + '?kd_page=game&global_id=' + json.gameInfo.gameGlobalId">試合情報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId" class="bbrCom_current">一球速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=inning&global_id=' + json.gameInfo.gameGlobalId">テキスト速報</a></li>
  <li><a v-bind:href="$config.baseUrl + '?kd_page=member&global_id=' + json.gameInfo.gameGlobalId">出場成績</a></li>
</ul>
<!--/速報ページ_メインメニュー-->

<!--速報ページ_メインコンテンツ-->
<div id="bbrSok_mainCont" class="bbrCom_setColFill">

  <div id="bbrSok_game_date">{{ getFormattedGameStartTime(json.gameDate, json.gameInfo.startTime) }} {{ json.gameInfo.venueName }}</div>
  
  <!--スコアボード-->
  <div class="bbrCom_scrTbl">
    <table id="bbrCom_scrTbl-scoreIn12">
        <tbody>
          <tr class="hd">
            <th class="bbrCom_scrTbl_tName">&nbsp;</th>
            <th v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              {{ inning.number }}
            </th>
            <th class="bbrCom_scrTbl_sum">計</th>
            <th class="bbrCom_scrTbl_hit">H</th>
            <th class="bbrCom_scrTbl_error">E</th>
        </tr>
        <tr class="bbrCom_scrTbl-first">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.visitor.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.visitor.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.visitor.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId + '&inning=' + inning.number + '&tob=1&flow=1'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.visitor.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.visitor.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.visitor.errors }}</td>
        </tr>
        <tr class="bbrCom_scrTbl-second">
            <td class="bbrCom_scrTbl_tName">
              <span class="bbrCom_ndS">{{ json.gameInfo.home.nickname }}</span>
              <span class="bbrCom_ndP">{{ json.gameInfo.home.aliasName }}</span>
            </td>
            <td v-bind:class="'bbrCom_scrTbl_inning bbrCom_scrTbl_in' + inning.number" v-for='(inning,index) in json.lineScore.home.innings' v-bind:key="index">
              <template v-if="inning.score == null || inning.score == 'X'">
                {{ inning.score }}
              </template>
              <template v-else>
                <a v-bind:href="$config.baseUrl + '?kd_page=pbp&global_id=' + json.gameInfo.gameGlobalId + '&inning=' + inning.number + '&tob=2&flow=1'">{{ inning.score }}</a>
              </template>
            </td>
            <td class="bbrCom_scrTbl_sum">{{ json.lineScore.home.runs }}</td>
            <td class="bbrCom_scrTbl_hit">{{ json.lineScore.home.hits }}</td>
            <td class="bbrCom_scrTbl_error">{{ json.lineScore.home.errors }}</td>
        </tr>
    </tbody></table>
  </div>
  <!--/スコアボード-->

  <!--chance of winning-->
  <!--
  <div id="bbrSok_game_cow">
    <ul class="cf">
      <li id="bbrSok_game_cow-first">西<span class="bbrCom_ndS">武</span> <span>78.2%</span></li>
      <li id="bbrSok_game_cow-second">ソ<span class="bbrCom_ndS">フトバンク</span> <span>21.8%</span></li>
      <li id="bbrSok_game_cow-att"><span><a href="#">chance of winning <span class="icon-bbrIco-qand"></span></a></span></li>
    </ul>
    <div id="bbrSok_game_cow-bar">
      <div id="bbrSok_game_cow-bar_left" class="bbrCom_bgTeam07" style="width: 78.6%"></div>
      <div id="bbrSok_game_cow-bar_right" class="bbrCom_bgTeam12" style="width: 21.4%"></div>
    </div>
  </div>
  -->
  <!--/chance of winning-->
  <template v-if="json.event">

  <div id="bbrSok_tabMenus" class="bbrCom_cf">
    <!--  切換ボタン-->
    <ul id="bbrSok_switchMenu">
      <li><a id="bbrSok_switchMenu_pbp" href="" v-on:click="switchPBP" class="bbrCom_current"><span class="bbrCom_ndS">一球</span>速報</a></li>
      <li><a id="bbrSok_switchMenu_runner" href="" v-on:click="switchRunner">走者<span class="bbrCom_ndS">情報</span></a></li>
    </ul>
    <!--  /切換ボタン-->
    <!--  更新ボタン-->
    <ul id="bbrSok_reloadMenu">
    <li><a href="" v-on:click="reload"><span class="icon-bbrIco-reload"></span>更新</a></li>
      <li><a id="bbrSok_autoReload" href="" v-on:click="setAutoReload">自動</a></li>
      <li><a id="bbrSok_manualReload" href="" v-on:click="setManualReload">手動</a></li>
    </ul>
    <!--  /更新ボタン-->
  </div>
  <!------------------現在の状態(白枠内)------------------>
  <div id="bbrSok_gStatus">
  
    <!--現在の状態＿ピッチングのセット-->
    <div id="bbrSok_gStatus_setPitching">

      <!--現在の状態＿中央-打者の左右-->
      <template v-if="getBatterHand() != null">
        <div v-bind:id="'bbrSok_gStatus_setPitching-bgbat' + getBatterHand()"></div>
      </template>

      <!--現在の状態＿左上-スコア-->
      <div id="bbrSok_gStatus-score" class="bbrCom_cf">

         <!--(左側 - テキスト表記)-->
        <div id="bbrSok_gStatus-score_left">
          <dl>
            <dt>{{ json.gameInfo.inning }}回{{ tob[json.gameInfo.tob] }}</dt>
            <template v-if="json.gameInfo.tob == 'Top'">
              <dd id="bbrSok_gStatus-score_left-atk">
                {{ json.gameInfo.visitor.aliasName }}
                <span>{{ json.event[this.eventIndex].score.visitor }}</span>
              </dd>
              <dd>
                {{ json.gameInfo.home.aliasName }}
                <span>{{ json.event[this.eventIndex].score.home }}</span>
              </dd>
            </template>
            <template v-else>
              <dd>
                {{ json.gameInfo.visitor.aliasName }}
                <span>{{ json.event[this.eventIndex].score.visitor }}</span>
              </dd>
              <dd id="bbrSok_gStatus-score_left-atk">
                {{ json.gameInfo.home.aliasName }}
                <span>{{ json.event[this.eventIndex].score.home }}</span>
              </dd>
            </template>
          </dl>
        </div>
        
        <!--(右側 - 塁とボールカウント)-->
        <div id="bbrSok_gStatus-score_right">
          <!--(右側 - 塁)-->
          <div id="bbrSok_gStatus-score_right-baseMap">
            <!--idの末尾1~3＝ベースの1～3塁、pタグの有無＝ランナーの有無-->
            <p id="bbrSok_gStatus-score_right-baseMap_base01" v-if="json.event[this.eventIndex].base.b1.onBase"></p>
            <p id="bbrSok_gStatus-score_right-baseMap_base02" v-if="json.event[this.eventIndex].base.b2.onBase"></p>
            <p id="bbrSok_gStatus-score_right-baseMap_base03" v-if="json.event[this.eventIndex].base.b3.onBase"></p>
          </div>
          <!--(右側 - ボールカウント)-->
          <ul id="bbrSok_gStatus-score_right-count">
            <!--classの末尾0~4＝カウントの数値-->
            <li id="bbrSok_gStatus-score_right-count-b" v-bind:class="'bbrSok_gStatus-score_right-count-bSet' + json.event[this.eventIndex].bso.b"><span>B</span></li>
            <li id="bbrSok_gStatus-score_right-count-s" v-bind:class="'bbrSok_gStatus-score_right-count-sSet' + json.event[this.eventIndex].bso.s"><span>S</span></li>
            <li id="bbrSok_gStatus-score_right-count-o" v-bind:class="'bbrSok_gStatus-score_right-count-oSet' + json.event[this.eventIndex].bso.o"><span>O</span></li>
          </ul>
        </div>
      </div>
      <!--/現在の状態＿左上-スコア-->

      <!--現在の状態＿右上-今のボール-->
      <div id="bbrSok_gStatus-nball">
        <div id="bbrSok_gStatus-nball_txt">
          <template v-if="json.event[this.eventIndex].pitch.result.color == 'out'">
              <div class="bbrCom_setColRst-out">
                <p>{{ json.event[this.eventIndex].result }}</p>
              </div>
          </template>
          <template v-else-if="json.event[this.eventIndex].pitch.result.color == 'hit'">
              <div class="bbrCom_setColRst-hit">
                <p>{{ json.event[this.eventIndex].result }}</p>
              </div>
          </template>
          <template v-else>
              <div class="bbrCom_setColRst-nc">
                <template v-if="json.event[this.eventIndex].type == 'PLAYER'">
                  <p>{{ json.event[this.eventIndex].result }}</p>
                  <span>{{ getReplaceDescription(json.event[this.eventIndex].result, json.event[this.eventIndex].description) }}</span>
                </template>
                <template v-else>
                <p v-if="json.event[this.eventIndex].result == null">{{ json.event[this.eventIndex].pitch.result.text }}</p>
                <p v-else>{{ json.event[this.eventIndex].result }}</p>
                </template>
              </div>
          </template>
        </div>
        <div id="bbrSok_gStatus-nball_bb" class="bbrCom_cf">
          <div id="bbrSok_gStatus-nball_bb_icon">
            <!--
            球種(形5種),結果(色4種)
            球種：bbrSok_bSel-***(top,lft,rgt,btm,str)の５種類
            結果：bbrSok_bRst-***(bal,stk,out,hit)の４種類
            -->
            <span v-bind:class="'bbrSok_bSel-' + json.event[this.eventIndex].pitch.type.draw + ' bbrSok_bRst-' + json.event[this.eventIndex].pitch.result.color">{{ json.event[this.eventIndex].pitch.count }}</span>
            <!--<span class="bbrSok_bSel-lft bbrSok_bRst-bal">4</span>-->
            <!--<span class="bbrSok_bSel-rgt bbrSok_bRst-out">4</span>-->
            <!--<span class="bbrSok_bSel-btm bbrSok_bRst-hit">4</span>-->
            <!--<span class="bbrSok_bSel-str bbrSok_bRst-stk">4</span>-->
          </div>
          <p>{{ json.event[this.eventIndex].pitch.type.str }}</p>
          <p>{{ json.event[this.eventIndex].pitch.speed }}km/h</p>
        </div>
      </div>
      <!--/現在の状態＿右上-今のボール-->
      
      <!--現在の状態＿中央-遷移ボタン6つ-->
      <div id="bbrSok_gStatus-batterNavBtns">
        <div id="bbrSok_gStatus-batterNavBtns_before">
          <ul>
            <!--(リンクがある場合)-->
            <li>
              <p class="bbrCom_btn01">
                <a v-on:click="prevBatter" href="" class="bbrCom_btn-ar2L">前の打者</a>
              </p>
            </li>
            <!--(リンクが無い場合)-->
            <li>
              <p class="bbrCom_btn01" v-if="json.gameInfo.inning == 1 && json.gameInfo.tob == 'Top' && eventIndex == 0">
                <span class="bbrCom_btn-ar1L">前の球</span>
              </p>
              <p class="bbrCom_btn01" v-else>
                <a v-on:click="prevBall" href="" class="bbrCom_btn-ar1L">前の球</a>
              </p>
            </li>
            <li>{{ getPrevBatter() }}</li>
          </ul>
        </div>
        <div id="bbrSok_gStatus-batterNavBtns_after">
          <ul>
            <li>
              <p class="bbrCom_btn01">
                <a v-on:click="nextBatter" href="" class="bbrCom_btn-ar2R">次の打者</a>
              </p>
            </li>
            <li>
              <p class="bbrCom_btn01" v-if="json.gameInfo.inning == json.gameInfo.currentInning && json.gameInfo.tob == json.gameInfo.currentTob && eventIndex == json.event.length-1">
                <span class="bbrCom_btn-ar1R">次の球</span>
              </p>
              <p class="bbrCom_btn01" v-else>
                <a v-on:click="nextBall" href="" class="bbrCom_btn-ar1R">次の球</a>
              </p>
            </li>
            <li>{{ getNextBatter() }}</li>
          </ul>
        </div>
      </div>
      <!--/現在の状態＿中央-遷移ボタン6つ-->
      
      <!--現在の状態＿中央-配球-->
      <div id="bbrSok_gStatus_batterZone">
        <table><tbody>
          <!--
          <span class="bbrSok_setColAvrMin">で青色背景
          <span class="bbrSok_setColAvrMax">で赤色背景
          -->
          <tr>
            <td id="bbrSok_gStatus_batterZone-Apos11"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos12"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos13"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos14"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos15"><span>&nbsp;</span></td>
          </tr>
          <tr>
            <td id="bbrSok_gStatus_batterZone-Apos21"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos22"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos23"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos24"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos25"><span>&nbsp;</span></td>
          </tr>
          <tr>
            <td id="bbrSok_gStatus_batterZone-Apos31"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos32"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos33"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos34"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos35"><span>&nbsp;</span></td>
          </tr>
          <tr>
            <td id="bbrSok_gStatus_batterZone-Apos41"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos42"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos43"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos44"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos45"><span>&nbsp;</span></td>
          </tr>
          <tr>
            <td id="bbrSok_gStatus_batterZone-Apos51"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos52"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos53"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos54"><span>&nbsp;</span></td>
            <td id="bbrSok_gStatus_batterZone-Apos55"><span>&nbsp;</span></td>
          </tr>
        </tbody></table>
        <ul>
          <!--
          位置,球種(形5種),結果(色4種)
          位置：bbrSok_bPos-**(1文字目は縦の列a～kの11種類、2文字目は横の行1~21の種類の組み合わせ)
          球種：bbrSok_bSel-***(top,lft,rgt,btm,str)の５種類
          結果：bbrSok_bRst-***(bal,stk,out,hit)の４種類
          -->
          <li v-bind:class="'bbrSok_bPos-' + event.ballY.toLowerCase() + event.ballX" v-for='(event,index) in getPitchingOfBall(json.event)' v-bind:key="index">
              <p v-bind:class="'bbrSok_bSel-' + event.type.draw + ' bbrSok_bRst-' + event.result.color">{{ event.count }}</p>
          </li>
        </ul>
      </div>
      <!--現在の状態＿中央-配球-->
      
    </div>
    <!--/現在の状態＿ピッチングのセット-->

    <!--/現在の状態＿ヒッティングのセット-->
    <div id="bbrSok_gStatus_setHitting">

      <!--現在の状態＿打球：行方-->
      <!--方向(B〜Y)_距離(01〜11)_結果(GO/FO/LO/GH/HR).png-->
      <template v-if="json.event[this.eventIndex].battingResult != null">
        <div id="bbrSok_gStatus_setHitting-ptn">
          <img  v-bind:src="'https://npb.sports-digican.com/pc/sokuhou/images/result/' + getResultDirection() + '_'  + getResultDistance()+ '_'  + getResultType() +'.png'" alt="">
        </div>
      </template>
      

      <!--現在の状態＿左上-スコア-->
      <div id="bbrSok_gStatus-score" class="bbrCom_cf">

        <!--(左側 - テキスト表記)-->
        <div id="bbrSok_gStatus-score_left">
          <dl>
            <dt>{{ json.gameInfo.inning }}回{{ tob[json.gameInfo.tob] }}</dt>
            <template v-if="json.gameInfo.tob == 'Top'">
              <dd id="bbrSok_gStatus-score_left-atk">
                {{ json.gameInfo.visitor.aliasName }}
                <span>{{ json.event[this.eventIndex].score.visitor }}</span>
              </dd>
              <dd>
                {{ json.gameInfo.home.aliasName }}
                <span>{{ json.event[this.eventIndex].score.home }}</span>
              </dd>
            </template>
            <template v-else>
              <dd>
                {{ json.gameInfo.visitor.aliasName }}
                <span>{{ json.event[this.eventIndex].score.visitor }}</span>
              </dd>
              <dd id="bbrSok_gStatus-score_left-atk">
                {{ json.gameInfo.home.aliasName }}
                <span>{{ json.event[this.eventIndex].score.home }}</span>
              </dd>
            </template>
          </dl>
        </div>
        
        <!--(右側 - 塁とボールカウント)-->
        <div id="bbrSok_gStatus-score_right">
          <!--(右側 - 塁)-->
          <div id="bbrSok_gStatus-score_right-baseMap">
            <!--idの末尾1~3＝ベースの1～3塁、pタグの有無＝ランナーの有無-->
            <p id="bbrSok_gStatus-score_right-baseMap_base01" v-if="json.event[this.eventIndex].base.b1.onBase"></p>
            <p id="bbrSok_gStatus-score_right-baseMap_base02" v-if="json.event[this.eventIndex].base.b2.onBase"></p>
            <p id="bbrSok_gStatus-score_right-baseMap_base03" v-if="json.event[this.eventIndex].base.b3.onBase"></p>
          </div>
          <!--(右側 - ボールカウント)-->
          <ul id="bbrSok_gStatus-score_right-count">
            <!--classの末尾0~4＝カウントの数値-->
            <li id="bbrSok_gStatus-score_right-count-b" v-bind:class="'bbrSok_gStatus-score_right-count-bSet' + json.event[this.eventIndex].bso.b"><span>B</span></li>
            <li id="bbrSok_gStatus-score_right-count-s" v-bind:class="'bbrSok_gStatus-score_right-count-sSet' + json.event[this.eventIndex].bso.s"><span>S</span></li>
            <li id="bbrSok_gStatus-score_right-count-o" v-bind:class="'bbrSok_gStatus-score_right-count-oSet' + json.event[this.eventIndex].bso.o"><span>O</span></li>
          </ul>
        </div>
      </div>
      <!--/現在の状態＿左上-スコア-->

      <!--現在の状態＿右上-今のボール-->
      <div id="bbrSok_gStatus-nball">
        <div id="bbrSok_gStatus-nball_txt">
          <template v-if="json.event[this.eventIndex].pitch.result.color == 'out'">
              <div class="bbrCom_setColRst-out">
                <p>{{ json.event[this.eventIndex].result }}</p>
              </div>
          </template>
          <template v-else-if="json.event[this.eventIndex].pitch.result.color == 'hit'">
              <div class="bbrCom_setColRst-hit">
                <p>{{ json.event[this.eventIndex].result }}</p>
              </div>
          </template>
          <template v-else>
              <div class="bbrCom_setColRst-nc">
                <p v-if="json.event[this.eventIndex].result == null">{{ json.event[this.eventIndex].pitch.result.text }}</p>
                <p v-else>{{ json.event[this.eventIndex].result }}</p>
              </div>
          </template>
        </div>
        <div id="bbrSok_gStatus-nball_bb" class="bbrCom_cf">
          <div id="bbrSok_gStatus-nball_bb_icon">
            <!--
            球種(形5種),結果(色4種)
            球種：bbrSok_bSel-***(top,lft,rgt,btm,str)の５種類
            結果：bbrSok_bRst-***(bal,stk,out,hit)の４種類
            -->
            <span v-bind:class="'bbrSok_bSel-' + json.event[this.eventIndex].pitch.type.draw + ' bbrSok_bRst-' + json.event[this.eventIndex].pitch.result.color">{{ json.event[this.eventIndex].pitch.count }}</span>
            <!--<span class="bbrSok_bSel-lft bbrSok_bRst-bal">4</span>-->
            <!--<span class="bbrSok_bSel-rgt bbrSok_bRst-out">4</span>-->
            <!--<span class="bbrSok_bSel-btm bbrSok_bRst-hit">4</span>-->
            <!--<span class="bbrSok_bSel-str bbrSok_bRst-stk">4</span>-->
          </div>
          <p>{{ json.event[this.eventIndex].pitch.type.str }}</p>
          <p>{{ json.event[this.eventIndex].pitch.speed }}km/h</p>
        </div>
      </div>
      <!--現在の状態＿中央-遷移ボタン6つ-->
      <div id="bbrSok_gStatus-batterNavBtns">
        <div id="bbrSok_gStatus-batterNavBtns_before">
          <ul>
            <!--(リンクがある場合)-->
            <li>
              <p class="bbrCom_btn01">
                <a v-on:click="prevBatter" href="" class="bbrCom_btn-ar2L">前の打者</a>
              </p>
            </li>
            <!--(リンクが無い場合)-->
            <li>
              <p class="bbrCom_btn01" v-if="json.gameInfo.inning == 1 && json.gameInfo.tob == 'Top' && eventIndex == 0">
                <span class="bbrCom_btn-ar1L">前の球</span>
              </p>
              <p class="bbrCom_btn01" v-else>
                <a v-on:click="prevBall" href="" class="bbrCom_btn-ar1L">前の球</a>
              </p>
            </li>
            <li>{{ getPrevBatter() }}</li>
          </ul>
        </div>
        <div id="bbrSok_gStatus-batterNavBtns_after">
          <ul>
            <li>
              <p class="bbrCom_btn01">
                <a v-on:click="nextBatter" href="" class="bbrCom_btn-ar2R">次の打者</a>
              </p>
            </li>
            <li>
              <p class="bbrCom_btn01" v-if="json.gameInfo.inning == json.gameInfo.currentInning && json.gameInfo.tob == json.gameInfo.currentTob && eventIndex == json.event.length-1">
                <span class="bbrCom_btn-ar1R">次の球</span>
              </p>
              <p class="bbrCom_btn01" v-else>
                <a v-on:click="nextBall" href="" class="bbrCom_btn-ar1R">次の球</a>
              </p>
            </li>
            <li>{{ getNextBatter() }}</li>
          </ul>
        </div>
      </div>
      <!--/現在の状態＿中央-遷移ボタン6つ-->

      <!--現在の状態＿ランナーの状態-->
      <div id="bbrSok_gStatus_setHitting-stBase">
        <p id="bbrSok_gStatus_setHitting-stBase_base01" v-if="json.event[this.eventIndex].base.b1.onBase">
          <span>{{ json.playerList[json.event[this.eventIndex].base.b1.playerId].name }}</span>
        </p>
        <p id="bbrSok_gStatus_setHitting-stBase_base02" v-if="json.event[this.eventIndex].base.b2.onBase">
          <span>{{ json.playerList[json.event[this.eventIndex].base.b2.playerId].name }}</span>
        </p>
        <p id="bbrSok_gStatus_setHitting-stBase_base03" v-if="json.event[this.eventIndex].base.b3.onBase">
          <span>{{ json.playerList[json.event[this.eventIndex].base.b3.playerId].name }}</span>
        </p>
      </div>
      <!--/現在の状態＿ランナーの状態-->
      
    </div>
    <!--/現在の状態＿ヒッティングのセット-->


    <!--現在の状態＿下部-選手情報-->
    <div id="bbrSok_gStatus-tab01">

      
  <!-------------------------------------リーグ戦時のコーディング開始------------------------------------->
  <!--↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓-->
      <ul id="bbrSok_gStatus-tab01_listMenu" class="bbrCom_tabMenu02">
        <li><a v-on:click="setMode" href="" data-tab-id='player'>選手情報</a></li>
        <li><a v-on:click="setMode" href="" data-tab-id='pitches'>配球</a></li>
      </ul>
  <!--↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑-->
  <!-------------------------------------リーグ戦時のコーディング終了------------------------------------->
      
  <!-------------------------------------オープン戦時のコーディング開始------------------------------------->
  <!--↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓-->
  <!--
      <ul id="bbrSok_gStatus-tab01_listMenu" class="bbrCom_tabMenu02">
        <li><a href="#" class="bbrCom_current">選手情報</a></li>
        <li><a href="#">配球</a></li>
      </ul>
  -->
  <!--↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑-->
  <!-------------------------------------オープン戦時のコーディング終了------------------------------------->

      
      <!--現在の状態＿下部-tab01 選手情報-->
      <div data-tab='player'>
      <div id="bbrSok_gStatus-tab01_vs" class="bbrCom_cf">
        <div class="bbrSok_gStatus-tab01_vs-st1">
          <table>
            <tbody>
            <tr>
              <template v-if="json.gameInfo.tob == 'Top'">
                <td class="bbrSok_gStatus-tab01_vs-st1_pl">{{ getBattingSlot() }}</td>
                <td class="bbrSok_gStatus-tab01_vs-st1_mid">
                  <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getBatter().teamGlobalId + '&player_id=' + getBatter().globalId">{{ getBatterName() }}</a>
                  <a v-else>{{ getBatterName() }}</a>
                </td>
                <td class="bbrSok_gStatus-tab01_vs-st1_pr">{{ getBatterPosition() }}</td>
                <td class="bbrSok_gStatus-tab01_vs-st1_pl">P</td>
                <td class="bbrSok_gStatus-tab01_vs-st1_mid">
                  <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getMoundPitcher(json.gameInfo.home.globalId).teamGlobalId + '&player_id=' + getMoundPitcher(json.gameInfo.home.globalId).globalId">{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
                  <a v-else>{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
                </td>
                <template v-if="isStartPitcher(json.gameInfo.home.globalId)">
                <td class="bbrSok_gStatus-tab01_vs-st1_pr">(投)</td>
                </template>
                <template v-else>
                <td class="bbrSok_gStatus-tab01_vs-st1_pr">投</td>
                </template>
              </template>
              <template v-else>
                <td class="bbrSok_gStatus-tab01_vs-st1_pl">P</td>
                <td class="bbrSok_gStatus-tab01_vs-st1_mid">
                  <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getMoundPitcher(json.gameInfo.visitor.globalId).teamGlobalId + '&player_id=' + getMoundPitcher(json.gameInfo.visitor.globalId).globalId">{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
                  <a v-else>{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
                </td>
                <template v-if="isStartPitcher(json.gameInfo.visitor.globalId)">
                <td class="bbrSok_gStatus-tab01_vs-st1_pr">(投)</td>
                </template>
                <template v-else>
                <td class="bbrSok_gStatus-tab01_vs-st1_pr">投</td>
                </template>
                <td class="bbrSok_gStatus-tab01_vs-st1_pl">{{ getBattingSlot() }}</td>
                <td class="bbrSok_gStatus-tab01_vs-st1_mid">
                  <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getBatter().teamGlobalId + '&player_id=' + getBatter().globalId">{{ getBatterName() }}</a>
                  <a v-else>{{ getBatterName() }}</a>
                </td>
                <td class="bbrSok_gStatus-tab01_vs-st1_pr">{{ getBatterPosition() }}</td>
              </template>
            </tr>
            <tr>
            <td class="bbrSok_gStatus-tab01_vs-st1_bar" colspan="3"><div v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id">&nbsp;</div></td>
            <td class="bbrSok_gStatus-tab01_vs-st1_bar" colspan="3"><div v-bind:class="'bbrCom_bgTeam' + json.gameInfo.home.id">&nbsp;</div></td>
            </tr>
            </tbody>
          </table>
        </div>
        <template v-if="json.gameInfo.tob == 'Top'">
        <div id="bbrSok_gStatus-tab01_vs-batter" class="bbrCom_posL">
            <ul class="bbrSok_gStatus-tab01_vs-st2">
              <li>打率<span>{{ getSeasonBatting().average }}</span></li>
              <li>本<span>{{ getSeasonBatting().homeRuns }}</span></li>
              <li>打点<span>{{ getSeasonBatting().runs }}</span></li>
              <li>四球<span>{{ getSeasonBatting().walks }}</span></li>
              <li>盗塁<span>{{ getSeasonBatting().stolenBases }}</span></li>
            </ul>
            <ul class="bbrSok_gStatus-tab01_vs-st3">
              <li v-for='(batting,index) in getGameBatterResult()' v-bind:key="index">
                {{ index+1 }}打席
                <span v-bind:class="'bbrCom_setColRst-' + batting.color">{{ batting.result }}</span>
              </li>
            </ul>
        </div> 
        </template>
        <template v-else>
        <div id="bbrSok_gStatus-tab01_vs-pitcher" class="bbrCom_posL">
          <ul class="bbrSok_gStatus-tab01_vs-st2">
            <li>防御率<span>{{ getSeasonPitching().earnedRunAverage }}</span></li>
            <li>勝<span>{{ getSeasonPitching().wins }}</span></li>
            <li>負<span>{{ getSeasonPitching().loses }}</span></li>
            <li>セーブ<span>{{ getSeasonPitching().saves }}</span></li>
            <li>奪三振<span>{{ getSeasonPitching().strikeOuts }}</span></li>
          </ul>
          <ul class="bbrSok_gStatus-tab01_vs-st3">
            <li>投球数<span>{{ getGamePitching().pitchCount }}</span></li>
            <li>打者数<span>{{ getGamePitching().battersFaced }}</span></li>
            <li>被安<span>{{ getGamePitching().hits }}</span></li>
            <li>被本<span>{{ getGamePitching().homeRuns }}</span></li>
            <li>与四球<span>{{ getGamePitching().walks }}</span></li>
            <li>奪三振<span>{{ getGamePitching().strikeOuts }}</span></li>
            <li>失点<span>{{ getGamePitching().runs }}</span></li>
            <li>自責<span>{{ getGamePitching().earnedRuns }}</span></li>
          </ul>
        </div>
        </template>
        <template v-if="json.gameInfo.tob == 'Top'">
        <div id="bbrSok_gStatus-tab01_vs-pitcher" class="bbrCom_posL">
          <ul class="bbrSok_gStatus-tab01_vs-st2">
            <li>防御率<span>{{ getSeasonPitching().earnedRunAverage }}</span></li>
            <li>勝<span>{{ getSeasonPitching().wins }}</span></li>
            <li>負<span>{{ getSeasonPitching().loses }}</span></li>
            <li>セーブ<span>{{ getSeasonPitching().saves }}</span></li>
            <li>奪三振<span>{{ getSeasonPitching().strikeOuts }}</span></li>
          </ul>
          <ul class="bbrSok_gStatus-tab01_vs-st3">
            <li>投球数<span>{{ getGamePitching().pitchCount }}</span></li>
            <li>打者数<span>{{ getGamePitching().battersFaced }}</span></li>
            <li>被安<span>{{ getGamePitching().hits }}</span></li>
            <li>被本<span>{{ getGamePitching().homeRuns }}</span></li>
            <li>与四球<span>{{ getGamePitching().walks }}</span></li>
            <li>奪三振<span>{{ getGamePitching().strikeOuts }}</span></li>
            <li>失点<span>{{ getGamePitching().runs }}</span></li>
            <li>自責<span>{{ getGamePitching().earnedRuns }}</span></li>
          </ul>
        </div>
        </template>
        <template v-else>
        <div id="bbrSok_gStatus-tab01_vs-batter" class="bbrCom_posL">
            <ul class="bbrSok_gStatus-tab01_vs-st2">
              <li>打率<span>{{ getSeasonBatting().average }}</span></li>
              <li>本<span>{{ getSeasonBatting().homeRuns }}</span></li>
              <li>打点<span>{{ getSeasonBatting().runs }}</span></li>
              <li>四球<span>{{ getSeasonBatting().walks }}</span></li>
              <li>盗塁<span>{{ getSeasonBatting().stolenBases }}</span></li>
            </ul>
            <ul class="bbrSok_gStatus-tab01_vs-st3">
              <li v-for='(batting,index) in getGameBatterResult()' v-bind:key="index">
                {{ index+1 }}打席
                <span v-bind:class="'bbrCom_setColRst-' + batting.color">{{ batting.result }}</span>
              </li>
            </ul>
        </div> 
        </template>
      </div>
    </div>

      <!--現在の状態＿下部-tab4 - 配球-->
    <div data-tab='pitches'>
      <div id="bbrSok_gStatus-tab01_vs-stSolo">
        <table>
          <tbody>
            <tr>
              <td>Ｐ</td>
              <td>
                <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getMoundPitcher(getPitcherTeamId()).teamGlobalId + '&player_id=' + getMoundPitcher(getPitcherTeamId()).globalId">{{ getMoundPitcher(getPitcherTeamId()).name }}</a>
                <a v-else>{{ getMoundPitcher(getPitcherTeamId()).name }}</a>
              </td>
              <td>{{ side[getMoundPitcher(getPitcherTeamId()).throwing] }}</td>
            </tr>
            <tr>
              <td id="bbrSok_gStatus-tab01_vs-stSolo_bar" colspan="3">
                <div v-bind:class="'bbrCom_bgTeam' + getMoundPitcher(getPitcherTeamId()).teamId" style="width: 100%;">&nbsp;</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="bbrSok_gStatus-tab01_4tco" class="bbrCom_cf">
        <!--左 - 配球-->
        <div id="bbrSok_gStatus-tab01_4tco_cs">
          <h4>配球</h4>
          <div id="bbrSok_gStatus-tab01_4tco_cs-scl">
            <table>
              <tbody>
                <tr v-for='(event,index) in getPitchingOfEvent(json.event)' v-bind:key="index">
                  <td><span v-bind:class="'bbrSok_bSel-' + event.pitch.type.draw + ' bbrSok_bRst-' + event.pitch.result.color">{{ event.pitch.count }}</span></td>
                  <td>
                    <template v-if="event.result == null">
                      {{ event.pitch.result.text }}
                    </template>
                    <template v-else>
                      {{ event.result }}
                    </template>
                  </td>
                  <td>{{ event.pitch.type.str }}</td>
                  <td>{{ event.pitch.speed }}km</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        
        <!-------------------------------------オープン戦時は以下のタグを削除する------------------------------------->
        <!--↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓-->
        <template v-if="json.gameInfo.gameType != '0'">
        <!--右 - 割合-->
        <div id="bbrSok_gStatus-tab01_4tco_dv" class="bbrCom_cf">
          <h4>球種割合</h4>
          <!--canvas-->
          <div id="bbrSok_gStatus-tab01_4tco_dv-cvs">
            <div id="bbrSok_gStatus-tab01_4tco_dv-cvs_cnt">
              <Pie :propData="chartData"></Pie>
            </div>
            <p><span class="bbrCom_setCol-att">※</span>前日まで</p>
          </div>
          <!--/canvas-->
          <!--球種割合テキスト 表示は原則6種類まで-->
          <div id="bbrSok_gStatus-tab01_4tco_dv-txt">
            <table>
              <tbody>
                <tr v-for='(type,index) in getTypeOfPitches()' v-bind:key="index">
                  <td><span v-bind:id="'bbrSok_gStatus-tab01_4tco_dv-txt_' + type.ballType.class">&nbsp;</span></td>
                  <td>{{ type.ballType.str }}</td>
                  <td>{{ getRatio(type.count) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <!--/球種割合テキスト-->
        </div>
        </template>
        <!--↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑-->
        <!-------------------------------------オープン戦時は以上のタグを削除する------------------------------------->
        
      </div>
      
      <!--現在の状態＿下部-tab4 - 配球-->

    </div>
    </div>
    
    

  </div>
  </template>
  <!------------------現在の状態(白枠内)------------------>
</div>
<!--速報ページ_メインコンテンツ-->

<!--スターティングメンバー-->
<div class="bbrCom_stMember">

    <table>
      <tbody>
        <tr v-bind:class="'bbrCom_bgTeam' + json.gameInfo.visitor.id">
          <th v-bind:class="'bbrCom_stMember_tName bbrCom_bgTeam' + json.gameInfo.visitor.id">{{ json.gameInfo.visitor.nickname }}</th>
          <th v-bind:class="'bbrCom_stMember_tName bbrCom_bgTeam' + json.gameInfo.home.id">{{ json.gameInfo.home.nickname }}</th>
        </tr>
      </tbody>
    </table>
  
    <table class="bbrCom_mb5">
    <tbody>
    <tr class="bbrCom_stMember_playerSet">
      <th class="bbrCom_stMember_playerSet-pos1">打順</th>
      <th class="bbrCom_stMember_playerSet-pos2">位置</th>
      <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
      <th class="bbrCom_stMember_playerSet-pos4">打</th>
      <th class="bbrCom_stMember_playerSet-pos5">打率</th>
      <th class="bbrCom_stMember_playerSet-pos1">打順</th>
      <th class="bbrCom_stMember_playerSet-pos2">位置</th>
      <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
      <th class="bbrCom_stMember_playerSet-pos4">打</th>
      <th class="bbrCom_stMember_playerSet-pos5">打率</th>
    </tr>
    <tr v-for='(result,index) in getBattingList()' v-bind:key="index">
      <td>{{ index+1 }}</td>
      <template v-if="result.visitor.started">
        <td>({{ result.visitor.pos }})</td>
      </template>
      <template v-else>
        <td>{{ result.visitor.pos }}</td>
      </template>
      <td class="bbrCom_stMember_playerName">
        <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.playerList[result.visitor.globalId].teamGlobalId + '&player_id=' + json.playerList[result.visitor.globalId].globalId">{{ json.playerList[result.visitor.globalId].name }}</a>
        <a v-else>{{ json.playerList[result.visitor.globalId].name }}</a>
      </td>
      <td>{{ side[json.playerList[result.visitor.globalId].batSide] }}</td>
      <td>{{ json.playerList[result.visitor.globalId].seasonBatting.average }}</td>
      <td>{{ index+1 }}</td>
      <template v-if="result.home.started">
        <td>({{ result.home.pos }})</td>
      </template>
      <template v-else>
        <td>{{ result.home.pos }}</td>
      </template>
      <td class="bbrCom_stMember_playerName">
        <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + json.playerList[result.home.globalId].teamGlobalId + '&player_id=' + json.playerList[result.home.globalId].globalId">{{ json.playerList[result.home.globalId].name }}</a>
        <a v-else>{{ json.playerList[result.home.globalId].name }}</a>
      </td>
      <td>{{ side[json.playerList[result.home.globalId].batSide] }}</td>
      <td>{{ json.playerList[result.home.globalId].seasonBatting.average }}</td>
    </tr>
    </tbody></table>
    
    <table><tbody>
      <tr class="bbrCom_stMember_playerSet">
        <th class="bbrCom_stMember_playerSet-pos1">&nbsp;</th>
        <th class="bbrCom_stMember_playerSet-pos2">位置</th>
        <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
        <th class="bbrCom_stMember_playerSet-pos4">投</th>
        <th class="bbrCom_stMember_playerSet-pos5">防御率</th>
        <th class="bbrCom_stMember_playerSet-pos1">&nbsp;</th>
        <th class="bbrCom_stMember_playerSet-pos2">位置</th>
        <th class="bbrCom_stMember_playerSet-pos3">選手名</th>
        <th class="bbrCom_stMember_playerSet-pos4">投</th>
        <th class="bbrCom_stMember_playerSet-pos5">防御率</th>
      </tr>
      <tr>
        <template v-if="isStartPitcher(json.gameInfo.visitor.globalId)">
        <td>先発</td>
        <td>（投）</td>
        </template>
        <template v-else>
        <td>&nbsp;</td>
        <td>投</td>
        </template>
        <td class="bbrCom_stMember_playerName">
          <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getMoundPitcher(json.gameInfo.visitor.globalId).teamGlobalId + '&player_id=' + getMoundPitcher(json.gameInfo.visitor.globalId).globalId">{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
          <a v-else>{{ getMoundPitcher(json.gameInfo.visitor.globalId).name }}</a>
        </td>
        <td>{{ side[getMoundPitcher(json.gameInfo.visitor.globalId).throwing] }}</td>
        <td>{{ getMoundPitcher(json.gameInfo.visitor.globalId).seasonPitching.earnedRunAverage }}</td>
        <template v-if="isStartPitcher(json.gameInfo.home.globalId)">
        <td>先発</td>
        <td>（投）</td>
        </template>
        <template v-else>
        <td>&nbsp;</td>
        <td>投</td>
        </template>
        <td class="bbrCom_stMember_playerName">
          <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + getMoundPitcher(json.gameInfo.home.globalId).teamGlobalId + '&player_id=' + getMoundPitcher(json.gameInfo.home.globalId).globalId">{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
          <a v-else>{{ getMoundPitcher(json.gameInfo.home.globalId).name }}</a>
        </td>
        <td>{{ side[getMoundPitcher(json.gameInfo.home.globalId).throwing] }}</td>
        <td>{{ getMoundPitcher(json.gameInfo.home.globalId).seasonPitching.earnedRunAverage }}</td>
      </tr>
    </tbody></table>
  
</div>
<!--/スターティングメンバー02-->
<!--他球団の試合-->
<div id="bbrCom_gameResult"></div>
<!--他球団の試合-->
<Copyright></Copyright>
</div>
</template>

<script>
import utilsMixin from '../utils';
import Copyright from './Copyright.vue';
import Pie from './PieChart.vue';

export default {
  components: {
    Pie,
    Copyright
  },
  mixins: [utilsMixin],
  data: function () {
    return {
      json: null,
      eventIndex: 0,
      switchTimerId: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      },
      side: {
        'Left':'左',
        'Right':'右',
        'Switch':'両',
      },
      batSide: {
        'Left':'L',
        'Right':'R',
        'Switch':'S',
      },
      referenceDistance: {
        'A':100,
        'B':100,
        'C':102,
        'D':104,
        'E':106,
        'F':108,
        'G':110,
        'H':112,
        'I':114,
        'J':116,
        'K':118,
        'L':120,
        'M':122,
        'N':122,
        'O':120,
        'P':118,
        'Q':116,
        'R':114,
        'S':112,
        'T':110,
        'U':108,
        'V':106,
        'W':104,
        'X':102,
        'Y':100,
        'Z':100,
      },
      reloadTimerID: null,
      chartData: null,
      mode: 'player',
    }
  },
  mounted: async function() {
    let params = new URLSearchParams(document.location.search.substring(1));
    let flow = params.get('flow');
    if (flow && flow == 1) {
      this.eventIndex = 0;
    } else if (flow && flow == 2) {
      this.eventIndex = this.json.event.length-1;
    } else {
      this.eventIndex = this.json.event.length-1;
    }
    this.setActiveInning(this.json.gameInfo);    
    this.checkView();
    this.checkSessionStorage();
    await this.loadOtherGame(this.json.gameDate);
    this.$config.callback();
  },
  methods: {
    setActiveInning: function (gameInfo) {
      let attackTeam;
      if (gameInfo.tob == 'Top') {
        attackTeam = document.querySelector('.bbrCom_scrTbl-first');
      } else if (gameInfo.tob == 'Bottom') {
        attackTeam = document.querySelector('.bbrCom_scrTbl-second');
      }
      if (attackTeam) {
        let attackTeamInning = attackTeam.querySelector('.bbrCom_scrTbl_in' + gameInfo.inning);
        let score = attackTeamInning.querySelector('a');
        if (score) {
          // 試合中かつ最新イニングでまだ無得点の場合
          if (gameInfo.gameStatus == 2 && gameInfo.inning == gameInfo.currentInning && gameInfo.tob == gameInfo.currentTob && score.textContent == '0') {
            score.textContent = '-';
          }
        } else {
          attackTeamInning.insertAdjacentHTML('afterbegin', '<a href="">-</a>');
        }
        attackTeamInning.classList.add('bbrCom_scrTbl_attack');
      }
    },
    prevBall: function (e) {
      e.preventDefault();
      this.manualReload();
      this.eventIndex--;
      if(this.eventIndex < 0) {
        this.eventIndex = 0;
        let id = this.json.gameInfo.gameGlobalId;
        let inning = parseInt(this.json.gameInfo.inning);
        let tob;
        if (this.json.gameInfo.tob == 'Top') {
          if (inning > 1) {
            inning--;
            tob = 2;
          } else {
            return
          }
        } else {
          tob = 1;
        }
        location.href = this.$config.baseUrl + '?kd_page=pbp&global_id=' + id + '&inning=' + inning + '&tob=' + tob + '&flow=2';
      }
      this.checkView();
    },
    prevBatter: function (e) {
      e.preventDefault();
      this.manualReload();
      let slot = parseInt(this.json.event[this.eventIndex].batter.slot) - 1;
      if (slot == 0) slot = 9;
      slot = String(slot);
      const event = this.json.event;
      let index = -1;
      let lastIndex = 0;
      for (let i = this.eventIndex; i >= 0; i--) {
        if (event[i].batter.slot == slot) {
          lastIndex = i;
          break
        }
      }
      for (let i = lastIndex; i < event.length; i++) {
        if (i < this.eventIndex) {
          if (event[i].batter.slot == slot) {
            index = i;
            break;
          }
        }
      }
      if (index != -1) {
        this.eventIndex = index;
      } else {
        let id = this.json.gameInfo.gameGlobalId;
        let inning = parseInt(this.json.gameInfo.inning);
        let tob;
        if (this.json.gameInfo.tob == 'Top') {
          if (inning > 1) {
            inning--;
            tob = 2;
          } else {
            return
          }
        } else {
          tob = 1;
        }
        location.href = this.$config.baseUrl + '?kd_page=pbp&global_id=' + id + '&inning=' + inning + '&tob=' + tob + '&flow=2';
      }
      this.checkView();
      return
    },
    nextBall: function (e) {
      e.preventDefault();
      this.manualReload();
      this.eventIndex++;
      if(this.eventIndex > this.json.event.length-1) {
        this.eventIndex = this.json.event.length-1;
        let id = this.json.gameInfo.gameGlobalId;
        let inning = parseInt(this.json.gameInfo.inning);
        let tob;
        if (inning == parseInt(this.json.gameInfo.currentInning) && this.json.gameInfo.tob == this.json.gameInfo.currentTob && this.eventIndex == this.json.event.length-1) {
          return
        } else if (this.json.gameInfo.tob == 'Top') {
          tob = 2;
        } else {
          if (inning < parseInt(this.json.gameInfo.currentInning)) {
            inning++;
            tob = 1;
          } else {
            return
          }
        }
        location.href = this.$config.baseUrl + '?kd_page=pbp&global_id=' + id + '&inning=' + inning + '&tob=' + tob + '&flow=1';
      }
      this.checkView();
    },
    nextBatter: function (e) {
      e.preventDefault();
      this.manualReload();
      let slot = parseInt(this.json.event[this.eventIndex].batter.slot) + 1;
      if (slot == 10) slot = 1;
      slot = String(slot);
      const event = this.json.event;
      let index = -1;
      for (let i = 0; i < event.length; i++) {
        if (i > this.eventIndex) {
          if (event[i].batter.slot == slot) {
            index = i;
            break;
          }
        }
      }
      if (index != -1) {
        this.eventIndex = index;
      } else {
        let id = this.json.gameInfo.gameGlobalId;
        let inning = parseInt(this.json.gameInfo.inning);
        let tob;
        if (inning == parseInt(this.json.gameInfo.currentInning) && this.json.gameInfo.tob == this.json.gameInfo.currentTob && this.eventIndex == this.json.event.length-1) {
          return
        } else if (this.json.gameInfo.tob == 'Top') {
          tob = 2;
        } else {
          if (inning < parseInt(this.json.gameInfo.currentInning)) {
            inning++;
            tob = 1;
          } else {
            return
          }
        }
        location.href = this.$config.baseUrl + '?kd_page=pbp&global_id=' + id + '&inning=' + inning + '&tob=' + tob + '&flow=1';
      }
      this.checkView();
      return
    },
    getPrevBatter: function () {
      if (this.json.gameInfo.inning == 1 && this.json.event[this.eventIndex].batter.slot == 1) {
        let batterCount = []
        for (let i = this.eventIndex; i >= 0; i--) {
          if (this.json.event[i].batter.slot == 1 && this.json.event[i].pitch.count == 1) {
            batterCount.push(this.json.event[i]);
          }
        }
        if (batterCount.length <= 1) {
          return '';
        }
      }
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      let slot = parseInt(this.json.event[this.eventIndex].batter.slot) - 1;
      if (slot == 0) slot = 9;
      slot = String(slot);
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      let batter = slot + '番 ' + this.json.playerList[battingList[slot].globalId].name;
      return batter;
    },
    getNextBatter: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      let slot = parseInt(this.json.event[this.eventIndex].batter.slot) + 1;
      if (slot == 10) slot = 1;
      slot = String(slot);
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      let batter = slot + '番 ' + this.json.playerList[battingList[slot].globalId].name;
      return batter;
    },
    getPitchingOfBall: function (event) {
      const pitchingResultList = [];
      const index = this.eventIndex;
      let firstIndex = 0;
      const id = event[this.eventIndex].batter.globalId;
      for (let i = event.length-1; i >= 0; i--) {
        if (event[i].batter.globalId !== '0' && event[i].batter.globalId == id && i <= index && (event[i].pitch.count == 0 || event[i].pitch.count == 1)) {
          firstIndex = i;
          break
        }
      }
      const filterEvent = event.filter((e, i) => {
        return e.type !== 'PLAYER' && e.type !== 'RUNNER' && e.batter.globalId !== '0' && e.batter.globalId == id && i <= index && i >= firstIndex;
      });
      filterEvent.forEach(e => {
        if (e.id != 15) pitchingResultList.push(e.pitch);
      });
      return pitchingResultList;
    },
    getPitchingOfEvent: function (event) {
      const pitchingResultList = [];
      const index = this.eventIndex;
      let firstIndex = 0;
      const id = event[this.eventIndex].batter.globalId;
      for (let i = event.length-1; i >= 0; i--) {
        if (event[i].batter.globalId !== '0' && event[i].batter.globalId == id && i <= index && (event[i].pitch.count == 0 || event[i].pitch.count == 1)) {
          firstIndex = i;
          break
        }
      }
      const filterEvent = event.filter((e, i) => {
        return e.type !== 'PLAYER' && e.type !== 'RUNNER' && e.batter.globalId !== '0' && e.batter.globalId == id && i <= index && i >= firstIndex;
      });
      filterEvent.forEach(e => {
        if (e.id != 15) pitchingResultList.push(e);
      });
      return pitchingResultList;
    },
    getBattingSlot: function () {
      return this.json.event[this.eventIndex].batter.slot;
    },
    getBatter: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      const slot = this.json.event[this.eventIndex].batter.slot;
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      const id = battingList[slot].globalId;

      let batter = this.json.playerList[id];
      if (batter) {
        return batter;
      } else {
        batter = [];
        batter.globalId = '';
        batter.teamGlobalId = '';
        return batter;
      }
    },
    getBatterName: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      const slot = this.json.event[this.eventIndex].batter.slot;
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      const id = battingList[slot].globalId;

      let batter = this.json.playerList[id];
      if (batter) {
        return batter.name;
      } else {
        return '';
      }
    },
    getBatterHand: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      const slot = this.json.event[this.eventIndex].batter.slot;
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      const id = battingList[slot].globalId;
      
      let batSide = null;
      if (this.json.event[this.eventIndex].batter.globalId == id) {
        batSide = this.json.event[this.eventIndex].batter.hand;
      }
      if (batSide == null) {
        let batter = this.json.playerList[id];
        if (batter) {
          batSide = this.batSide[batter.batSide];
        }
      }
      return batSide;
    },
    getBatterPosition: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      const slot = this.json.event[this.eventIndex].batter.slot;
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      if (battingList[slot].started) {
        return '(' + battingList[slot].pos + ')';
      } else {
        return battingList[slot].pos;
      }
    },
    getPitcher: function () {
      const id = this.json.event[this.eventIndex].pitch.globalId;
      return this.json.playerList[id];
    },
    getPitcherTeamId: function () {
      return this.json.event[this.eventIndex].pitch.teamGlobalId;
    },
    getPitcherName: function () {
      const id = this.json.event[this.eventIndex].pitch.globalId;
      const name = this.json.playerList[id].name;
      return name;
    },
    getSeasonBatting: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      const slot = this.json.event[this.eventIndex].batter.slot;
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      const id = battingList[slot].globalId;

      let batter = this.json.playerList[id];
      let seasonBatting;
      if (batter) {
        seasonBatting = batter.seasonBatting;
      } else {
        seasonBatting = [];
      }
      return seasonBatting;
    },
    getGameBatterResult: function () {
      const teamId = this.json.event[this.eventIndex].batter.teamGlobalId;
      const slot = this.json.event[this.eventIndex].batter.slot;
      let latestBattingList = this.getLatestBattingList();
      const battingList = latestBattingList[teamId];
      const id = battingList[slot].globalId;

      let batter = this.json.playerList[id];
      let gameBatterResult;
      if (batter) {
        gameBatterResult = batter.gameBatterResult;
      } else {
        gameBatterResult = [];
      }
      return gameBatterResult;
    },
    getSeasonPitching: function () {
      const teamId = this.json.event[this.eventIndex].pitch.teamGlobalId;
      let pitcher = null;
      let latestPitcher = this.getLatestPitcher();
      if (latestPitcher) {
        if (teamId == latestPitcher.teamId) {
          pitcher = this.json.playerList[latestPitcher.globalId];
        } else {
          const id = this.json.moundPitcherList[teamId].globalId;
          pitcher = this.json.playerList[id];
        }
      } else {
        const id = this.json.moundPitcherList[teamId].globalId;
        pitcher = this.json.playerList[id];
      }
      if (pitcher) {
        let seasonPitching = pitcher.seasonPitching;
        if (seasonPitching) {
          return seasonPitching;
        } else {
          seasonPitching = [];
          seasonPitching.earnedRunAverage = '';
          return seasonPitching;
        }
      } else {
        let seasonPitching = [];
        seasonPitching.earnedRunAverage = '';
        return seasonPitching;
      }
    },
    getGamePitching: function () {
      const teamId = this.json.event[this.eventIndex].pitch.teamGlobalId;
      let pitcher = null;
      let latestPitcher = this.getLatestPitcher();
      if (latestPitcher) {
        if (teamId == latestPitcher.teamId) {
          pitcher = this.json.playerList[latestPitcher.globalId];
        } else {
          const id = this.json.moundPitcherList[teamId].globalId;
          pitcher = this.json.playerList[id];
        }
      } else {
        const id = this.json.moundPitcherList[teamId].globalId;
        pitcher = this.json.playerList[id];
      }
      if (pitcher) {
        let gamePitching = pitcher.gamePitching;
        if (gamePitching) {
          return gamePitching;
        } else {
          gamePitching = [];
          gamePitching.earnedRunAverage = '';
          return gamePitching;
        }
      } else {
        let gamePitching = [];
        gamePitching.earnedRunAverage = '';
        return gamePitching;
      }
    },
    getBattingList: function () {
      let latestBattingList = this.getLatestBattingList();
      const hBattingList = latestBattingList[this.json.gameInfo.home.globalId];
      const vBattingList = latestBattingList[this.json.gameInfo.visitor.globalId];
      let battingList = [];
      for (let i = 0; i < 9; i++) {
        battingList[i] = {home: hBattingList[i+1], visitor: vBattingList[i+1]};
      }
      return battingList;
    },
    isStartPitcher: function (teamId) {
      let latestPitcher = this.getLatestPitcher();
      if (latestPitcher) {
        if (teamId == latestPitcher.teamId) {
          return false;
        } else {
          return this.json.moundPitcherList[teamId].started
        }
      } else {
        return this.json.moundPitcherList[teamId].started
      }
    },
    getMoundPitcher: function (teamId) {
      let pitcher = null;
      let latestPitcher = this.getLatestPitcher();
      if (latestPitcher) {
        if (teamId == latestPitcher.teamId) {
          pitcher = this.json.playerList[latestPitcher.globalId];
        } else {
          const id = this.json.moundPitcherList[teamId].globalId;
          pitcher = this.json.playerList[id];
        }
      } else {
        const id = this.json.moundPitcherList[teamId].globalId;
        pitcher = this.json.playerList[id];
      }
      if (pitcher) {
        if (pitcher.seasonPitching) {
          return pitcher;
        } else {
          pitcher.seasonPitching = [];
          pitcher.seasonPitching.earnedRunAverage = '';
          return pitcher;
        }
      } else {
        pitcher = [];
        pitcher.name = '';
        pitcher.teamGlobalId = '';
        pitcher.globalId = '';
        pitcher.throwing = '';

        pitcher.seasonPitching = [];
        pitcher.seasonPitching.earnedRunAverage = '';
        
        return pitcher;
      }
    },
    getResultDirection: function () {
      let direction = this.json.event[this.eventIndex].battingResult.direction;
      if (direction == 'A') direction = 'B';
      if (direction == 'Z') direction = 'Y';
      return direction;
    },
    getResultDistance: function () {
      let direction = this.json.event[this.eventIndex].battingResult.direction;
      let distance = this.json.event[this.eventIndex].battingResult.distance;
      let ceilDistance = Math.ceil(distance / this.referenceDistance[direction] * 10);
      if (this.json.event[this.eventIndex].id == 8) {
        ceilDistance = 11;
      } else {
        if (ceilDistance > 10 ) ceilDistance = 10;
      }
      return ('00' + ceilDistance).slice(-2);
    },
    getResultType: function () {
      let type = this.json.event[this.eventIndex].battingResult.batType;
      if (type == 'P') type = 'F';
      if (type == 'B') type = 'G';
      if (this.json.event[this.eventIndex].id == 8) {
        type = 'HR';
      } else if (this.json.event[this.eventIndex].battingResult.hits == 1 || this.json.event[this.eventIndex].battingResult.errors == 1 ) {
        type += 'H';
      } else {
        type += 'O';
      }
      return type;
    },
    checkView: function () {
      this.switchPitchingView();
      clearInterval(this.switchTimerId);
      if (this.json.event[this.eventIndex].battingResult != null) {
        this.switchTimerId = setTimeout(() => this.switchHittingView(), 1500);
      }
      this.setModeView();
      this.createChart();
    },
    setMode: function (e) {
      e.preventDefault();
      this.mode = e.currentTarget.getAttribute('data-tab-id');
      this.setModeView();
      return   
    },
    setModeView: function () {
      let playerTab = document.querySelector('[data-tab-id="player"]');
      let pitchesTab = document.querySelector('[data-tab-id="pitches"]');
      let player = document.querySelector('[data-tab="player"]');
      let pitches = document.querySelector('[data-tab="pitches"]');
      if (this.mode == 'player') {
        playerTab.classList.add('bbrCom_current');
        pitchesTab.classList.remove('bbrCom_current');
        player.style.display = 'block';
        pitches.style.display = 'none';
      } else if (this.mode == 'pitches') {
        playerTab.classList.remove('bbrCom_current');
        pitchesTab.classList.add('bbrCom_current');
        player.style.display = 'none';
        pitches.style.display = 'block';
      }
    },
    switchPBP: function (e) {
      e.preventDefault();
      this.switchPitchingView();
    },
    switchRunner: function (e) {
      e.preventDefault();
      this.switchHittingView();
    },
    switchPitchingView: function () {
      document.querySelector('#bbrSok_gStatus_setPitching').style.display = 'block';
      document.querySelector('#bbrSok_switchMenu_pbp').classList.add('bbrCom_current');
      document.querySelector('#bbrSok_gStatus_setHitting').style.display = 'none';
      document.querySelector('#bbrSok_switchMenu_runner').classList.remove('bbrCom_current');
    },
    switchHittingView: function () {
      document.querySelector('#bbrSok_gStatus_setPitching').style.display = 'none';
      document.querySelector('#bbrSok_switchMenu_pbp').classList.remove('bbrCom_current');
      document.querySelector('#bbrSok_gStatus_setHitting').style.display = 'block';
      document.querySelector('#bbrSok_switchMenu_runner').classList.add('bbrCom_current');
    },
    getLatestBattingList: function () {
      let cloneBattingList = Object.assign({}, JSON.parse(JSON.stringify(this.json.battingList)));
      for (let i = 0; i <= this.eventIndex; i++) {
        if (this.json.event[i].type == 'PLAYER') {
          if (this.json.event[i].changeEvent) {
            // 打者
            if (this.json.event[i].changeEvent.batter) {
              let changeBatter = this.json.event[i].changeEvent.batter;
              let targetBatter = cloneBattingList[changeBatter.teamId][changeBatter.slot];
              targetBatter.globalId = changeBatter.globalId;
              targetBatter.pos = changeBatter.pos;
              targetBatter.started = false;
            }
          }
        }
      }
      return cloneBattingList;
    },
    getLatestPitcher: function () {
      let latestPitcher = null;
      for (let i = 0; i <= this.eventIndex; i++) {
        if (this.json.event[i].type == 'PLAYER') {
          if (this.json.event[i].changeEvent) {
            // 投手
            if (this.json.event[i].changeEvent.pitcher) {
              latestPitcher = this.json.event[i].changeEvent.pitcher;
              let pitcher = this.json.playerList[latestPitcher.globalId];
              // 交代投手情報がまだ入ってない場合はnull
              if (pitcher === undefined) {
                latestPitcher = null;
              }
            }
          }
        }
      }
      return latestPitcher;
    },
    reload: function (e) {
      e.preventDefault();
      location.reload();
    },
    autoReload: function () {
      let sessionStorageKey = 'KDNPB_AUTO_RELOAD_' + this.json.gameInfo.gameGlobalId;
      window.sessionStorage.setItem(sessionStorageKey, '1');
      let auto = document.querySelector('#bbrSok_autoReload');
      let manual = document.querySelector('#bbrSok_manualReload');
      auto.classList.add('bbrCom_current');
      manual.classList.remove('bbrCom_current');
      clearInterval(this.reloadTimerID);
      if (this.json.gameInfo.gameStatus == 2) {
        let autoReloadTime = this.$config.autoReloadTime * 1000;
        this.reloadTimerID = setTimeout(function() {
          location.reload();
        }, autoReloadTime);
      }
    },
    setAutoReload: function (e) {
      e.preventDefault();
      this.autoReload();
    },
    manualReload: function () {
      let sessionStorageKey = 'KDNPB_AUTO_RELOAD_' + this.json.gameInfo.gameGlobalId;
      window.sessionStorage.setItem(sessionStorageKey, '0');
      let auto = document.querySelector('#bbrSok_autoReload');
      let manual = document.querySelector('#bbrSok_manualReload');
      auto.classList.remove('bbrCom_current');
      manual.classList.add('bbrCom_current');
      clearInterval(this.reloadTimerID);
    },
    setManualReload: function (e) {
      e.preventDefault();
      this.manualReload();
    },
    checkSessionStorage: function () {
      let sessionStorageKey = 'KDNPB_AUTO_RELOAD_' + this.json.gameInfo.gameGlobalId;
      let item = window.sessionStorage.getItem(sessionStorageKey);
      if (item == null) {
        if (this.$config.defaultAutoReload && this.json.gameInfo.gameStatus == 2) {
          this.autoReload();
        } else {
          this.manualReload();
        }
      } else if (item == '1') {
        this.autoReload();
      } else {
        this.manualReload();
      }
    },
    getTypeOfPitches: function () {
      let pitchesData = [];
      let pitcher = this.getMoundPitcher(this.getPitcherTeamId());
      if (pitcher.seasonPitching.typeOfPitches) {
        pitcher.seasonPitching.typeOfPitches.forEach((type) => {
          if (type.count > 0) pitchesData.push(type);
        })
      }
      return pitchesData;
    },
    getRatio: function (count) {
      let pitchesCount = 0;
      let pitchesData = this.getTypeOfPitches();
      pitchesData.forEach((type) => {
        if (type.count > 0) pitchesCount += type.count;
      })
      return (count / pitchesCount * 100).toFixed(1);
    },
    createChart: function () {
      let pitchesData = [];
      let pitcher = this.getMoundPitcher(this.getPitcherTeamId());
      if (pitcher.seasonPitching.typeOfPitches) {
        pitcher.seasonPitching.typeOfPitches.forEach((type) => {
          pitchesData.push(type.count)
        })
      } else {
        pitchesData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
      this.chartData = {
        datasets: [
          {
            //球種の色羅列
            backgroundColor: ["#e64442","#00ff30","#2f1dde","#00a0e9","#ff6c00","#e4007f","#8e5843","#828282","#ffd800","#8ec9e3","#828282"],
            data: pitchesData,
            borderWidth:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
        ]
      }
    },
    getReplaceDescription: function (result, description) {
      if (description == null) return '';
      let replace = description.replace(result + '：', '');
      return replace;
    },
  }
}
</script>