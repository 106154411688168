<template>
  <div class="bbrCom_setStdCnt bbrCom_setColFill">
    <h3 class="bbrCom_ttlSc-l">{{ getFormattedToday(json.gameDate) }}の試合</h3>
    <div id="bbrCom_gameResult">
      <ul>
        <li v-for='(game,index) in json.gameList' v-bind:key="index">
          <!--試合前・開始前遅延-->
          <a v-if="game.gameStatus == 1 || game.gameStatus == 23" v-bind:href="$config.baseUrl + '?kd_page=game&global_id=' + game.gameGlobalId">
            <dl>
              <dt>{{ game.gameStatusName }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
              <dd>
                <div>{{ game.visitor.nickname }}
                  <span v-if="game.visitor.startingPitcher != null">先発：{{ game.visitor.startingPitcher }}</span>
                  <span v-else>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
              <dd>
                <div>{{ game.home.nickname }}
                  <span v-if="game.home.startingPitcher != null">先発：{{ game.home.startingPitcher }}</span>
                  <span v-else>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
            </dl>
          </a>
          <!--中止-->
          <a v-else-if="game.gameStatus == 5" v-bind:data="'?kd_page=pbp&global_id=' + game.gameGlobalId">
            <dl>
              <dt>{{ game.gameStatusName }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
              <dd>
                <div>{{ game.visitor.nickname }}
                  <span>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
              <dd>
                <div>{{ game.home.nickname }}
                  <span>&nbsp;</span>
                </div>
                <div>-</div>
              </dd>
            </dl>
          </a>
          <!--試合-->
          <a v-else v-bind:href="getGameLink($config.baseUrl, game)">
            <dl>
              <dt v-if="game.gameStatus == 2">{{ game.inning }}回{{ tob[game.tob] }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
              <dt v-else>{{ game.gameStatusName }}<span>{{ game.venueNickName }}</span><span>{{ getFormattedStartTime(game.startTime) }}</span></dt>
              <dd>
                <div>{{ game.visitor.nickname }}
                  <template v-if="game.gameStatusName == '試合終了'">
                    <template v-if="game.visitor.winLose == 'true'">
                    <span>勝：{{ game.visitor.pitcher }}</span>
                    <span v-if="game.savePitcher != null">S：{{ game.savePitcher }}</span>
                    </template>
                    <template v-else-if="game.visitor.winLose == 'false'">
                    <span>負：{{ game.visitor.pitcher }}</span>
                    </template>
                    <template v-else>
                    <span>&nbsp;</span>
                    </template>
                  </template>
                  <template v-else>
                    <span v-if="game.visitor.pitcher == null">&nbsp;</span>
                    <span v-else>{{ game.visitor.pitcher }}</span>
                  </template>
                </div>
                <div>{{ game.visitor.score }}</div>
              </dd>
              <dd>
                <div>{{ game.home.nickname }}
                  <template v-if="game.gameStatusName == '試合終了'">
                    <template v-if="game.home.winLose == 'true'">
                    <span>勝：{{ game.home.pitcher }}</span>
                    <span v-if="game.savePitcher != null">S：{{ game.savePitcher }}</span>
                    </template>
                    <template v-else-if="game.home.winLose == 'false'">
                    <span>負：{{ game.home.pitcher }}</span>
                    </template>
                    <template v-else>
                    <span>&nbsp;</span>
                    </template>
                  </template>
                  <template v-else>
                    <span v-if="game.home.pitcher == null">&nbsp;</span>
                    <span v-else>{{ game.home.pitcher }}</span>
                  </template>
                </div>
                <div>{{ game.home.score }}</div>
              </dd>
            </dl>
          </a>
          <!--試合-->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import utilsMixin from '../utils';

export default {
  mixins: [utilsMixin],
  data: function () {
    return {
      json: null,
      tob: {
        'Top':'表',
        'Bottom':'裏',
      }
    }
  },
  mounted: async function() {
    if (document.querySelector('#kd-npb-standings')) {
      await this.loadStandingsLite();
    }
    if (document.querySelector('#kd-npb-leaders')) {
      await this.loadLeadersLite();
    }
    this.$config.callback();
  },
  methods: {

  }
}
</script>