<template>
<div id="bbrCom_wrap">

<!--メインコンテンツ-->
<div class="bbrCom_setStdCnt bbrCom_setColFill">
  <!--<div class="bbrCom_attHyoki"><a href="#" class="bbrCom_linkHyoki"><span>各表記について</span></a></div>-->
  <h2 class="bbrCom_ttl">個人成績</h2>
  <template v-if="json.updateTime">
  <hr>
  <h3 class="bbrCom_ttlSc-ss bbrCom_txtDetail">{{ json.updateTime }}更新</h3>
  </template>
  <template v-if="json.gameType == 1">
  <!--セ・リーグ打撃成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_blCentral">セ・リーグ打撃成績</h3>
  <table class="bbrCom_tbl bbrRec_top-tbl bbrCom_tblTaRBeh3ClmSpan bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
        <th>打率</th>
        <th>打数</th>
        <th>安打</th>
        <th>打点</th>
        <th>本塁打</th>
      </tr>
      <template v-if="Object.keys(json.battingRankingList.ce).length">
      <template v-for="(info, index) in json.battingRankingList.ce">
        <template v-if="info.rank <= 5">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <td><span>{{ info.leaders['BA'] }}</span></td>
          <td><span>{{ info.leaders['AB'] }}</span></td>
          <td><span>{{ info.leaders['H'] }}</span></td>
          <td><span>{{ info.leaders['RBI'] }}</span></td>
          <td><span>{{ info.leaders['HR'] }}</span></td>
        </tr>
        </template>
      </template>
      </template>
      <template v-else>
        <td colspan="7">データ更新までお待ちください。</td>
      </template>
    </tbody>
  </table>
  <template v-if="Object.keys(json.battingRankingList.ce).length">
  <div class="bbrCom_txtDetail bbrCom_mb40"><a v-bind:href="$config.baseUrl + '?kd_page=leaders_batter&league_code=1'">詳細を見る</a></div>
  </template>
  <!--/セ・リーグ打撃成績-->
  <!--パ・リーグ打撃成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_blPacific">パ・リーグ打撃成績</h3>
  <table class="bbrCom_tbl bbrRec_top-tbl bbrCom_tblTaRBeh3ClmSpan bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
        <th><span>打率</span></th>
        <th><span>打数</span></th>
        <th><span>安打</span></th>
        <th><span>打点</span></th>
        <th><span>本塁打</span></th>
      </tr>
      <template v-if="Object.keys(json.battingRankingList.pa).length">
      <template v-for="(info, index) in json.battingRankingList.pa">
        <template v-if="info.rank <= 5">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <td><span>{{ info.leaders['BA'] }}</span></td>
          <td><span>{{ info.leaders['AB'] }}</span></td>
          <td><span>{{ info.leaders['H'] }}</span></td>
          <td><span>{{ info.leaders['RBI'] }}</span></td>
          <td><span>{{ info.leaders['HR'] }}</span></td>
        </tr>
        </template>
      </template>
      </template>
      <template v-else>
        <td colspan="7">データ更新までお待ちください。</td>
      </template>
    </tbody>
  </table>
  <template v-if="Object.keys(json.battingRankingList.pa).length">
  <div class="bbrCom_txtDetail bbrCom_mb40"><a v-bind:href="$config.baseUrl + '?kd_page=leaders_batter&league_code=2'">詳細を見る</a></div>
  </template>
  <!--/パ・リーグ打撃成績-->

  <!--セ・リーグ投手成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_blCentral">セ・リーグ投手成績</h3>
  <table class="bbrCom_tbl bbrRec_top-tbl bbrCom_tblTaRBeh3ClmSpan bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
        <th><span>防御率</span></th>
        <th><span>試合数</span></th>
        <th><span>勝利</span></th>
        <th><span>敗北</span></th>
        <th><span>奪三振</span></th>
      </tr>
      <template v-if="Object.keys(json.pitchingRankingList.ce).length">
      <template v-for="(info, index) in json.pitchingRankingList.ce">
        <template v-if="info.rank <= 5">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <td><span>{{ info.leaders['ERA'] }}</span></td>
          <td><span>{{ info.leaders['GP'] }}</span></td>
          <td><span>{{ info.leaders['W'] }}</span></td>
          <td><span>{{ info.leaders['L'] }}</span></td>
          <td><span>{{ info.leaders['K'] }}</span></td>
        </tr>
        </template>
      </template>
      </template>
      <template v-else>
        <td colspan="7">データ更新までお待ちください。</td>
      </template>
    </tbody>
  </table>
  <template v-if="Object.keys(json.pitchingRankingList.ce).length">
  <div class="bbrCom_txtDetail bbrCom_mb40"><a v-bind:href="$config.baseUrl + '?kd_page=leaders_pitcher&league_code=1'">詳細を見る</a></div>
  </template>
  <!--/セ・リーグ投手成績-->
  <!--パ・リーグ投手成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_blPacific">パ・リーグ投手成績</h3>
  <table class="bbrCom_tbl bbrRec_top-tbl bbrCom_tblTaRBeh3ClmSpan bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
        <th><span>防御率</span></th>
        <th><span>試合数</span></th>
        <th><span>勝利</span></th>
        <th><span>敗北</span></th>
        <th><span>奪三振</span></th>
      </tr>
      <template v-if="Object.keys(json.pitchingRankingList.pa).length">
      <template v-for="(info, index) in json.pitchingRankingList.pa">
        <template v-if="info.rank <= 5">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <td><span>{{ info.leaders['ERA'] }}</span></td>
          <td><span>{{ info.leaders['GP'] }}</span></td>
          <td><span>{{ info.leaders['W'] }}</span></td>
          <td><span>{{ info.leaders['L'] }}</span></td>
          <td><span>{{ info.leaders['K'] }}</span></td>
        </tr>
        </template>
      </template>
      </template>
      <template v-else>
        <td colspan="7">データ更新までお待ちください。</td>
      </template>
    </tbody>
  </table>
  <template v-if="Object.keys(json.pitchingRankingList.pa).length">
  <div class="bbrCom_txtDetail bbrCom_mb40"><a v-bind:href="$config.baseUrl + '?kd_page=leaders_pitcher&league_code=2'">詳細を見る</a></div>
  </template>
  <!--/パ・リーグ投手成績-->
  </template>
  <!-------------------------------------オープン戦時のコーディング開始------------------------------------->
  <template v-if="json.gameType == 0">
  <!--打撃成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_blInterleague">オープン戦打撃成績</h3>
  <table class="bbrCom_tbl bbrRec_top-tbl bbrCom_tblTaRBeh3ClmSpan bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
        <th>打率</th>
        <th>打数</th>
        <th>安打</th>
        <th>打点</th>
        <th>本塁打</th>
      </tr>
      <template v-if="Object.keys(json.battingRankingList.op).length">
      <template v-for="(info, index) in json.battingRankingList.op">
        <template v-if="info.rank <= 10">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <td><span>{{ info.leaders['BA'] }}</span></td>
          <td><span>{{ info.leaders['AB'] }}</span></td>
          <td><span>{{ info.leaders['H'] }}</span></td>
          <td><span>{{ info.leaders['RBI'] }}</span></td>
          <td><span>{{ info.leaders['HR'] }}</span></td>
        </tr>
        </template>
      </template>
      </template>
      <template v-else>
        <td colspan="7">データ更新までお待ちください。</td>
      </template>
    </tbody>
  </table>
  <!--投手成績-->
  <h3 class="bbrCom_ttlSc-t bbrCom_blInterleague">オープン戦投手成績</h3>
  <table class="bbrCom_tbl bbrRec_top-tbl bbrCom_tblTaRBeh3ClmSpan bbrCom_mb20">
    <tbody>
      <tr>
        <th>順位</th>
        <th class="bbrCom_tblTxtPName">選手名(チーム)</th>
        <th><span>防御率</span></th>
        <th><span>試合数</span></th>
        <th><span>勝利</span></th>
        <th><span>敗北</span></th>
        <th><span>奪三振</span></th>
      </tr>
      <template v-if="Object.keys(json.pitchingRankingList.op).length">
      <template v-for="(info, index) in json.pitchingRankingList.op">
        <template v-if="info.rank <= 10">
        <tr :key="index">
          <td>{{ info.rank }}</td>
          <td class="bbrCom_tblTxtPName">
            <a v-if="$config.disabledPlayerLink == false" v-bind:href="$config.baseUrl + '?kd_page=player&team_id=' + info.teamGloablId + '&player_id=' + info.playerGloablId">{{ info.playerName }}（{{ info.teamAliasName }}）</a>
            <a v-else>{{ info.playerName }}（{{ info.teamAliasName }}）</a>
          </td>
          <td><span>{{ info.leaders['ERA'] }}</span></td>
          <td><span>{{ info.leaders['GP'] }}</span></td>
          <td><span>{{ info.leaders['W'] }}</span></td>
          <td><span>{{ info.leaders['L'] }}</span></td>
          <td><span>{{ info.leaders['K'] }}</span></td>
        </tr>
        </template>
      </template>
      </template>
      <template v-else>
        <td colspan="7">データ更新までお待ちください。</td>
      </template>
    </tbody>
  </table>
  </template>
  <!-------------------------------------オープン戦時のコーディング終了------------------------------------->
   
</div>
<!--/メインコンテンツ-->

</div>
</template>

<script>

export default {
  data: function () {
    return {
      json: null,
    }
  },
  mounted: async function() {
  },
  methods: {
  }
}
</script>